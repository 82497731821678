import React from 'react';
import type { Props as ButtonProps } from '../buttons/Button';

export interface ModalContainerProps {
  /**
   * The children elements that are rendered in the body of
   * the Modal (between the header and footer). If the children height
   * is taller than max-height allowed, the children will become scrollable.
   */
  children: React.ComponentProps<'div'>['children'];

  /**
   * Whether or not the Modal is open. Allows for external control of the Modal
   */
  isOpen: boolean;

  /**
   * Function to call when the Modal is closed
   */
  onClose: () => void;

  /**
   * Optional prop to add id to modal container
   */
  id?: string;

  /**
   * Optional prop to add class name to the body of the modal that is scrollable.
   * This can be used if we want to scroll the modal all the way to the bottom on load.
   */
  scrollableBodyClassName?: string;

  /**
   * Optional prop to fit entire screen on all sizes, with 32px padding all around (defaults as false)
   */
  isEntireScreen?: boolean;
}

/*
 *(CONNER): Could be too hacky, want opinions
 * Why this works: ReactElement is itself basically a wrapper that has a type (which is either a JS class or a string) and props,
 * and within those props you can find the children, but that one layer of separation means a react element will never have children as a direct prop
 */
export const isButtonProps = (
  primaryAction: ButtonProps | React.ReactElement,
): primaryAction is ButtonProps =>
  !!primaryAction && typeof primaryAction === 'object' && 'children' in primaryAction;

export interface ModalProps extends Omit<ModalContainerProps, 'fitEntireScreen'> {
  /**
   * Class name to be added to the Modal container
   */
  className?: string;

  /**
   * The primary action for the Modal, which is rendered as a `Button` component.
   * It can be any variant of Panama Button. It is rendered in the bottom right of the
   * Modal footer.
   * Can also just be passed a React.ReactElement;
   */
  primaryAction?: ButtonProps | React.ReactElement;

  /**
   * Optional prop to add id to the modal's primary action
   */
  primaryActionId?: string;

  /**
   * An optional secondary action for the Modal, which is rendered as a `Button` component.
   * It can be any variant of Panama Button. It is rendered to the left of the `primaryAction`
   * in the Modal footer
   */
  secondaryAction?: ButtonProps;

  /**
   * Optional prop to add id to the modal's primary action
   */
  secondaryActionId?: string;

  /**
   * An optional 'plain' Panama button rendered at the very left of the footer.
   */
  leftAction?: Omit<ButtonProps, 'variant' | 'isDisabled'>;

  /**
   * Optional (but encouraged) title string to display as h6 Text at the top of the Modal.
   */
  title?: string;

  /**
   * Optional text to be placed at the bottom left of the modal.
   */
  informationText?: string | React.ReactNode;

  /**
   * Optional prop to modify the max width of the Modal, in number of pixels. Defaults to 612
   */
  maxContentWidth?: number;

  /**
   * Optional prop to modify the minimum width of the Modal, in number of pixels. Defaults to 612
   */
  minContentWidth?: number;

  /**
   * Optional prop to modify the maximum height of the Modal, in number of pixels.
   */
  maxContentHeight?: number;

  /**
   * Optional prop to modify the minimum height of the Modal, in number of pixels.
   */
  minContentHeight?: number;

  /**
   * Optional prop to add custom padding to the body. Overrides padding given from title.
   * Defaults to using the padding it inherits from the ModalTitle
   */
  customBodyPadding?: string;

  /**
   * Optional component to display in a gray panel to either the left or right side of the Modal children.
   * The position of the modal is determined by sidePanelPosition
   */
  sidePanelBody?: React.ReactNode;

  /**
   * Determines the position of the sidePanel when sidePanelBody is present. Defaults to 'left'
   */
  sidePanelPosition?: 'left' | 'right';

  /**
   * Determines the flex value of the sidePanel. Default (or false) is 0.8, true is 0.5.
   */
  smallSidePanel?: boolean;

  /**
   * Determines if the modal should overflow the content. Defaults to false.
   * An example of when one would use this is if there's a tooltip being used
   * in the modal that becomes covered within the modal container.
   */
  shouldOverflow?: boolean;

  /**
   * Optional prop to have modal grow to the maximum size allowed
   * (appends width: 100% and height: 100% to the modal)
   */
  shouldGrow?: boolean;

  /*
   * Determines if the modal has a close button in the top right corner. Defaults to false.
   * Used in the initial onboarding bulk unlisting modal (found in supplierapp listings),
   * important for forcing the user to make a decision in a modal.
   */
  noCloseButton?: boolean;

  /**
   * Determines if the title has bottom padding. Defaults to false.
   */
  noTitleBottomBorder?: boolean;
}
