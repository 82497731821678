import React from 'react';
import styled, { keyframes } from 'styled-components';

export interface Props {
  styles?: React.CSSProperties;
  className?: string;
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinningIcon = styled.i`
  display: inline-block;
  animation: 1.2s linear infinite ${spin};
`;

/**
 * Displays a spinning icon to convey loading to a user
 */
const Spinner = ({ styles, className }: Props): React.ReactElement => (
  <SpinningIcon className={`ri-loader-4-line ${className}`} style={{ ...styles }} />
);

export default Spinner;
