import { urlifyText } from 'lib/helpers/urls';
import { zIndex } from 'lib/helpers/zIndex';
import CancelButton from 'panama/components/buttons/CancelButton';
import PlainButton from 'panama/components/buttons/PlainButton';
import Spinner from 'panama/components/Spinner';
import Portal from 'panama/layout/Portal';
import Stack from 'panama/layout/Stack';
import theme from 'panama/styles/theme';
import { textStyle } from 'panama/styles/typography';
import React, { Dispatch, MouseEvent, SetStateAction, useCallback, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';

export interface Props {
  /**
   * Duration (in ms) that the Toast should remain open
   * before closing. The default is 5000 (5s)
   */
  duration?: number;

  /**
   * Determines if the Toast is conveying an error or not. When true,
   * the Toast will be red. When false, the Toast will be black. Defaults to false.
   */
  isError?: boolean;

  /**
   * Whether the toast is in a loading state or not. When true, a spinner will
   * be displayed to the left of the toast content to indicate loading.
   */
  isLoading?: boolean;

  /**
   * The message in the body of the Toast. The Toast is displayed when there is a message.
   * When the message is cleared, the Toast is dismissed
   */
  message: string;

  /**
   * Setter method for modifying the message passed to the Toast. When the message is set
   * to an empty string, the Toast will disappear
   */
  setMessage: Dispatch<SetStateAction<string>>;

  /**
   * Function to call when action is clicked. This goes with the "actionText" prop.
   */
  onAction?: () => void;

  /**
   * Text to display that on click triggers "onAction".
   */
  actionText?: string;

  /**
   * Boolean to see if close action should stop propagation. Added as an optional parameter to not
   * break earlier changes.
   */
  stopPropagation?: boolean;
}

const ToastContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 32px;
  left: 0;
  z-index: ${zIndex('toast')};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const ToastBody = styled(Stack).attrs({ gap: '16px' })<{
  $isError: boolean;
  $passThroughPointerEvents: boolean;
}>`
  color: ${theme.color.white[700]};
  background-color: ${({ $isError }) =>
    $isError ? theme.color.critical[500] : theme.color.gray[900]};

  a {
    color: ${theme.color.white[700]};
  }

  ${textStyle('bodyLarge')};
  padding: 16px;
  pointer-events: ${({ $passThroughPointerEvents }) =>
    $passThroughPointerEvents ? 'none' : 'all'};
`;

// Inversing a PlainButton makes the text primary, but we want it to be white for Toasts
const StyledPlainButton = styled(PlainButton).attrs({ inverse: true })`
  color: ${theme.color.white[800]};
`;

/**
 * Displays a message to the user about something that has happened without disrupting their usage
 * of the page. Slides in from the bottom of the page and remains until `duration` amount of time
 * has passed. Can also be dismissed by clicking the "X" icon.
 */
const Toast = ({
  duration = 5000,
  isError = false,
  isLoading,
  message,
  setMessage,
  onAction,
  actionText = 'Undo',
  stopPropagation,
}: Props): React.ReactElement | null => {
  const clearMessage = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (stopPropagation) {
        e.stopPropagation();
      }
      setMessage('');
    },
    [setMessage, stopPropagation],
  );

  // Clear the Toast after the given `duration` has passed
  useEffect(() => {
    if (message && Number.isFinite(duration)) {
      const id = setTimeout(clearMessage, duration);
      return () => clearTimeout(id);
    }
  }, [clearMessage, duration, message]);

  /**
   * When an action is clicked, call the handleClick method and also clear the Toast message
   */
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (onAction) onAction();
      clearMessage(e);
    },
    [clearMessage, onAction],
  );
  const isToastVisible = Boolean(message);
  return (
    <Portal>
      <ToastContainer>
        <Fade bottom when={isToastVisible} duration={200}>
          <ToastBody
            $passThroughPointerEvents={!isToastVisible}
            $isError={isError}
            alignItems="center"
          >
            {isLoading && <Spinner />}
            <Stack gap="32px" alignItems="center">
              <span aria-live="polite">{urlifyText(message)}</span>
              {onAction && actionText && (
                <StyledPlainButton id="toast-action-button" onClick={handleClick}>
                  {actionText}
                </StyledPlainButton>
              )}
            </Stack>
            <CancelButton id="toast-dismiss-button" inverse onClick={clearMessage} />
          </ToastBody>
        </Fade>
      </ToastContainer>
    </Portal>
  );
};

export default Toast;
