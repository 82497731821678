import { createContext } from 'react';

export interface ImportProducts {
  /**
   * Opens a modal for importing products
   */
  openModal: () => void;

  /**
   * Import products function is used by the modal, takes a CSV file and an optional error handler
   */
  importProducts: (
    /**
     * CSV file to import
     */
    csvFile: File | null,
    /**
     * Optional error handler, if provided should override the existing behavior which is to open a modal
     */
    onError?: (error: unknown) => void,
    /**
     * Optional success handler to call only after the import begins running successfully
     */
    onSuccess?: () => void,
  ) => Promise<void>;

  /**
   * Whether or not the import is currently in progress
   */
  isImporting: boolean;
}

const ImportProductsContext = createContext<ImportProducts | undefined>(undefined);

export default ImportProductsContext;
