import { createContext } from 'react';

export type UpdateCartProductsProps = {
  shopId: string;
  productIdsToRemove?: string[];
  productIdsToAdd?: string[];
  isBulkAdd?: boolean;
  shouldRefetchImmediately?: boolean;
};

/**
 * Props for the SharedCartContext
 */
export interface SharedCartContextValue {
  isMiniCartOpen: boolean;
  setIsMiniCartOpen: (isOpen: boolean) => void;

  /**
   * IDs of shops that the logged-in shop is drafting a proposal for
   */
  shopIds: string[];

  /**
   * IDs of products that the logged-in shop is drafting a proposal for
   */
  productIds: string[];

  /**
   * Calculates the total count of draft proposals a shop has.
   */
  totalCountOfDraftProposals: number;

  /**
   * Adds product(s) to the cart. Used in the discover results page.
   */
  updateCartProducts: (props: UpdateCartProductsProps) => Promise<void | null>;

  /**
   * Bulk adds product(s) to the cart. Used in discover product grid.
   */
  bulkAddCartProducts: (shopToProductIds: Record<string, string[]>) => Promise<void | null>;

  /**
   * Checks to see if the product is in the cart.
   */
  isProductInCart: (shopId: string, productId: string) => boolean;

  /**
   * Checks to see if the product is in the cart.
   */
  isBulkAddInCart: (shopId: string) => boolean;

  /**
   * Refetch the json
   */
  refetchCart: () => void;

  /**
   * After a product is removed from the, we store it's ID to be used to optimistcally
   * update other parts of the app.
   */
  lastRemovedProductId?: string | null;

  /**
   * Setter that sets the lastRemovedProductId. This helps with optimistcally updating
   * components and preventing unnecessary renders.
   */
  setLastRemovedProductId?: (lastRemovedProductId: string | null) => void;

  /**
   * Grabs the product IDs that are apart of a shop.
   */
  getProductIdsFromShopId: (shopId: string) => string[] | null;
}

/**
 * Object returned whenever using the SharedCartProvider
 */
export const SharedCartContext = createContext<SharedCartContextValue>({
  isMiniCartOpen: false,
  setIsMiniCartOpen: () => {},
  shopIds: [],
  productIds: [],
  totalCountOfDraftProposals: 0,
  updateCartProducts: async () => {},
  bulkAddCartProducts: async () => {},
  isProductInCart: () => false,
  isBulkAddInCart: () => false,
  refetchCart: () => {},
  getProductIdsFromShopId: () => [],
});
SharedCartContext.displayName = 'SharedCartContext';
