import { gql } from '@apollo/client';
import {
  UpdatePersistedJsonForShopMutation,
  UpdatePersistedJsonForShopMutationVariables,
} from 'gql/graphql';
import client from 'lib/api/ApolloClient';

const MUTATION = gql`
  mutation UpdatePersistedJsonForShop(
    $productIdsToRemove: [String!]!
    $productIdsToAdd: [String!]!
    $shopId: UUID!
  ) {
    updatePersistedJsonForShop(
      productIdsToRemove: $productIdsToRemove
      productIdsToAdd: $productIdsToAdd
      shopId: $shopId
    ) {
      ok
      persistedJson
    }
  }
`;

/**
 * Updates `persistedJson` that lives on the logged in Shop. The
 * `persistedJson` field corresponds to the items stored in the
 * shared cart.
 * Bulk add is treated as a productId and is passed in through the same field
 */
const updatePersistedJsonForShop = async (
  productIdsToRemove: string[],
  productIdsToAdd: string[],
  shopId: string,
) => {
  const { data } = await client.mutate<
    UpdatePersistedJsonForShopMutation,
    UpdatePersistedJsonForShopMutationVariables
  >({
    mutation: MUTATION,
    variables: {
      productIdsToRemove,
      productIdsToAdd,
      shopId,
    },
  });

  return { data: data?.updatePersistedJsonForShop ?? null };
};

export default updatePersistedJsonForShop;
