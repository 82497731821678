import Text from 'panama/components/Text';
import Button from 'panama/components/buttons/Button';
import Modal from 'panama/components/modal/Modal';
import Stack from 'panama/layout/Stack';
import theme from 'panama/styles/theme';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DropzoneState, FileError, useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';
import { DropzoneProps, DropzoneSizeProps, Size } from './DropzoneProps';
import { ErrorMessage } from './constants';

export type Props = DropzoneProps & DropzoneSizeProps;

// For the styled components
type StyledProps = {
  $isFocused: DropzoneState['isFocused'];
  $isDragActive: DropzoneState['isDragActive'];
  $size: Size;
  $isFullWidth: boolean;
  $isDisabled: boolean;
  $hasChildren: boolean;
  $hasTransparentBackground: boolean;
  $hasNoBorder: boolean;
};

const BORDER_WIDTH = '1px';
const SMALL_SIZE = `44px`;
const MEDIUM_WIDTH = `126px`;
const MEDIUM_HEIGHT = `131px`;
const BANNER_WIDTH = `243px`;
const BANNER_HEIGHT = `131px`;
const LARGE_WIDTH = `676px`;
const LARGE_HEIGHT = `250px`;

type ErrorType = string | null;

const getErrorMessage = (fileError: FileError) => {
  switch (fileError.code) {
    case 'generic-error':
      return ErrorMessage.GENERIC_ERROR;
    case 'file-invalid-type':
      return ErrorMessage.INVALID_FILE_TYPE_ERROR;
    case 'file-too-large':
      return ErrorMessage.IMAGE_FILE_TO_LARGE_ERROR;
    default:
      return fileError.message;
  }
};

/**
 * Creates CSS for the size of the zone + padding.
 */
const sizeStyling = ({ $size, $isFullWidth, $hasChildren }: StyledProps) => {
  const largeWidth = $isFullWidth ? `100%` : LARGE_WIDTH;
  switch ($size) {
    case 'small':
      return css`
        width: ${SMALL_SIZE};
        height: ${SMALL_SIZE};
        ${!$hasChildren &&
        css`
          padding: 12px;
        `};
      `;
    case 'medium':
      return css`
        width: ${MEDIUM_WIDTH};
        height: ${MEDIUM_HEIGHT};
        ${!$hasChildren &&
        css`
          padding: 15px;
        `};
      `;
    case 'banner':
      return css`
        width: ${BANNER_WIDTH};
        height: ${BANNER_HEIGHT};
        ${!$hasChildren &&
        css`
          padding: 17px;
        `};
      `;
    case 'large':
      return css`
        width: ${largeWidth};
        height: ${LARGE_HEIGHT};
        ${!$hasChildren &&
        css`
          padding: 20px;
        `};
      `;
  }
};

/**
 * Creates CSS for the border of the zone. Focused is highlighted, active drag
 * is solid and darker. With image is solid border and lighter.
 */
const borderStyling = ({
  $isDragActive,
  $isFocused,
  $isDisabled,
  $hasChildren,
  $hasNoBorder,
}: StyledProps) => {
  if ($hasNoBorder) return css``;
  const base = css`
    outline: none;
    border: ${BORDER_WIDTH} dashed ${theme.color.gray[400]};
    border-radius: 0;
  `;
  const focused = css`
    border-color: ${theme.color.gray[500]};
  `;

  if ($isDragActive) {
    return css`
      ${base};
      border-style: solid;
      border-color: ${theme.color.gray[700]};
    `;
  }
  if ($isFocused) {
    return css`
      ${base};
      ${focused};
    `;
  }
  if ($hasChildren) {
    return css`
      ${base};
      border-style: solid;
      border-color: ${theme.color.gray[300]};
    `;
  }
  return css`
    ${base};
    &:hover {
      ${!$isDisabled &&
      css`
        ${focused};
        cursor: pointer;
      `}
    }
  `;
};

/**
 * Creates CSS for the background of the zone. Active drag, focused, or
 * disabled have a gray background, otherwise white.
 */
const colorStyling = ({ $isDragActive, $isDisabled, $hasTransparentBackground }: StyledProps) => {
  const backgroundColor = (() => {
    if ($isDragActive) {
      return theme.color.gray[200];
    }
    const defaultBackgroundColor = $hasTransparentBackground
      ? 'transparent'
      : theme.color.white[900];
    return $isDisabled ? theme.color.gray[100] : defaultBackgroundColor;
  })();
  const foregroundColor = $isDragActive ? theme.color.black[800] : theme.color.gray[700];
  return css`
    color: ${foregroundColor};
    background-color: ${backgroundColor};
  `;
};

/**
 * This is the container for all other content + the dropzone itself.
 * Important: can't be a styled component otherwise the ref forwarding
 * fails and the input isn't styled.
 */
const Zone = styled.div<StyledProps>`
  --transition: 0.1s;

  ${sizeStyling};
  ${borderStyling};
  ${colorStyling};
  transition: border-color var(--transition), background-color var(--transition),
    color var(--transition);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
  overflow: hidden;
`;

// File upload icon of two possible sizes
const Icon = styled.i.attrs({ className: 'ri-file-upload-line' })<Pick<StyledProps, '$size'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $size }) => {
    const pixelSize = $size === 'small' ? 20 : 40;
    return css`
      width: ${pixelSize}px;
      height: ${pixelSize}px;
      font-size: ${pixelSize}px;
    `;
  }};
`;

/**
 * Creates the inner contents for the dropzone. For small size,
 * always just shows the icon. For medium/banner/large when dragging, shows
 * text for drop files to upload. For large, shows icon, button, and
 * help text otherwise. For medium/banner, shows title + help text otherwise.
 */
const DropzoneContents = ({
  size = 'large',
  isDragActive,
  isDisabled,
  open,
  customIcon,
  customButtonText,
}: Pick<Props, 'size' | 'isDisabled' | 'customButtonText' | 'customIcon'> &
  Pick<DropzoneState, 'isDragActive' | 'open'>) => {
  // Always just return the icon for small size
  if (size === 'small') {
    return (
      (
        <>
          {/* Just show the custom icon when small */}
          {customIcon}
        </>
      ) ?? <Icon $size={size} />
    );
  }

  if (isDragActive) {
    return (
      <Text $variant="cta" $alignment="center">
        Drop files to upload
      </Text>
    );
  }

  const buttonText = customButtonText ?? 'Add file';
  const buttonSubtext = 'or drag files here to upload';

  if (size === 'medium' || size === 'banner') {
    return (
      <Stack isVertical gap="8px" alignItems="center">
        <Text $variant="cta">{buttonText}</Text>
        <Text $variant="footnote" color={theme.color.black[500]} $alignment="center">
          {buttonSubtext}
        </Text>
      </Stack>
    );
  }
  return (
    <Stack isVertical gap="12px" alignItems="center">
      {customIcon ?? <Icon $size={size} />}
      <Button
        id="panama-dropzone-button"
        variant={isDisabled ? 'basic' : 'white'}
        size="medium"
        onClick={open}
        isDisabled={isDisabled}
        style={{ background: 'transparent' }}
      >
        {buttonText}
      </Button>
      <Text $variant="caption" $alignment="center">
        {buttonSubtext}
      </Text>
    </Stack>
  );
};

/**
 * Creates a dropzone component of a given size. Defaults to large if nothing is
 * provided. Large zones can be made full width of parent, and medium/small have
 * predefined static sizes. The `handleFiles` function is how you handle the
 * result of using the files. Accepts only single files for now.
 */
const Dropzone = ({
  size = 'large',
  isFullWidth,
  isDisabled,
  handleFile,
  forceCancel,
  accept,
  hasTransparentBackground = false,
  hasNoBorder = false,
  customIcon,
  customButtonText,
  children,
  validator,
  noClick,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState<ErrorType>(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);

  const clear = useCallback(() => {
    handleFile(null);
    setErrorMessage(null);
  }, [handleFile]);

  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragActive, open } = useDropzone(
    {
      disabled: isDisabled,
      accept,
      multiple: false,
      noClick,
      onDropAccepted: (acceptedFiles) => {
        const file = acceptedFiles[0];
        try {
          handleFile(file);
        } catch (error) {
          if (error instanceof Error) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage(ErrorMessage.GENERIC_ERROR);
          }
        }
      },
      onDropRejected: (rejectionMessages) => {
        if (rejectionMessages.length === 0) return;
        const firstError = rejectionMessages[0].errors[0];
        const message = getErrorMessage(firstError);
        setErrorMessage(message);
      },
      validator,
    },
  );

  const acceptedFile = useMemo(() => acceptedFiles[0], [acceptedFiles]);

  useEffect(() => {
    if (forceCancel) {
      clear();
    }
    // For this effect, it should only change if forceCancel
    // changes. Putting handleChanges is not only unnecessary
    // but may cause unnecessary renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceCancel]);

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorMessage(null);
  };

  useEffect(() => {
    if (errorMessage) setIsErrorModalOpen(true);
  }, [errorMessage]);

  const childrenContent = children?.({
    open,
    clear,
    acceptedFile,
    errorMessage,
    isDragActive,
  });

  const {
    isFocused: $isFocused,
    isDragActive: $isDragActive,
    ...rest
  } = getRootProps({
    isFocused,
    isDragActive,
    className: 'dropzone',
    tabIndex: noClick ? -1 : 0,
  });

  return (
    <>
      <Zone
        {...rest}
        $isFocused={$isFocused}
        $isDragActive={$isDragActive}
        $size={size}
        $isFullWidth={isFullWidth ?? false}
        $isDisabled={isDisabled ?? false}
        $hasChildren={Boolean(childrenContent)}
        $hasTransparentBackground={hasTransparentBackground}
        $hasNoBorder={hasNoBorder}
      >
        <input {...getInputProps()} />
        {childrenContent ?? (
          <DropzoneContents
            size={size}
            isDisabled={isDisabled}
            isDragActive={isDragActive}
            open={open}
            customIcon={customIcon}
            customButtonText={customButtonText}
          />
        )}
      </Zone>
      <Modal
        isOpen={isErrorModalOpen}
        onClose={closeErrorModal}
        primaryAction={{
          id: 'dropzone-error-modal-try-again-button',
          children: 'Try again',
          onClick: closeErrorModal,
          variant: 'basic',
          size: 'medium',
        }}
        secondaryAction={{
          id: 'dropzone-error-modal-cancel-button',
          children: 'Cancel',
          onClick: closeErrorModal,
          variant: 'white',
          size: 'medium',
        }}
        title="Error"
      >
        {errorMessage}
      </Modal>
    </>
  );
};

export default Dropzone;
