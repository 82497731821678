import { useContext } from 'react';
import ExportProductsContext from './ExportProductsContext';

/**
 * Convenience hook for exporting products
 */
const useExportProducts = () => {
  const exportProducts = useContext(ExportProductsContext);
  if (exportProducts === undefined) {
    throw new Error('useExportProducts must be used within a ExportProductsProvider');
  }

  return exportProducts;
};

export default useExportProducts;
