/* eslint-disable canal/no-raw-apollo-calls */
import type {
  DocumentNode,
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  QueryLazyOptions,
  TypedDocumentNode,
} from '@apollo/client';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import useToast from 'lib/hooks/interactions/useToast';
import { useEffect, useRef } from 'react';
import { formatBackendError } from '../useBackendErrorToast';
import { isJSONParseErrorFromApollo } from './useQueryWithErrorHandling';

type LazyQueryResults<QueryType, QueryVariablesType extends OperationVariables> = readonly [
  (
    options?: QueryLazyOptions<QueryVariablesType> | undefined,
  ) => Promise<LazyQueryResult<QueryType, QueryVariablesType>>,
  Pick<LazyQueryResult<QueryType, QueryVariablesType>, 'data' | 'refetch'> & {
    isLoading: boolean;
  },
];

/**
 * Hook that wraps a useLazyQuery call to provide built in error handling
 * + toast popup for any errors that occur. USE THIS OVER useLazyQuery!
 * @ref https://www.apollographql.com/docs/react/data/queries/#manual-execution-with-uselazyquery
 */
const useLazyQueryWithErrorHandling = <
  QueryType,
  QueryVariablesType extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<QueryType, QueryVariablesType>,
  options?: LazyQueryHookOptions<QueryType, QueryVariablesType>,
): LazyQueryResults<QueryType, QueryVariablesType> => {
  const showToast = useToast();
  const shouldRetryQuery = useRef(false);
  const [getData, { data, loading: isLoading, error, refetch }] = useLazyQuery<
    QueryType,
    QueryVariablesType
  >(query, options);

  useEffect(() => {
    if (!error) {
      return;
    }

    let errorCode;
    if (error.networkError && 'statusCode' in error.networkError) {
      errorCode = error.networkError.statusCode;
    }

    Sentry.captureException(error);

    /**
     * If a query 502s, we want to retry it a single time. The reasoning is that the backend will
     * respond much quicker the second time around and this can improve the user experience while the
     * root causes behind the 502s can be fixed on the backend.
     */
    if (errorCode === 502 && !shouldRetryQuery.current) {
      shouldRetryQuery.current = true;
      refetch();
      return;
    }

    let message;
    // Apollo seems to expect the API to always return valid JSON and when that
    // doesn't happen their attempt to JSON.parse the response body fails and
    // causes a parsing error to show up. Temp fix is to hide these errors
    // by setting the message to an empty string.
    if (isJSONParseErrorFromApollo(error)) {
      message = '';
    } else {
      message = formatBackendError(error);
    }

    if (message) {
      showToast(message);
    }
  }, [error, showToast, refetch]);

  return [getData, { data, isLoading, refetch }];
};

export default useLazyQueryWithErrorHandling;
