import styled from 'styled-components';

interface Props {
  color?: string;
  size?: number;
}

const InformationIcon = styled.i.attrs({ className: 'ri-information-line' })<Props>`
  font-size: ${({ size }) => size ?? 16}px;
  color: ${({ color }) => color ?? 'inherit'};

  &::before {
    vertical-align: middle;
  }
`;

export default InformationIcon;
