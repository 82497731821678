import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  HttpOptions,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import {
  createPersistedQueryManifestVerificationLink,
  generatePersistedQueryIdsFromManifest,
  PersistedQueryManifestForVerification,
} from '@apollo/persisted-query-lists';

import * as Sentry from '@sentry/nextjs';
import { createApp } from '@shopify/app-bridge';
import { authenticatedFetch } from '@shopify/app-bridge-utils';
import { CUR_JWT_LOCAL_STORAGE_KEY } from 'components/shared/BigCommerceAppBridgeProvider';
import { GHOST_MODE_LOCAL_STORAGE_KEY } from 'components/shared/session/constants';
import { getShopifyCredentials } from 'components/shared/ShopifyAppBridgeProvider';
import {
  CommunicationContainersFragment,
  GetAllCommunicationContainersQueryVariables,
  GetCommunicationContainerMessagesQueryVariables,
} from 'gql/graphql';
import { API_URL } from 'lib/api';
import { IS_DEVELOP, IS_LOCAL } from 'lib/constants/internals';
import { SPYGLASS_PREFIX } from 'lib/constants/routes';
import {
  isBigCommerceEmbeddedApp,
  isEmbeddedApp,
  isShopifyEmbeddedApp,
} from 'lib/helpers/isEmbeddedApp';
import { getItemFromLocalStorage } from 'lib/helpers/localStorage';
import { isJSONParseErrorFromApollo } from 'lib/hooks/graphql/queries/useQueryWithErrorHandling';

const errorLink = onError(({ networkError }) => {
  if (networkError && isJSONParseErrorFromApollo(networkError)) {
    // eslint-disable-next-line no-param-reassign
    networkError.message = 'Server Parse Error';
    Sentry.captureException(networkError);
  }
});

const httpLinkOpts: HttpOptions = {
  uri: `${API_URL}/graphql`,
  credentials: 'same-origin',
};

if (isEmbeddedApp) {
  if (isShopifyEmbeddedApp) {
    const creds = getShopifyCredentials();
    if (creds) {
      const app = createApp(creds);
      httpLinkOpts.fetch = authenticatedFetch(app);
    } else {
      Sentry.captureMessage('isEmbeddedApp=true but getShopifyCredentials returned null');
    }
  }
}

const includeQueryLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    http: {
      includeExtensions: true,
      includeQuery: true,
    },
  });

  return forward(operation);
});

// This ensures that we don't allow any mutations while in Ghost Mode.
// CAVEAT: This won't work in Incognito since local storage is blocked
const blockMutationsInGhostMode = new ApolloLink((operation, forward) => {
  // If the operation is a mutation, the user is in ghost mode, and the user is NOT
  // within Spyglass, we block the mutation.
  if (
    // Alex: I promise "operation" exists at the end of this chain - Apollo's types must be messed up.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    operation?.query?.definitions?.[0]?.operation === 'mutation' &&
    getItemFromLocalStorage(GHOST_MODE_LOCAL_STORAGE_KEY) === 'true' &&
    !window.location.href.includes(SPYGLASS_PREFIX)
  ) {
    // eslint-disable-next-line no-console
    console.error('Ghost mode flag detected, mutation blocked');
    return null;
  }

  return forward(operation);
});

const authLink = new ApolloLink((operation, forward) => {
  if (isBigCommerceEmbeddedApp) {
    const token = getItemFromLocalStorage(CUR_JWT_LOCAL_STORAGE_KEY);
    if (token) {
      operation.setContext({
        headers: {
          credentials: 'same-origin',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    }
  }

  return forward(operation);
});

const verificationLink = createPersistedQueryManifestVerificationLink({
  loadManifest: () =>
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    import(
      '../../../persisted-query-manifest.json'
    ) as unknown as Promise<PersistedQueryManifestForVerification>,
  onVerificationFailed: (err) => {
    // eslint-disable-next-line no-console
    console.warn(err);
  },
});

const persistedQueryLink = createPersistedQueryLink(
  generatePersistedQueryIdsFromManifest({
    loadManifest: () =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      import(
        '../../../persisted-query-manifest.json'
      ) as unknown as Promise<PersistedQueryManifestForVerification>,
  }),
);

const httpLink = new HttpLink(httpLinkOpts);

const client = new ApolloClient({
  link: from([
    blockMutationsInGhostMode,
    errorLink,
    authLink,
    ...(IS_LOCAL ? [] : [persistedQueryLink]),
    includeQueryLink,
    ...(IS_DEVELOP ? [verificationLink] : []), // console.warn allow list failures in dev env
    httpLink,
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      StripePaymentMethodIdType: {
        keyFields: ['paymentMethodId'],
      },
      ShopifyVariant: {
        fields: {
          defaultPrice: {
            // Equivalent to options.mergeObjects(existing, incoming).
            merge: true,
          },
        },
      },
      Query: {
        fields: {
          communicationContainers: {
            // ...relayStylePagination(),
            keyArgs: [
              'id',
              'supShopifyShop_Id',
              'skShopifyShop_Id',
              'onlyMessageThreads',
              'onlyNegotiations',
              'onlyContainersUnreadBySup',
              'onlyContainersUnreadBySk',
              'latestProposalStatus',
              'orderBy',
              'supShopifyShop_Id_In',
              'skShopifyShop_Id_In',
              'searchQuery',
              /* Fields below are purposely omitted b/c we want to be appending to the same list when these values change */
              // 'first',
              // 'last',
              // 'offset',
            ],
            merge(
              existing: CommunicationContainersFragment,
              incoming: CommunicationContainersFragment,
              options,
            ) {
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              const variables = options?.variables as GetAllCommunicationContainersQueryVariables;
              const offset = variables.offset ?? 0;
              const onlyMessageCount = variables.onlyMessageCount ?? false;
              const onlyUnread = variables.onlySkUnread || variables.onlySupUnread;

              // If we're only fetching the message count, we need to merge the data since
              // no messages are sent back. If we're fetching only unread, we reset the list
              // b/c we want remove any containers that are now read.
              if (onlyMessageCount || (onlyUnread && offset === 0)) {
                return incoming;
              }

              // Slicing is necessary because the existing data is
              // immutable, and frozen in development.
              const mergedEdges = existing?.edges ? [...existing.edges] : [];
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unnecessary-type-assertion
              for (let i = 0; i < incoming.edges!.length; i += 1) {
                mergedEdges[offset + i] = incoming?.edges?.[i] ? incoming.edges[i] : null;
              }

              return {
                ...incoming,
                edges: mergedEdges,
              };
            },
          },
        },
      },
      CommunicationContainer: {
        fields: {
          messages: {
            // ...relayStylePagination(),
            keyArgs: false,
            merge: (
              existing: CommunicationContainersFragment,
              incoming: CommunicationContainersFragment,
              options,
            ) => {
              const variables =
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                options?.variables as GetCommunicationContainerMessagesQueryVariables;
              const offset = variables.offset ?? 0;

              // Slicing is necessary because the existing data is
              // immutable, and frozen in development.
              const mergedEdges = existing?.edges ? [...existing.edges] : [];
              for (let i = 0; i < (incoming?.edges?.length ?? 0); i += 1) {
                mergedEdges[offset + i] = incoming?.edges?.[i] ? incoming.edges[i] : null;
              }

              return {
                ...incoming,
                edges: mergedEdges,
              };
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
    },
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default client;
