import { AxiosError } from 'axios';
import ImportFailedModal from 'components/shared/ImportFailedModal';
import { useIsGhostMode } from 'components/shared/session/useIsGhostMode';
import uploadTracking from 'lib/api/uploadTracking';
import { EXTERNAL_URLS } from 'lib/constants/routes';
import useToast from 'lib/hooks/interactions/useToast';
import Text from 'panama/components/Text';
import Button from 'panama/components/buttons/Button';
import CsvDropzone from 'panama/components/dropzone/CsvDropzone';
import Link from 'panama/components/link/Link';
import Modal from 'panama/components/modal/Modal';
import Stack from 'panama/layout/Stack';
import theme from 'panama/styles/theme';
import { useCallback, useMemo, useState } from 'react';
import useExportLineItems from '../ExportLineItems/useExportLineItems';
import ImportTrackingContext from './ImportTrackingContext';

interface ProviderProps {
  /**
   * This will show children components within itself
   */
  children: React.ComponentProps<'div'>['children'];
}

/**
 * Context for exporting products -- is context cause we want to
 * prevent the user from making a second export while the first one
 * is still in progress.
 *
 * Can be trigger from various parts of the app, returns the state
 * of the export and a function to trigger it. Has toast baked in.
 */
const ImportTrackingProvider = ({ children }: ProviderProps) => {
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [isImporting, setIsImporting] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showImportFailedModal, setShowImportFailedModal] = useState(false);
  const { exportLineItems: exportOrders } = useExportLineItems();
  const [isGhostMode] = useIsGhostMode();
  const [errorMessage, setErrorMessage] = useState('');
  const showToast = useToast();
  const importTracking = useCallback(
    async (csvFile: File | null, onError?: (error: unknown) => void) => {
      if (isImporting || !csvFile) return;

      setIsImporting(true);
      showToast(`Updating orders`, {
        isLoading: true,
        duration: Infinity,
      });
      try {
        await uploadTracking(csvFile, isGhostMode);
        showToast('Orders updated');
      } catch (error) {
        showToast(
          error instanceof Error ? error.message : 'Something went wrong. Please try again.',
        );
        setErrorMessage(error instanceof AxiosError ? String(error?.response?.data) : '');
        if (onError) {
          onError(error);
        } else {
          setShowImportFailedModal(true);
        }
        throw error;
      } finally {
        setIsImporting(false);
      }
    },
    [isGhostMode, isImporting, showToast],
  );
  const openModal = useCallback(() => {
    setShowImportModal(true);
  }, []);

  const importTrackingState = useMemo(
    () => ({ isImporting, importTracking, openModal }),
    [isImporting, importTracking, openModal],
  );

  return (
    <ImportTrackingContext.Provider value={importTrackingState}>
      {children}
      <Modal
        minContentWidth={649}
        isOpen={showImportModal}
        onClose={() => setShowImportModal(false)}
        title="Import order tracking by CSV"
        primaryAction={{
          id: 'import-order-tracking-button',
          children: 'Import',
          variant: 'primary',
          isDisabled: csvFile === null,
          onClick: () => {
            setShowImportModal(false);
            importTracking(csvFile);
          },
        }}
        secondaryAction={{
          id: 'cancel-import-order-tracking-modal-button',
          children: 'Cancel',
          variant: 'ghost',
          onClick: () => {
            setCsvFile(null);
            setShowImportModal(false);
          },
        }}
        informationText={
          <Text $variant="bodyRegular" color={theme.color.black[700]}>
            Need help? <Link href={EXTERNAL_URLS.FAQ_IMPORT_TRACKING}>Learn more here</Link>
          </Text>
        }
      >
        <Stack isVertical gap={16}>
          <Text $variant="bodyRegular">
            Only certain fields are supported in our CSV upload. Please{' '}
            <Button
              id="export-orders-button"
              variant="link"
              onClick={() => exportOrders(0, { type: 'unfulfilled' })}
            >
              export your orders
            </Button>{' '}
            to make sure order information is imported correctly.
          </Text>
          <CsvDropzone csvFile={csvFile} setCsvFile={setCsvFile} isFullWidth />
        </Stack>
      </Modal>
      <ImportFailedModal
        isOpen={showImportFailedModal}
        onClose={() => setShowImportFailedModal(false)}
        fileName={csvFile?.name ?? 'unknown file name'}
        errorMessage={errorMessage}
        onTryAgain={() => {
          setCsvFile(null);
          setShowImportModal(true);
        }}
      />
    </ImportTrackingContext.Provider>
  );
};

export default ImportTrackingProvider;
