/* eslint-disable canal/no-raw-apollo-calls */
import type {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
} from '@apollo/client';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import useToast from 'lib/hooks/interactions/useToast';
import { useEffect, useRef } from 'react';
import { formatBackendError } from '../useBackendErrorToast';

export const isJSONParseErrorFromApollo = (error: Error): boolean =>
  // Apollo replaces the predictable error.name == "SyntaxError" of JSON.parse
  // with an "Error", so we can't use the following.
  // if (error.name !== 'SyntaxError') {
  //  return false;
  // }
  //
  // But Apollo seems to keep error.message, so we have to check for different
  // formats depending on the browser.
  // FELIPE: the safest way I found for Chrome was to check if it starts with
  // "Unexpected" followed by a "in JSON" not too far away. Examples:
  // "Unexpected number in JSON" and "Unexpected token ! in JSON"
  error.message.match(/^Unexpected .{0,30} in JSON/) !== null || // Chrome
  error.message.startsWith('JSON.parse:') || // Firefox
  error.message.startsWith('JSON Parse error:'); // Safari

/**
 * Hook that wraps a useQuery call to provide built in error handling
 * + toast popup for any errors that occur. USE THIS OVER useQuery!
 */
const useQueryWithErrorHandling = <
  QueryType,
  QueryVariablesType extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<QueryType, QueryVariablesType>,
  options?: QueryHookOptions<QueryType, QueryVariablesType>,
): Pick<
  QueryResult<QueryType, QueryVariablesType>,
  | 'data'
  | 'refetch'
  | 'fetchMore'
  | 'variables'
  | 'error'
  | 'updateQuery'
  | 'startPolling'
  | 'stopPolling'
> & {
  isLoading: boolean;
} => {
  const showToast = useToast();
  const shouldRetryQuery = useRef(false);
  const {
    data,
    loading: isLoading,
    variables,
    error,
    refetch,
    fetchMore,
    startPolling,
    stopPolling,
    updateQuery,
  } = useQuery<QueryType, QueryVariablesType>(query, options);

  useEffect(() => {
    if (!error) {
      return;
    }

    Sentry.captureException(error);

    let message;
    // Apollo seems to expect the API to always return valid JSON and when that
    // doesn't happen their attempt to JSON.parse the response body fails and
    // causes a parsing error to show up. Temp fix is to hide these errors
    // by setting the message to an empty string.
    if (isJSONParseErrorFromApollo(error)) {
      message = '';
    } else {
      message = formatBackendError(error);
    }

    let errorCode;
    if (error.networkError && 'statusCode' in error.networkError) {
      errorCode = error.networkError.statusCode;
    }

    if (message && errorCode !== 502) {
      showToast(message);
    }

    /**
     * If a query 502s, we want to retry it a single time. The reasoning is that the backend will
     * respond much quicker the second time around and this can improve the user experience while the
     * root causes behind the 502s can be fixed on the backend.
     */
    if (errorCode === 502 && !shouldRetryQuery.current) {
      shouldRetryQuery.current = true;
      refetch();
    }
  }, [error, showToast, refetch]);

  return {
    data,
    isLoading,
    refetch,
    fetchMore,
    variables,
    error,
    updateQuery,
    startPolling,
    stopPolling,
  };
};

export default useQueryWithErrorHandling;
