import { AxiosError } from 'axios';
import ImportFailedModal from 'components/shared/ImportFailedModal';
import { useIsGhostMode } from 'components/shared/session/useIsGhostMode';
import { useSession } from 'components/shared/session/useSession';
import uploadCreateProducts from 'lib/api/uploadProducts';
import { EXTERNAL_URLS } from 'lib/constants/routes';
import useToast from 'lib/hooks/interactions/useToast';
import Text from 'panama/components/Text';
import Button from 'panama/components/buttons/Button';
import CsvDropzone from 'panama/components/dropzone/CsvDropzone';
import Link from 'panama/components/link/Link';
import Modal from 'panama/components/modal/Modal';
import Stack from 'panama/layout/Stack';
import theme from 'panama/styles/theme';
import { useCallback, useMemo, useState } from 'react';
import useExportProducts from '../ExportProducts/useExportProducts';
import ImportProductsContext from './ImportProductsContext';

interface ProviderProps {
  /**
   * This will show children components within itself
   */
  children: React.ComponentProps<'div'>['children'];
}

/**
 * Context for exporting products -- is context cause we want to
 * prevent the user from making a second export while the first one
 * is still in progress.
 *
 * Can be trigger from various parts of the app, returns the state
 * of the export and a function to trigger it. Has toast baked in.
 */
const ImportProductsProvider = ({ children }: ProviderProps) => {
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [isImporting, setIsImporting] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showImportFailedModal, setShowImportFailedModal] = useState(false);
  const { exportProducts } = useExportProducts();
  const showToast = useToast();
  const [isGhostMode] = useIsGhostMode();
  const [errorMessage, setErrorMessage] = useState('');
  const {
    session: { shop },
  } = useSession();
  const importProducts = useCallback(
    async (csvFile: File | null, onError?: (error: unknown) => void, onSuccess?: () => void) => {
      if (isImporting || !csvFile) return;

      setIsImporting(true);
      showToast(`Uploading products`, {
        isLoading: true,
        duration: Infinity,
      });
      try {
        await uploadCreateProducts(csvFile, isGhostMode);
        showToast(
          `Upload successful, an email will be sent${
            shop?.canalContactEmail ? ` to ${shop.canalContactEmail}` : ''
          } when the products are ready.`,
        );
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        showToast(
          error instanceof Error ? error.message : 'Something went wrong. Please try again.',
        );
        setErrorMessage(error instanceof AxiosError ? String(error?.response?.data) : '');
        if (onError) {
          onError(error);
        } else {
          setShowImportFailedModal(true);
        }
      } finally {
        setIsImporting(false);
      }
    },
    [isGhostMode, isImporting, shop, showToast],
  );
  const openModal = useCallback(() => {
    setShowImportModal(true);
  }, []);

  const importProductsState = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    () => ({ isImporting, importProducts, openModal }),
    [isImporting, importProducts, openModal],
  );

  return (
    <ImportProductsContext.Provider value={importProductsState}>
      {children}
      <Modal
        minContentWidth={649}
        isOpen={showImportModal}
        onClose={() => setShowImportModal(false)}
        title="Import products by CSV"
        primaryAction={{
          id: 'import-products-button',
          children: 'Import',
          variant: 'primary',
          isDisabled: csvFile === null,
          onClick: () => {
            setShowImportModal(false);
            importProducts(csvFile);
          },
        }}
        secondaryAction={{
          id: 'import-modal-cancel-button',
          children: 'Cancel',
          variant: 'ghost',
          onClick: () => setShowImportModal(false),
        }}
        informationText={
          <Text $variant="bodyRegular" color={theme.color.black[700]}>
            Need help? <Link href={EXTERNAL_URLS.FAQ_IMPORT_PRODUCTS}>Learn more here</Link>
          </Text>
        }
      >
        <Stack isVertical gap={16}>
          <Text $variant="bodyRegular">
            Only certain fields are supported in our CSV upload. Please{' '}
            <Link
              href="/api/platform/csv/product/template"
              download="Product Listings Upload Template"
            >
              download our template
            </Link>{' '}
            or{' '}
            <Button
              id="export-existing-products-button"
              variant="link"
              onClick={() => exportProducts(0, { type: 'all' })}
            >
              export existing products
            </Button>{' '}
            to update.
          </Text>
          <CsvDropzone csvFile={csvFile} setCsvFile={setCsvFile} isFullWidth />
        </Stack>
      </Modal>
      <ImportFailedModal
        isOpen={showImportFailedModal}
        onClose={() => setShowImportFailedModal(false)}
        fileName={csvFile?.name ?? 'unknown file name'}
        errorMessage={errorMessage}
        onTryAgain={() => {
          setCsvFile(null);
          setShowImportModal(true);
        }}
      />
    </ImportProductsContext.Provider>
  );
};

export default ImportProductsProvider;
