import type { AppType, OnboardingStep, OnboardingType } from 'lib/constants/landing';
import {
  GONDOLIER_ROUTES,
  LANDING_ROUTES,
  QueryParameterRecord,
  RouteRecord,
  SHOPKEEP_ROUTES,
  SPYGLASS_ROUTES,
  SUPPLIER_ROUTES,
} from 'lib/constants/routes';
import { stringifiedParam } from 'lib/helpers/queryParams';
import router, { NextRouter, useRouter } from 'next/router';

// Felipe: Not a fan of testing the pathname here, but it's the only way to
// know which attribute to use.
export const isSupplierApp = () =>
  router.pathname.startsWith('/supplier') || router.query.appType === 'supplier';
export const isShopkeepApp = () =>
  router.pathname.startsWith('/shopkeep') || router.query.appType === 'shopkeep';

export const getAppType = () => {
  if (isSupplierApp()) {
    return 'supplier';
  }
  if (isShopkeepApp()) {
    return 'shopkeep';
  }
  return 'unknown';
};

export const useAppType = () => {
  const { pathname, query } = useRouter();
  if (pathname.startsWith('/supplier') || query.appType === 'supplier') {
    return 'supplier';
  }
  if (pathname.startsWith('/shopkeep') || query.appType === 'shopkeep') {
    return 'shopkeep';
  }
  return 'unknown';
};

/**
 * Opens an internal route with a key + routes array.
 * Used only internally. Add to this if we support more than just
 * strings or arguments that take one string and produce a string
 * as RouteValue.
 */
const openInternalRoute = async (
  routeName: string,
  record: RouteRecord,
  argument?: string,
  options: {
    queryParameters?: QueryParameterRecord;
    hash?: string;
  } = {},
): Promise<boolean> => {
  const { queryParameters, hash } = options;
  const value = record[routeName];
  if (typeof value === 'string') {
    // If we have any query parameters, push those along with route
    if (queryParameters) {
      return router.push({
        pathname: value,
        query: queryParameters,
        hash,
      });
    }
    return router.push(value);
  }
  if (argument) {
    // If we have any query parameters, push those along with route
    if (queryParameters) {
      return router.push({
        pathname: value(argument),
        query: queryParameters,
        hash,
      });
    }
    // This means that we have a route that takes a string as a param, so pass the argument to the route
    return router.push(value(argument));
  }
  throw TypeError('Missing argument for route that takes one!');
};

// same as openInternalRoute but returns the route instead of opening it
const getInternalRoute = (
  routeName: string,
  record: RouteRecord,
  argument?: string,
  options: {
    queryParameters?: QueryParameterRecord;
    hash?: string;
  } = {},
): string | never => {
  const { queryParameters, hash } = options;
  const value = record[routeName];

  if (typeof value === 'string') {
    // If we have any query parameters, push those along with route
    if (queryParameters) {
      return `${value}?${new URLSearchParams(queryParameters).toString()}${hash ? `#${hash}` : ''}`;
    }
    return value;
  }
  if (argument) {
    // If we have any query parameters, push those along with route
    if (queryParameters) {
      return `${value(argument)}?${new URLSearchParams(queryParameters).toString()}${
        hash ? `#${hash}` : ''
      }`;
    }
    // This means that we have a route that takes a string as a param, so pass the argument to the route
    return value(argument);
  }
  throw TypeError('Missing argument for route that takes one!');
};

/**
 * Opens a landing page route in this tab
 */
export const openLandingRoute = async (
  routeName: keyof typeof LANDING_ROUTES,
  argument?: string,
  queryParameters?: QueryParameterRecord,
): Promise<boolean> => openInternalRoute(routeName, LANDING_ROUTES, argument, { queryParameters });

/**
 * Opens a Shopkeep specific route in this tab
 */
export const openShopkeepRoute = async (
  routeName: keyof typeof SHOPKEEP_ROUTES,
  argument?: string,
  queryParameters?: QueryParameterRecord,
  hash?: string,
): Promise<boolean> =>
  openInternalRoute(routeName, SHOPKEEP_ROUTES, argument, { queryParameters, hash });

/**
 * Opens a Supplier specific route in this tab
 */
export const openSupplierRoute = async (
  routeName: keyof typeof SUPPLIER_ROUTES,
  argument?: string,
  queryParameters?: QueryParameterRecord,
  hash?: string,
): Promise<boolean> =>
  openInternalRoute(routeName, SUPPLIER_ROUTES, argument, { queryParameters, hash });

/**
 * Opens a Gondolier specific route in this tab
 */
export const openGondolierRoute = async (
  routeName: keyof typeof GONDOLIER_ROUTES,
  argument?: string,
  queryParameters?: QueryParameterRecord,
  hash?: string,
): Promise<boolean> =>
  openInternalRoute(routeName, GONDOLIER_ROUTES, argument, { queryParameters, hash });

/**
 * Opens the product route
 */
export const openProductRoute = async (
  routeName: keyof typeof SUPPLIER_ROUTES | keyof typeof SHOPKEEP_ROUTES,
  argument?: string,
  queryParameters?: QueryParameterRecord,
): Promise<boolean> => {
  const parameters = { ...queryParameters };
  if (isSupplierApp()) {
    return openInternalRoute(routeName, SUPPLIER_ROUTES, argument, {
      queryParameters: {
        ...parameters,
        appType: 'supplier',
      },
    });
  }
  if (isShopkeepApp()) {
    return openInternalRoute(routeName, SHOPKEEP_ROUTES, argument, {
      queryParameters: {
        ...parameters,
        appType: 'shopkeep',
      },
    });
  }

  // There's a chance that neither isSupplierApp or isShopkeepApp will work,
  // which is when we check for the current 'appType' query parameter.
  // Example: when we click on a product from the bottom carousel of the PDP
  const {
    query: { appType: rawCurrentAppType },
  } = router;
  const currentAppType = stringifiedParam(rawCurrentAppType);
  const routes = currentAppType === 'supplier' ? SUPPLIER_ROUTES : SHOPKEEP_ROUTES;
  return openInternalRoute(routeName, routes, argument, {
    queryParameters: { ...parameters, appType: currentAppType },
  });
};

/**
 * Returns the URL for a particular product
 */
export const getProductRoute = (productId: string, queryParameters?: QueryParameterRecord) => {
  const searchParams = new URLSearchParams(queryParameters);
  if (isSupplierApp()) {
    searchParams.append('appType', 'supplier');
    return `${SUPPLIER_ROUTES.product(productId)}?${searchParams.toString()}`;
  }
  if (isShopkeepApp()) {
    searchParams.append('appType', 'shopkeep');
    return `${SHOPKEEP_ROUTES.product(productId)}?${searchParams.toString()}`;
  }

  // There's a chance that neither isSupplierApp or isShopkeepApp will work,
  // which is when we check for the current 'appType' query parameter.
  // Example: when we click on a product from the bottom carousel of the PDP
  const {
    query: { appType: rawCurrentAppType },
  } = router;
  const currentAppType = stringifiedParam(rawCurrentAppType);
  const routes = currentAppType === 'supplier' ? SUPPLIER_ROUTES : SHOPKEEP_ROUTES;
  searchParams.append('appType', currentAppType);
  return `${routes.product(productId)}?${searchParams.toString()}`;
};

/**
 * Opens a route on the SUP or SK app, depending on the user's role.
 * TODO(felipe): find a way to appropriately type `routeName` to make it
 */
export const openRoleAgnosticRoute = async (
  routeName: keyof typeof SUPPLIER_ROUTES | keyof typeof SHOPKEEP_ROUTES,
  argument?: string,
  queryParameters?: QueryParameterRecord,
): Promise<boolean> => {
  if (isSupplierApp()) {
    return openInternalRoute(routeName, SUPPLIER_ROUTES, argument, { queryParameters });
  }
  return openInternalRoute(routeName, SHOPKEEP_ROUTES, argument, { queryParameters });
};

export const getRoleAgnosticRoute = (
  routeName: string,
  argument?: string,
  queryParameters?: QueryParameterRecord,
): string => {
  if (isSupplierApp()) {
    return getInternalRoute(routeName, SUPPLIER_ROUTES, argument, { queryParameters });
  }
  return getInternalRoute(routeName, SHOPKEEP_ROUTES, argument, { queryParameters });
};

/**
 * Opens a Spyglass specific route in this tab
 */
export const openSpyglassRoute = async (
  routeName: keyof typeof SPYGLASS_ROUTES,
  argument?: string,
  queryParameters?: QueryParameterRecord,
): Promise<boolean> => openInternalRoute(routeName, SPYGLASS_ROUTES, argument, { queryParameters });

/**
 * Takes in path string and returns the last path.
 */
export const getLatestPath = (longPath: string): string => {
  if (longPath.length === 0) {
    return '';
  }
  const path = longPath.split('/');
  return path[path.length - 1];
};

/**
 * Once an action is complete, we want to set the query parameter to `true`
 * so there are no more actions taken (i.e segment tracking).
 * @param router NextJS router used to replace the query parameter
 * @param key Key to have it's current value be replaced
 * @param value Value to replace current value
 */
export const replaceQueryParameterValue = (router: NextRouter, key: string, value: string) => {
  // We're fine with reassigning `router` here
  // since we're confident that the value being
  // replaced is concerned with the value being
  // passed in.
  // eslint-disable-next-line no-param-reassign
  router.query[key] = value;
  router.replace(router);
};

export const updateOnboardingRoute = (
  appType: AppType,
  onboardingType: OnboardingType,
  onboardingStep: OnboardingStep,
) => {
  const route = `/${appType}/onboarding/${onboardingType}/${onboardingStep}`;
  router.replace(route, undefined, { shallow: true });
};
