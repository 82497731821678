/**
 * Determines whether localStorage is supported by the user's browser or not. If not, it swallows
 * the error and returns false. This is useful whenever calling localStorage in the app, since
 * it does not work in incognito browsing due to default browser permissions.
 *
 * If localStorage is not supported or allowed by the browser, localStorage calls will throw
 * runtime errors and break other parts of the page
 *
 * Reference: https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
 */
export const isLocalStorageSupported = (): boolean => {
  try {
    const key = '__ls_test_key_not_used_for_anything__';
    localStorage.setItem(key, key);
    localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Wraps localStorage.key in isLocalStorageSupported, which checks if localStorage
 * is supported by the browser and its permissions. This should always be used instead
 * of the built-in localStorage.key.
 * @ref https://developer.mozilla.org/en-US/docs/Web/API/Storage/key
 */
export const getKeyFromLocalStorage = (index: number): string | null =>
  isLocalStorageSupported() ? localStorage.key(index) : null;

/**
 * Wraps localStorage.getItem in isLocalStorageSupported, which checks if localStorage
 * is supported by the browser and its permissions. This should always be used instead
 * of the built-in localStorage.getItem.
 */
export const getItemFromLocalStorage = (key: string): string | null =>
  isLocalStorageSupported() ? localStorage.getItem(key) : null;

/**
 * Wraps localStorage.setItem in isLocalStorageSupported, which checks if localStorage
 * is supported by the browser and its permissions. This should always be used instead
 * of the built-in localStorage.setItem so we can be sure we have permission to set values
 * in local storage before we attempt to do so.
 */
export const setItemToLocalStorage = (key: string, value: string): void => {
  if (isLocalStorageSupported()) {
    localStorage.setItem(key, value);
  }
};

/**
 * Wraps localStorage.removeItem in isLocalStorageSupported, which checks if localStorage
 * is supported by the browser and its permissions. This should always be used instead
 * of the built-in localStorage.removeItem so we can be sure we have permission to remove values
 * in local storage before we attempt to do so.
 */
export const removeItemFromLocalStorage = (key: string): void => {
  if (isLocalStorageSupported()) {
    localStorage.removeItem(key);
  }
};
