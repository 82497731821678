import { AxiosResponse } from 'axios';
import api from 'lib/api';

const ROUTE = '/platform/csv/product/import/';

/**
 * Uploads products for a shop and returns the results.
 * @param csvFile csv with products
 * @param isGhostMode If we're in ghost mode, we don't want to call api.post.
 * TODO(alex): Ideally we'd check this within our API instead of passing an argument here,
 * but this is fast for now. I'm making this arg required so it doesn't accidentally get forgotten.
 */
const uploadCreateProducts = async (
  csvFile: File,
  isGhostMode: boolean,
): Promise<AxiosResponse<never> | void> => {
  if (isGhostMode) return Promise.resolve();
  const formData = new FormData();
  formData.append('csv_file', csvFile);
  const headers = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return api.post(ROUTE, formData, headers);
};

export default uploadCreateProducts;
