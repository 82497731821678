/**
 * Type guard to make sure item isn't null. Use in an array's `.filter` to get
 * a non-nullable type out of the filter: `itemOrNullArray.map(isNonNullItem)`.
 */
export const isNonNullItem = <ItemType>(item: ItemType | null): item is ItemType => item !== null;

/**
 * Type guard to make sure item isn't undefined. Use in an array's `.filter` to get
 * a non-undefined type out of the filter: `itemOrNullArray.map(isNonUndefinedItem)`.
 */
export const isNonUndefinedItem = <ItemType>(item: ItemType | undefined): item is ItemType =>
  item !== undefined;

/**
 * Type guard to make sure item isn't undefined, false or null
 */
export const isTruthyItem = <ItemType>(
  item: ItemType | undefined | null | false,
): item is ItemType => item != null && item !== false && item !== undefined;

/**
 * Determines if an object contains any null values
 */
export function hasNullValues<T>(obj: T): boolean {
  for (const key in obj) {
    if (obj[key] === null) {
      return true;
    }
  }
  return false;
}
