import { zIndex } from 'lib/helpers/zIndex';
import Portal from 'panama/layout/Portal';
import React, { CSSProperties, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import styled, { css } from 'styled-components';

export interface Props {
  children: React.ComponentProps<'div'>['children'];
  isOpen: boolean;
  onClose: () => void;

  /**
   * Where the content should be positioned
   */
  contentPosition: 'left' | 'center' | 'right';

  /**
   * Duration of fade in milliseconds
   */
  fadeDuration: number;

  /**
   * Direction of where the fade appears and disappears from
   */
  fadeDirection: 'top' | 'right' | 'bottom' | 'left';

  /**
   * Optional prop to fit entire screen on all sizes, with 32px padding all around (defaults to false)
   */
  isEntireScreen?: boolean;

  /**
   * Optional prop to set container id
   */
  id?: string;
}

const CONTENT_POSITION = (position: Props['contentPosition']): CSSProperties['justifyContent'] => {
  switch (position) {
    case 'left':
      return 'start';
    case 'center':
      return 'center';
    case 'right':
      return 'end';
    default:
      return 'center';
  }
};

const Wrapper = styled.div<{ $contentPosition: Props['contentPosition'] }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex('modal')};
  background-color: rgb(0 0 0 / 30%);
  display: flex;
  align-items: center;
  justify-content: ${({ $contentPosition }) =>
    $contentPosition && CONTENT_POSITION($contentPosition)};
`;

const Container = styled.div<{
  $contentPosition: Props['contentPosition'];
  $isEntireScreen: boolean;
}>`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: ${({ $contentPosition }) =>
    $contentPosition && CONTENT_POSITION($contentPosition)};
  ${({ $isEntireScreen }) =>
    $isEntireScreen &&
    css`
      padding: 32px;
    `};
`;

/**
 * Renders an overlay to be used for components that want to shade out the app
 * and only focus on the children to be rendered. Examples are a modal, mini cart, etc.
 */
const Overlay = ({
  children,
  isOpen,
  onClose,
  contentPosition,
  fadeDuration,
  fadeDirection,
  isEntireScreen = false,
  id,
}: Props): React.ReactElement | null => {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e?.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', onKeyDown);
      return () => window.removeEventListener('keydown', onKeyDown);
    }
  }, [isOpen, onClose]);

  const onClickContainer = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      // Hide when click is directly on overlay (not on a child).
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <Portal>
      <Wrapper $contentPosition={contentPosition}>
        <Fade {...{ [fadeDirection]: true }} duration={fadeDuration}>
          <Container
            onClick={onClickContainer}
            $contentPosition={contentPosition}
            $isEntireScreen={isEntireScreen}
            id={id}
          >
            {children}
          </Container>
        </Fade>
      </Wrapper>
    </Portal>
  );
};

export default Overlay;
