import { Context as AppBridgeContext, RoutePropagator } from '@shopify/app-bridge-react';
import { Redirect } from '@shopify/app-bridge/actions';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

/**
 * When mounted inside a Shopify AppBridgeProvider, this component syncs the URL
 * of the embedded frame with the parent Shopify admin page.
 */
const ShopifyRoutePropagator = () => {
  const router = useRouter();
  const { route, asPath } = router;
  const appBridge = useContext(AppBridgeContext);

  useEffect(() => {
    appBridge?.subscribe(Redirect.Action.APP, (payload: { path: string }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push(payload.path);
    });

    // We only want this to fire once, so we shouldn't include router in the
    // dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!route || !asPath) {
    return null;
  }

  return <RoutePropagator location={asPath} />;
};

export default ShopifyRoutePropagator;
