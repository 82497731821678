/**
 * This file defines Canal's typography system for use WITHIN the Shopify apps.
 *
 * For typography used OUTSIDE of the Shopify apps, see: styles/typography.ts
 */
import { css } from 'styled-components';
import shopifyAppTheme from 'styles/shopifyAppTheme';

export type TextVariant =
  | 'pageTitle'
  | 'titleSmall'
  | 'titleRegular'
  | 'titleMobile'
  | 'titleLarge'
  | 'titleHuge'
  | 'subtitleRegular'
  | 'subtitleBold'
  | 'subtitleBoldest'
  | 'shopifyBodyRegular'
  | 'shopifyBodyRegularBold'
  | 'shopifyBodyLarge'
  | 'shopifyBodyLargeBold'
  | 'shopifyCaption'
  | 'brandRegular'
  | 'whiteRegular'
  | 'whiteBold';

export const textVariants: { [key in TextVariant]: TextVariant } = {
  pageTitle: 'pageTitle',
  titleSmall: 'titleSmall',
  titleRegular: 'titleRegular',
  titleMobile: 'titleMobile',
  titleLarge: 'titleLarge',
  titleHuge: 'titleHuge',
  subtitleRegular: 'subtitleRegular',
  subtitleBold: 'subtitleBold',
  subtitleBoldest: 'subtitleBoldest',
  shopifyBodyRegular: 'shopifyBodyRegular',
  shopifyBodyRegularBold: 'shopifyBodyRegularBold',
  shopifyBodyLarge: 'shopifyBodyLarge',
  shopifyBodyLargeBold: 'shopifyBodyLargeBold',
  shopifyCaption: 'shopifyCaption',
  brandRegular: 'brandRegular',
  whiteRegular: 'whiteRegular',
  whiteBold: 'whiteBold',
};

const TEXT_STYLES: {
  [variant in TextVariant]: {
    size: number;
    weight: string;
    lineHeight: number;
    color?: string;
  };
} = {
  pageTitle: {
    size: 28,
    weight: shopifyAppTheme.weight.regular,
    lineHeight: 36,
  },
  titleSmall: {
    size: 24,
    weight: shopifyAppTheme.weight.boldest,
    lineHeight: 32,
  },
  titleRegular: {
    size: 32,
    weight: shopifyAppTheme.weight.bolder,
    lineHeight: 44,
  },
  titleMobile: {
    size: 37,
    weight: shopifyAppTheme.weight.boldest,
    lineHeight: 44,
  },
  titleLarge: {
    size: 70,
    weight: shopifyAppTheme.weight.boldest,
    lineHeight: 78,
  },
  titleHuge: {
    size: 96,
    weight: shopifyAppTheme.weight.boldest,
    lineHeight: 112,
  },
  subtitleRegular: {
    size: 18,
    weight: shopifyAppTheme.weight.regular,
    lineHeight: 24,
  },
  subtitleBold: {
    size: 18,
    weight: shopifyAppTheme.weight.bold,
    lineHeight: 24,
  },
  subtitleBoldest: {
    size: 18,
    weight: shopifyAppTheme.weight.boldest,
    lineHeight: 24,
  },
  shopifyBodyRegular: {
    size: 14,
    weight: shopifyAppTheme.weight.regular,
    lineHeight: 20,
  },
  shopifyBodyRegularBold: {
    size: 14,
    weight: shopifyAppTheme.weight.stronger,
    lineHeight: 20,
  },
  shopifyBodyLarge: {
    size: 16,
    weight: shopifyAppTheme.weight.regular,
    lineHeight: 24,
  },
  shopifyBodyLargeBold: {
    size: 16,
    weight: shopifyAppTheme.weight.bold,
    lineHeight: 24,
  },
  shopifyCaption: {
    size: 12,
    weight: shopifyAppTheme.weight.regular,
    lineHeight: 16,
  },
  brandRegular: {
    size: 14,
    weight: shopifyAppTheme.weight.regular,
    lineHeight: 20,
    color: shopifyAppTheme.color.brandRegular,
  },
  whiteRegular: {
    size: 14,
    weight: shopifyAppTheme.weight.regular,
    lineHeight: 20,
    color: shopifyAppTheme.color.white,
  },
  whiteBold: {
    size: 14,
    weight: shopifyAppTheme.weight.bold,
    lineHeight: 20,
    color: shopifyAppTheme.color.white,
  },
};

export const shopifyAppTextStyle = (variant: TextVariant): ReturnType<typeof css> => css`
  font-size: ${TEXT_STYLES[variant].size}px;
  font-weight: ${TEXT_STYLES[variant].weight};
  line-height: ${TEXT_STYLES[variant].lineHeight}px;
  ${TEXT_STYLES[variant].color &&
  css`
    color: ${TEXT_STYLES[variant].color};
  `};
`;
