import Text from 'panama/components/Text';
import theme from 'panama/styles/theme';
import { textStyle } from 'panama/styles/typography';
import styled, { css } from 'styled-components';

export const INVALID_INPUT_COLOR = theme.color.critical[500];

interface Props {
  $shouldWrapInvalidMessage?: boolean;
}

const ErrorText = styled(Text)<Props>`
  ${textStyle('caption')};
  color: ${INVALID_INPUT_COLOR};
  display: flex;
  gap: 8px;
  padding-top: 4px;
  padding-left: 8px;
  ${({ $shouldWrapInvalidMessage }) =>
    $shouldWrapInvalidMessage &&
    css`
      align-items: center;
      padding: 0;
      white-space: nowrap;
    `};
`;

export default ErrorText;
