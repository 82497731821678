import { EXTERNAL_URLS } from 'lib/constants/routes';
import Text from 'panama/components/Text';
import Link from 'panama/components/link/Link';
import Stack from 'panama/layout/Stack';
import theme from 'panama/styles/theme';
import React from 'react';
import { ZendeskAPI } from 'react-zendesk';
import styled from 'styled-components';

const ParentContainer = styled(Stack).attrs({ justifyContent: 'center', alignItems: 'center' })`
  height: 100vh;
`;

const StackCard = styled(Stack).attrs({
  isVertical: true,
  justifyContent: 'center',
  alignItems: 'center',
})`
  text-align: center;
  width: 500px;
`;

const ScheduledMaintenance = (): React.ReactElement => {
  const onClickContact = (event: React.MouseEvent) => {
    event.preventDefault();
    ZendeskAPI('messenger', 'open');
  };

  return (
    <ParentContainer>
      <StackCard gap="16px">
        <img alt="" src="/icons/tool-icon.svg" />
        <Text $variant="h1" color={theme.color.primary[700]}>
          Be back soon
        </Text>
        <Text $variant="bodyRegular">
          We are undergoing scheduled maintenance to upgrade our technology and features. Orders and
          fulfillment will not be affected during this time. Please{' '}
          <Link onClick={onClickContact} href={EXTERNAL_URLS.WEBSITE}>
            contact us
          </Link>{' '}
          with any questions.
        </Text>
      </StackCard>
    </ParentContainer>
  );
};

export default ScheduledMaintenance;
