import Image from 'next/legacy/image';
import Button from 'panama/components/buttons/Button';
import Dropzone from 'panama/components/dropzone/Dropzone';
import Text from 'panama/components/Text';
import Stack from 'panama/layout/Stack';
import theme from 'panama/styles/theme';
import styled from 'styled-components';

const UploadIconContainer = styled.div`
  padding-bottom: 12px;
`;
const UploadIcon = () => (
  <UploadIconContainer>
    <Image src="/icons/upload.svg" alt="upload icon" width={56} height={56} />
  </UploadIconContainer>
);

export const FileIcon = styled.i.attrs({ className: 'ri-file-text-line' })`
  font-size: 24px;
  vertical-align: middle;
`;

const FilePreview = styled(Stack).attrs({
  alignItems: 'flex-start',
  gap: 8,
})`
  width: 100%;
  height: 100%;
`;

const FilePreviewRow = styled(Stack).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 8,
})`
  width: 100%;
  border-top: 1px solid ${theme.color.black[400]};
  border-bottom: 1px solid ${theme.color.black[400]};
  padding: 24px 0;
`;

const FileName = styled(Text).attrs({ $variant: 'bodyRegular' })`
  display: flex;
  gap: 8px;
  align-items: center;
`;

interface Props {
  csvFile: File | null;
  setCsvFile: (csvFile: File | null) => void;
  isFullWidth?: boolean;
}

const CsvDropzone = ({ csvFile, setCsvFile, isFullWidth }: Props) => (
  <Dropzone
    accept={{ 'text/csv': ['.csv'] }}
    size="large"
    handleFile={(csvFile) => {
      setCsvFile(csvFile);
    }}
    customIcon={<UploadIcon />}
    hasNoBorder={csvFile !== null}
    noClick={csvFile !== null}
    hasTransparentBackground
    isFullWidth={isFullWidth}
  >
    {({ open }) =>
      !csvFile ? null : (
        <FilePreview>
          <FilePreviewRow>
            <FileName>
              <FileIcon /> {csvFile.name}
            </FileName>
            <Button id="replace-csv-file-button" onClick={open} size="medium" variant="ghost">
              Replace file
            </Button>
          </FilePreviewRow>
        </FilePreview>
      )
    }
  </Dropzone>
);

export default CsvDropzone;
