import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import shopifyAppTheme from 'styles/shopifyAppTheme';
import { shopifyAppTextStyle } from 'styles/shopifyAppTypography';

const GlobalStyle = createGlobalStyle`
  ${normalize}

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    color: ${shopifyAppTheme.color.black};
    ${shopifyAppTextStyle('shopifyBodyRegular')};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: ${shopifyAppTheme.color.black};
    
    &:hover {
      color: ${shopifyAppTheme.color.blue};
      transition: color 0.1s ease-out;
    }
  }

  /* stylelint-disable */
  .Polaris-Frame-Toast {
    border-radius: 0 !important;
  }
  
  .Polaris-Frame__Skip {
    display: none;
  }
  /* stylelint-enable */
`;

export default GlobalStyle;
