import { createContext } from 'react';

export interface ImportTracking {
  /**
   * Opens a modal for importing products
   */
  openModal: () => void;

  /**
   * Import tracking function is used by the modal, takes a CSV file and an optional error handler
   */
  importTracking: (
    /**
     * CSV file to import
     */
    csvFile: File | null,
    /**
     * Optional error handler, if provided should override the existing behavior which is to open a modal
     */
    onError?: (error: unknown) => void,
  ) => Promise<void>;

  /**
   * Whether or not the import is currently in progress
   */
  isImporting: boolean;
}

const ImportTrackingContext = createContext<ImportTracking | undefined>(undefined);

export default ImportTrackingContext;
