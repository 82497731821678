import React, { CSSProperties, forwardRef, useMemo } from 'react';
import styled, { css } from 'styled-components';

export type Props = React.ComponentPropsWithoutRef<'div'> & {
  /**
   * Whether the stack flows horizontally or vertically. Defaults to `false`, meaning horizontal.
   */
  isVertical?: boolean;

  /**
   * Whether the stack is reversed. Defaults to `false`, meaning "flow left to right or
   * top to bottom" depending on the value of `isVertical`.
   */
  isReversed?: boolean;

  /**
   * How the stack should align its items
   */
  alignItems?: CSSProperties['alignItems'] | 'left' | 'right';

  /**
   * How the stack should justify its content in comparison with its parent
   */
  justifyContent?: CSSProperties['justifyContent'] | 'left' | 'right';

  /**
   * Spacing between items
   */
  gap?: CSSProperties['gap'];

  /**
   * Wrapping behavior for the contents of the stack
   */
  wrap?: CSSProperties['flexWrap'];

  /**
   * Sets the height, width, and flex-basis to 100%
   */
  full?: boolean;

  /**
   * Sets display: inline-flex on the Stack
   */
  inline?: boolean;
};

interface StyledProps {
  $isVertical: boolean;
  $isReversed: boolean;
  $alignItems: Props['alignItems'];
  $justifyContent: Props['justifyContent'];
  $gap: Props['gap'];
  $wrap: Props['wrap'];
  $full: Props['full'];
  $inline: Props['inline'];
}

// Uses the appropriate alignment based on the verticality of the component
const Container = styled.div<StyledProps>`
  display: flex;
  ${({ $inline }) =>
    $inline &&
    css`
      display: inline-flex;
    `};
  ${({ $full }) =>
    $full &&
    css`
      width: 100%;
      height: 100%;
      flex-basis: 100%;
    `};
  ${({ $wrap }) =>
    $wrap &&
    css`
      flex-wrap: ${$wrap};
    `};
  ${({ $isVertical, $isReversed }) => {
    if ($isVertical) {
      return $isReversed
        ? css`
            flex-direction: column-reverse;
          `
        : css`
            flex-direction: column;
          `;
    }
    return (
      $isReversed &&
      css`
        flex-direction: row-reverse;
      `
    );
  }};
  ${({ $gap }) =>
    $gap &&
    css`
      gap: ${typeof $gap === 'string' ? $gap : `${$gap}px`};
    `}
  ${({ $alignItems }) =>
    $alignItems &&
    css`
      align-items: ${$alignItems};
    `}
  ${({ $justifyContent }) =>
    $justifyContent &&
    css`
      justify-content: ${$justifyContent};
    `}
`;

/**
 * Stacks one or more pieces of data vertically or horizontally.
 */
const Stack: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    children,
    isVertical,
    isReversed,
    alignItems,
    justifyContent,
    gap,
    wrap,
    full,
    inline,
    ...restOfProps
  }: Props,
  ref: React.Ref<HTMLDivElement>,
): React.ReactElement => {
  // We allow `left` and `right` for align items, so convert any use of those to `flex-` equivalents
  const safeAlignedItems = useMemo(() => {
    switch (alignItems) {
      case 'left':
        return 'flex-start';
      case 'right':
        return 'flex-end';
      default:
        return alignItems;
    }
  }, [alignItems]);

  // We allow `left` and `right` for justify content, so convert any use of those to `flex-` equivalents
  const safeJustifyContent = useMemo(() => {
    switch (justifyContent) {
      case 'left':
        return 'flex-start';
      case 'right':
        return 'flex-end';
      default:
        return justifyContent;
    }
  }, [justifyContent]);

  return (
    <Container
      ref={ref}
      $isVertical={isVertical ?? false}
      $isReversed={isReversed ?? false}
      $alignItems={safeAlignedItems}
      $justifyContent={safeJustifyContent}
      $gap={gap}
      $wrap={wrap}
      $full={full}
      $inline={inline}
      {...restOfProps}
    >
      {children}
    </Container>
  );
};

export default forwardRef<HTMLDivElement, Props>(Stack);
