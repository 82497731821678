import axios from 'axios';
import time from 'lib/constants/time';

/** This is proxied to the backend in next.config.js */
export const API_URL = '/api';

const TIMEOUT_MS = 20 * time.msPerSecond;

const api = axios.create({
  baseURL: API_URL,
  timeout: TIMEOUT_MS,
});

export default api;
