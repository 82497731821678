import { createContext } from 'react';

/**
 * Re-shows the toast with a new message
 */
export type ToastToggler = (
  message: string,
  options?: Partial<{
    isLoading: boolean;
    duration: number;
    actionText?: string;
    onAction?: () => void;
  }>,
) => void;

const ToastTogglerContext = createContext<ToastToggler | undefined>(undefined);

export default ToastTogglerContext;
