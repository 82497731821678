import { gql } from '@apollo/client';
import {
  PutPersistedJsonForShopMutation,
  PutPersistedJsonForShopMutationVariables,
} from 'gql/graphql';
import client from 'lib/api/ApolloClient';

const MUTATION = gql`
  mutation PutPersistedJsonForShop($persistedJson: JSONString!) {
    putPersistedJsonForShop(persistedJson: $persistedJson) {
      ok
      persistedJson
    }
  }
`;

/**
 * Updates `persistedJson` that lives on the logged in Shop. The
 * `persistedJson` field corresponds to the items stored in the
 * shared cart.
 * This is a temporary replacement to the usage of local storage.
 */
const putPersistedJsonForShop = async (persistedJson: string) => {
  const { data } = await client.mutate<
    PutPersistedJsonForShopMutation,
    PutPersistedJsonForShopMutationVariables
  >({
    mutation: MUTATION,
    variables: {
      persistedJson,
    },
  });

  return { data: data?.putPersistedJsonForShop ?? null };
};

export default putPersistedJsonForShop;
