/**
 * This file defines Canal's non-typography styles for use OUTSIDE of the Shopify apps.
 *
 * This files includes: colors, box shadows, and breakpoint definitions
 *
 * For styles used WITHIN the Shopify apps, see: styles/shopifyAppTheme.ts
 */
const theme = {
  /**
   * Colors for use throughout Canal. There are multiple color categories, each with
   * a handful of variations within. Higher numbers mean more saturation/color. For
   * example, primary['700'] is darker than primary['400']
   */
  color: {
    // Grays: This color is used for backgrounds, icons, and division lines.
    gray: {
      900: '#272D2E',
      700: '#484F50',
      500: '#7A8282',
      400: '#B7BDBD',
      300: '#D4D9D9',
      200: '#F0F2F2',
      100: '#F7FAFA',
    },

    // Blacks: This color is used for text on light backgrounds
    black: {
      900: '#000000',
      800: '#242424',
      700: '#4D4D4D',
      600: '#636363',
      500: '#7A7A7A',
      400: '#BDBDBD',
    },

    // Whites: This color is used for text on dark backgrounds
    white: {
      900: '#FFFFFF',
      800: '#F8F8F8',
      700: '#F1F2F2',
      600: '#E1E1E1',
      500: '#CCCCCC',
    },

    // Aquamarines: This color is displayed most frequently and be used for important actions
    primary: {
      900: '#003D45',
      800: '#005C68',
      700: '#007A8A', // Primary brand color
      500: '#0095A0',
      400: '#5AAFB7', // Primary variant brand color
      300: '#94C9CE',
      200: '#CAE3E6',
      150: '#E0EFF1',
      100: '#F4F9F9',
    },

    // Greens: This color is used to show positive feedback or status.
    success: {
      900: '#3C4C38',
      500: '#768F6E',
      100: '#D8E1D6',
    },

    // Mustards: This color is used to show warning feedback or status.
    warning: {
      900: '#D5A354',
      700: '#DDB579',
      500: '#E6C899',
      300: '#EDD9BC',
      100: '#F6ECDC',
    },

    // Reds: This color is used to show negative feedback or status.
    critical: {
      900: '#660B0B',
      500: '#AE1717',
      300: '#FAE0E0',
      100: '#FFEFEF',
    },

    // Used for loaders (skeleton text, etc)
    loader: '#E4E5E7',
  },

  boxShadow: {
    lightest: '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)',
    lighter: '0px 2px 6px rgba(0, 0, 0, 0.06), 0px 4px 12px rgba(35, 41, 54, 0.08)',
    light: '0px 1px 4px rgba(0, 0, 0, 0.06), 0px 8px 16px rgba(35, 41, 54, 0.08)',
    medium: '0px 4px 8px rgba(0, 0, 0, 0.06), 0px 12px 32px rgba(35, 41, 54, 0.14)',
    dark: '0px 4px 16px rgba(0, 0, 0, 0.06), 0px 16px 64px rgba(35, 41, 54, 0.2)',
    darker: '0px 0px 4px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.2)',
  },

  // Same as the box shadows, but used with `filter:` instead
  dropShadow: {
    lightest:
      'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2)) drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1))',
  },

  /**
   * Each breakpoint is defined by the MINIMUM screen width required to qualify as that breakpoint. Once
   * the screen width increases to the next highest breakpoint, the screen is at that higher breakpoint.
   *
   * For example, the 'tablet' breakpoint comprises screen widths from 768px to 1199px. Desktop is 1200px and wider.
   */
  breakpoints: {
    mobile: '320px',
    tablet: '768px',
    desktop: '1200px',
  },
};

export default theme;
