import type {
  IconStyle,
  IconVariant,
  StyleProps,
  VariantStyle,
  VariantType,
} from 'panama/components/link/types';
import theme from 'panama/styles/theme';
import { css } from 'styled-components';

export const ICON_STYLES: Record<IconVariant, IconStyle> = {
  arrow: {
    className: 'ri-arrow-right-s-line',
    placement: 'right',
  },
  add: {
    className: 'ri-add-line',
    placement: 'left',
  },
  external: {
    className: 'ri-external-link-line',
    placement: 'right',
  },
  links: {
    className: 'ri-links-line',
    placement: 'left',
  },
  instagram: {
    className: 'ri-instagram-line',
    placement: 'left',
  },
  view: {
    className: 'ri-eye-line',
    placement: 'left',
  },
  mail: {
    className: 'ri-mail-line',
    placement: 'left',
  },
};

/**
 * All of the properties for each variant
 */
export const VARIANT_STYLES: Record<VariantType, VariantStyle> = {
  helpCaption: {
    color: theme.color.black[500],
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: 'currentColor',
    colorOnHover: theme.color.primary[700],
    textDecorationLineOnHover: 'underline',
    textDecorationStyleOnHover: 'solid',
    textDecorationColorOnHover: 'currentColor',
    cursorOnHover: 'pointer',
    disabledColor: theme.color.gray[400],
  },
  helpRegular: {
    color: theme.color.black[600],
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: 'currentColor',
    colorOnHover: theme.color.primary[700],
    textDecorationLineOnHover: 'underline',
    textDecorationStyleOnHover: 'solid',
    textDecorationColorOnHover: 'currentColor',
    cursorOnHover: 'pointer',
    disabledColor: theme.color.gray[400],
  },
  helpBold: {
    color: theme.color.black[600],
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: 'currentColor',
    colorOnHover: theme.color.primary[700],
    textDecorationLineOnHover: 'underline',
    textDecorationStyleOnHover: 'solid',
    textDecorationColorOnHover: 'currentColor',
    cursorOnHover: 'pointer',
    disabledColor: theme.color.gray[400],
  },
  icon: {
    color: theme.color.black[800],
    textDecorationLine: 'none',
    textDecorationStyle: 'none',
    textDecorationColor: 'currentColor',
    colorOnHover: theme.color.primary[700],
    textDecorationLineOnHover: 'none',
    textDecorationStyleOnHover: 'none',
    textDecorationColorOnHover: 'none',
    cursorOnHover: 'pointer',
    disabledColor: theme.color.gray[400],
  },
  iconGray: {
    color: theme.color.black[600],
    textDecorationLine: 'none',
    textDecorationStyle: 'none',
    textDecorationColor: 'currentColor',
    colorOnHover: theme.color.primary[700],
    textDecorationLineOnHover: 'none',
    textDecorationStyleOnHover: 'none',
    textDecorationColorOnHover: 'none',
    cursorOnHover: 'pointer',
    disabledColor: theme.color.gray[400],
  },
  tooltip: {
    color: theme.color.black[600],
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: 'currentColor',
    colorOnHover: theme.color.black[800],
    textDecorationLineOnHover: 'underline',
    textDecorationStyleOnHover: 'solid',
    textDecorationColorOnHover: 'currentColor',
    cursorOnHover: 'inherit',
    disabledColor: theme.color.black[600],
  },
  default: {
    color: 'inherit',
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: 'currentColor',
    colorOnHover: theme.color.primary[700],
    textDecorationLineOnHover: 'underline',
    textDecorationStyleOnHover: 'solid',
    textDecorationColorOnHover: 'currentColor',
    cursorOnHover: 'pointer',
    disabledColor: theme.color.gray[400],
  },
  primary: {
    color: theme.color.primary[700],
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: 'currentColor',
    colorOnHover: theme.color.primary[900],
    textDecorationLineOnHover: 'underline',
    textDecorationStyleOnHover: 'solid',
    textDecorationColorOnHover: 'currentColor',
    cursorOnHover: 'pointer',
    disabledColor: theme.color.gray[400],
  },
  critical: {
    color: theme.color.critical[500],
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: 'currentColor',
    colorOnHover: theme.color.critical[900],
    textDecorationLineOnHover: 'underline',
    textDecorationStyleOnHover: 'solid',
    textDecorationColorOnHover: 'currentColor',
    cursorOnHover: 'pointer',
    disabledColor: theme.color.gray[400],
  },
  plain: {
    color: theme.color.black[800],
    textDecorationLine: 'none',
    textDecorationStyle: 'solid',
    textDecorationColor: 'currentColor',
    colorOnHover: theme.color.black[700],
    textDecorationLineOnHover: 'underline',
    textDecorationStyleOnHover: 'solid',
    textDecorationColorOnHover: 'currentColor',
    cursorOnHover: 'pointer',
    disabledColor: theme.color.gray[400],
  },
  none: {
    color: 'inherit',
    textDecorationLine: 'none',
    textDecorationStyle: 'solid',
    textDecorationColor: 'currentColor',
    colorOnHover: 'inherit',
    textDecorationLineOnHover: 'none',
    textDecorationStyleOnHover: 'solid',
    textDecorationColorOnHover: 'currentColor',
    cursorOnHover: 'pointer',
    disabledColor: theme.color.gray[400],
  },
};

const COLOR_STYLES = ({ variant, isWhite, isDisabled }: StyleProps): string => {
  if (isWhite) return theme.color.white[700];
  if (isDisabled) return VARIANT_STYLES[variant].disabledColor;
  return VARIANT_STYLES[variant].color;
};

const TEXT_DECORATION_STYLES = (styleProps: StyleProps, isOnHover = false): string => {
  const { variant, isWhite, isDisabled } = styleProps;
  if (variant === 'icon' || (isDisabled && variant !== 'default')) return 'none';
  const variantStyle = VARIANT_STYLES[variant];
  const line = isOnHover ? variantStyle.textDecorationLineOnHover : variantStyle.textDecorationLine;
  const style = isOnHover
    ? variantStyle.textDecorationStyleOnHover
    : variantStyle.textDecorationStyle;
  let color = isOnHover
    ? variantStyle.textDecorationColorOnHover
    : variantStyle.textDecorationColor;
  if (isDisabled) color = variantStyle.disabledColor;
  if (isWhite) color = isOnHover ? theme.color.gray[300] : theme.color.white[700];
  return `${line} ${style} ${color}`;
};

/**
 * Main styles for the Link component
 */
export const STYLES = ({
  variant,
  isWhite = false,
  isDisabled = false,
  isUnderlined = true,
}: StyleProps): ReturnType<typeof css> => css`
  color: ${COLOR_STYLES({ variant, isWhite, isDisabled })};
  text-decoration: ${isUnderlined
    ? TEXT_DECORATION_STYLES({ variant, isWhite, isDisabled })
    : 'none'};
  text-underline-position: under;
  cursor: ${isDisabled ? 'not-allowed' : VARIANT_STYLES[variant].cursorOnHover};

  &:hover {
    ${!isDisabled &&
    css`
      color: ${isWhite ? theme.color.gray[300] : VARIANT_STYLES[variant].colorOnHover};
    `};
    ${!isDisabled &&
    css`
      text-decoration: ${isUnderlined
        ? TEXT_DECORATION_STYLES({ variant, isWhite, isDisabled }, true)
        : 'none'};
    `};
  }
`;
