import { useContext } from 'react';
import ExportLineItemsContext from './ExportLineItemsContext';

/**
 * Convenience hook for exporting products
 */
const useExportLineItems = () => {
  const exportLineItems = useContext(ExportLineItemsContext);
  if (exportLineItems === undefined) {
    throw new Error('useExportLineItems must be used within a ExportLineItemsProvider');
  }

  return exportLineItems;
};

export default useExportLineItems;
