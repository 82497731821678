import { zIndex } from 'lib/helpers/zIndex';
import Text from 'panama/components/Text';
import PlainButton, { getPlainButtonTextColor } from 'panama/components/buttons/PlainButton';
import Stack from 'panama/layout/Stack';
import theme from 'panama/styles/theme';
import React, { ComponentProps, useEffect, useMemo, useRef, useState } from 'react';
import { Arrow, useHover, useLayer } from 'react-laag';
import type { PlacementType } from 'react-laag/dist/PlacementType';
import styled, { css } from 'styled-components';
import type { Props } from '.';

// In px, how much the tooltip is offset vertically/horizontally from the parent
const TOOLTIP_OFFSET = 15;

const backgroundColor = ($isDark: boolean): string =>
  $isDark ? theme.color.black[800] : theme.color.white[900];

const foregroundColor = ($isDark: boolean) =>
  $isDark ? theme.color.white[800] : theme.color.black[700];

type ColorStylesProps = { $isDark: boolean };
const colorStyles = css<ColorStylesProps>`
  background-color: ${({ $isDark }) => backgroundColor($isDark)};
  color: ${({ $isDark }) => foregroundColor($isDark)};
`;

type TooltipContainerProps = {
  $zIndex: number;
};
const TooltipContainer = styled.div<TooltipContainerProps>`
  z-index: ${({ $zIndex }) => $zIndex};
  position: relative;
  filter: ${theme.dropShadow.lightest};
`;

const TooltipBody = styled.div<{
  $isDark: boolean;
  $isLong: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 8px;
  width: fit-content;
  max-width: 223px;
  padding: ${({ $isLong }) => ($isLong ? '12px 16px' : '6px 8px')};
  ${colorStyles};
`;

const ReactLaagArrow = (
  props: Omit<ComponentProps<typeof Arrow>, 'onPointerEnterCapture' | 'onPointerLeaveCapture'>,
) => <Arrow onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} {...props} />;

const TooltipArrow = styled(ReactLaagArrow)<ColorStylesProps>`
  path {
    stroke: none;
    fill: ${({ $isDark }) => backgroundColor($isDark)};
  }
`;

const HeadlineRow = styled(Stack).attrs({ alignItems: 'center', gap: '4px' })<ColorStylesProps>`
  ${colorStyles};
`;

const Icon = styled.i`
  font-size: 20px;
`;

const DismissButton = styled(PlainButton)`
  width: fit-content;
  padding: 0;
`;

const DismissText = styled(Text)<ColorStylesProps>`
  border-bottom: 1px solid ${({ $isDark }) => getPlainButtonTextColor(false, $isDark)};
  ${colorStyles};
`;

export const TooltipTrigger = styled.div`
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  text-decoration: inherit;
`;

const TooltipInner = ({
  children,
  content,
  isDark = false,
  isOpen,
  headline,
  icon,
  dismissText,
  tooltipPosition = 'below',
  arrowPosition = 'center',
  delayLeave = 200,
  delayEnter = 0,
  showArrow = true,
  triggerOffset = TOOLTIP_OFFSET,
  zIndexOverride,
}: Props): React.ReactElement => {
  const [isOverrideOpen, setIsOverrideOpen] = useState(!!dismissText);

  const placement: PlacementType = useMemo(() => {
    if (tooltipPosition === 'above') {
      switch (arrowPosition) {
        case 'left':
          return 'top-start';
        case 'center':
          return 'top-center';
        case 'right':
          return 'top-end';
      }
    } else if (tooltipPosition === 'below') {
      switch (arrowPosition) {
        case 'left':
          return 'bottom-start';
        case 'center':
          return 'bottom-center';
        case 'right':
          return 'bottom-end';
      }
    } else if (tooltipPosition === 'left') {
      return 'left-start';
    } else if (tooltipPosition === 'right') {
      return 'right-start';
    } else {
      throw new TypeError('Bad positioning for placement');
    }
  }, [arrowPosition, tooltipPosition]);

  const [isHovered, hoverProps, hoverOff] = useHover({ delayLeave, delayEnter });
  const showTooltip =
    Boolean(content) && (isOpen !== undefined ? isOpen : isHovered || isOverrideOpen);

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: showTooltip,
    arrowOffset: 10,
    triggerOffset,
    placement,
    onOutsideClick: () => setIsOverrideOpen(false),
  });

  const hide = (isOverrideOpen: boolean) => {
    hoverOff();
    setIsOverrideOpen(isOverrideOpen);
  };

  const bodyRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (showTooltip && bodyRef.current) {
      bodyRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [showTooltip]);

  return (
    <>
      <TooltipTrigger {...triggerProps} {...hoverProps}>
        {children}
      </TooltipTrigger>
      {showTooltip &&
        renderLayer(
          <TooltipContainer
            {...layerProps}
            {...hoverProps}
            $zIndex={zIndexOverride ?? zIndex('tooltip')}
          >
            <TooltipBody $isDark={isDark} $isLong={!!headline || !!dismissText} ref={bodyRef}>
              <Stack isVertical gap="4px">
                {headline && (
                  <HeadlineRow $isDark={isDark}>
                    {icon && <Icon className={icon} />}
                    <Text $variant="bodyRegularBold">{headline}</Text>
                  </HeadlineRow>
                )}
                <Text $variant="bodyRegular">{content}</Text>
              </Stack>
              {dismissText && (
                <DismissButton
                  id="tooltip-dismiss-button"
                  inverse={isDark}
                  onClick={() => hide(false)}
                >
                  <DismissText $isDark={isDark}>{dismissText}</DismissText>
                </DismissButton>
              )}
            </TooltipBody>
            {showArrow && <TooltipArrow $isDark={isDark} {...arrowProps} />}
          </TooltipContainer>,
        )}
    </>
  );
};

export default TooltipInner;
