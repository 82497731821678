import { useCallback, useState } from 'react';
import {
  DEFAULT_GLOBAL_MODAL_STATE,
  GlobalModalName,
  GlobalModalState,
  GlobalModalStateContext,
  GlobalModalUpdateContext,
} from './GlobalModalContext';

interface ProviderProps {
  /**
   * This will show children components within itself
   */
  children: React.ComponentProps<'div'>['children'];
}

/**
 * Provides a global modal state and update function to all children components
 */
const GlobalModalProvider = ({ children }: ProviderProps): React.ReactElement => {
  const [globalModalState, setGlobalModalState] = useState<GlobalModalState>(
    DEFAULT_GLOBAL_MODAL_STATE,
  );
  const globalModalUpdate = useCallback((name: GlobalModalName, isOpen: boolean) => {
    setGlobalModalState((prev) => ({ ...prev, [name]: isOpen }));
  }, []);

  return (
    <GlobalModalUpdateContext.Provider value={globalModalUpdate}>
      <GlobalModalStateContext.Provider value={globalModalState}>
        {children}
      </GlobalModalStateContext.Provider>
    </GlobalModalUpdateContext.Provider>
  );
};

export default GlobalModalProvider;
