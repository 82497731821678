import { EventNames, trackSegmentEvent } from 'lib/helpers/segment';
import theme from 'panama/styles/theme';
import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Stack from '../../layout/Stack';
import { textStyle } from '../../styles/typography';
import Spinner from '../Spinner';
import Text from '../Text';
import { VARIANT_STYLES as LINK_VARIANT_STYLES } from '../link/styles';
import type { ButtonVariant, IconPosition, IconVariant, SizeVariant } from './styles';
import {
  ICON_POSITIONS,
  ICON_VARIANT_TO_CLASS_NAME,
  PLAIN_ICONS,
  VARIANT_STYLES,
  buttonDisabledStyle,
  buttonPaddingStyle,
} from './styles';

type IconPlacement = 'together' | 'separate';

export type Props = Omit<
  React.HTMLProps<HTMLButtonElement>,
  'ref' | 'as' | 'type' | 'size' | 'disabled' | 'id'
> & {
  /**
   * The id of the button. We require this so that we can automatically log each button click
   */
  id: string;

  /**
   * Whether the button is disabled or not. When true, the button won't be interactable
   * and will visually appear disabled.
   */
  isDisabled?: boolean;

  /**
   * Whether the button is in a loading state or not. When true, a spinner will
   * be displayed to the left of the button content to indicate loading.
   */
  isLoading?: boolean;

  /**
   * Whether the button is "complete" from whatever it was loading from. Has the same
   * exact styles as "isLoading" except there is no loading icon.
   */
  isComplete?: boolean;

  /**
   * Overrides the controlled isLoading state handling; assumes the onClick function is
   * async and automatically sets an internal isLoading flag based on the onClick instead.
   */
  shouldHandleLoading?: boolean;

  /**
   * Different supported button types
   */
  type?: 'button' | 'submit' | 'reset';

  /**
   * The button's style variant. This modifies text color, background color, border, and
   * hover, loading, and disabled states. The default variant is `primary` (which uses brand colors)
   */
  variant?: ButtonVariant;

  /**
   * The button's size variant. This controls padding of the button. The default is 'large'
   */
  size?: SizeVariant;

  /**
   * An optional icon variant. This determines which icon to display horizontally in line with the
   * button content. The default is no icon.
   */
  iconVariant?: IconVariant;

  /**
   * This determines how the icon sits next to the text. Some icons
   * may appear on the left vs the right, but by default, all are
   * `together` next to the text. If you'd like to move the icon to
   * the right and the text to the left within the button, use
   * `separate` placement.
   */
  iconPlacement?: IconPlacement;

  /**
   * The (font) size of the icon
   */
  iconSize?: number;

  /**
   * Ignores the native position an icon has (taken from ICON_POSITIONS)
   * and forces it to a certain position.
   */
  forcedIconPosition?: IconPosition;

  /**
   * A ref for the button to use in getting width/etc
   */
  buttonRef?: Pick<React.ComponentPropsWithRef<'button'>, 'ref'>['ref'];

  /**
   * The color of the text
   */
  textColor?: string;
};

/**
 * Used in the styled component
 */
interface RootProps {
  $variant: ButtonVariant;
  $size: SizeVariant;
  $iconVariant?: IconVariant;
  $isDisabled: boolean;
  $isLoading: boolean;
  $textColor?: string;
  $hasNoChildren: boolean;
}

interface IconProps {
  size?: number;
}

// Make sure to reset font weight to what the icon library expects the weight to be
const Icon = styled.i<IconProps>`
  ${({ size }) =>
    size &&
    css`
      font-size: ${size}px;
    `}
  font-weight: 500;
  line-height: 1;
`;

export const ButtonText = styled(Text).attrs({ $variant: 'cta' })``;

interface ButtonContentProps {
  children: React.ReactNode;
  isLoading: boolean;
  iconSize?: number;
  iconVariant?: IconVariant;
  iconPlacement?: IconPlacement;
  forcedIconPosition?: IconPosition;
}

/**
 * Determines how to layout the Button's children and icon based on
 * loading state and iconVariant
 */
const ButtonContent = ({
  children,
  isLoading,
  iconSize,
  iconVariant,
  iconPlacement,
  forcedIconPosition,
}: ButtonContentProps) => {
  const passedChild = children ? <ButtonText>{children}</ButtonText> : null;

  if (isLoading || iconVariant) {
    let icon = null;
    if (isLoading && passedChild) {
      // The loading icon takes precedence over any other icon in the Button
      icon = <Spinner />;
    } else if (iconVariant) {
      icon = <Icon size={iconSize} className={ICON_VARIANT_TO_CLASS_NAME[iconVariant]} />;
    }

    const iconPosition = forcedIconPosition || (iconVariant && ICON_POSITIONS[iconVariant]);

    return (
      <Stack
        alignItems="center"
        justifyContent={iconPlacement === 'separate' ? 'space-between' : 'center'}
        gap="4px"
        isReversed={!isLoading && iconVariant && iconPosition === 'right'}
      >
        {icon}
        {passedChild}
      </Stack>
    );
  }

  return passedChild;
};

export const buttonStyles = ({
  $variant,
  $size,
  $iconVariant,
  $isLoading,
  $textColor,
  $isDisabled,
  $hasNoChildren,
}: RootProps) => css`
  ${textStyle('cta')};
  transition: all 0.1s ease;
  height: fit-content;
  white-space: nowrap;
  border: 1px solid ${VARIANT_STYLES[$variant].borderColor || 'transparent'};
  box-sizing: border-box;
  padding: ${buttonPaddingStyle($size, $variant, $iconVariant, $hasNoChildren)};
  color: ${$textColor ?? VARIANT_STYLES[$variant].textColor};
  background-color: ${VARIANT_STYLES[$variant].backgroundColor};
  ${$variant === 'link' &&
  css`
    text-decoration: ${LINK_VARIANT_STYLES.default.textDecorationLine};
    font-weight: inherit;

    @media (min-width: ${theme.breakpoints.tablet}) {
      font-weight: inherit;
    }

    @media (min-width: ${theme.breakpoints.desktop}) {
      font-weight: inherit;
    }
  `}
  ${$isLoading &&
  css`
    background-color: ${VARIANT_STYLES[$variant].hoverAndLoadingBackgroundColor};
  `}
  &:hover {
    cursor: pointer;
    background-color: ${VARIANT_STYLES[$variant].hoverAndLoadingBackgroundColor};
    border: 1px solid ${VARIANT_STYLES[$variant].hoverAndLoadingBorderColor || 'transparent'};
    ${$variant === 'link' &&
    css`
      color: ${LINK_VARIANT_STYLES.default.colorOnHover};
    `}
  }
  ${$isDisabled && buttonDisabledStyle($variant)}
`;

const Root = styled.button<RootProps>`
  ${(props) => buttonStyles(props)}

  ${ButtonText} {
    ${({ $variant }) =>
      $variant === 'link' &&
      css`
        font-weight: inherit;
      `}
  }
`;

/**
 * A Button component with standardized Panama styles. To be used in our apps
 * any time a button is needed.
 */
const Button = ({
  id,
  children,
  className,
  isDisabled = false,
  isLoading: isLoadingProp = false,
  isComplete = false,
  type = 'button',
  variant = 'primary',
  size = 'large',
  iconVariant,
  iconPlacement = 'together',
  iconSize,
  forcedIconPosition,
  buttonRef,
  textColor,
  shouldHandleLoading = false,
  onClick: onClickProp,
  ...props
}: Props): React.ReactElement => {
  // PLAIN_ICONS can only be used with the plain variant and without text.
  const buttonVariant = iconVariant && PLAIN_ICONS.includes(iconVariant) ? 'plain' : variant;
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const isLoading = shouldHandleLoading ? isLoadingInternal : isLoadingProp;

  const onClick = useMemo(() => {
    if (!shouldHandleLoading) {
      return onClickProp;
    }
    if (!onClickProp) {
      return undefined;
    }

    const asyncOnClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
      new Promise((resolve, reject) => {
        try {
          const result = onClickProp(e);
          resolve(result);
        } catch (e) {
          reject(e);
        }
      });

    return async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setIsLoadingInternal(true);
      try {
        await asyncOnClick(e);
      } finally {
        setIsLoadingInternal(false);
      }
    };
  }, [shouldHandleLoading, onClickProp]);

  return (
    <Root
      id={id}
      ref={buttonRef}
      className={className}
      $isLoading={isLoading || isComplete}
      $isDisabled={isDisabled}
      $iconVariant={iconVariant}
      $hasNoChildren={!children}
      $size={size}
      $variant={buttonVariant}
      $textColor={textColor}
      type={type}
      onClick={(e) => {
        trackSegmentEvent(EventNames.BUTTON_CLICKED, { button_id: id });
        onClick?.(e);
      }}
      {...props}
      disabled={isLoading || isDisabled || isComplete}
    >
      <ButtonContent
        isLoading={isLoading}
        iconSize={iconSize}
        iconVariant={iconVariant}
        iconPlacement={iconPlacement}
        forcedIconPosition={forcedIconPosition}
      >
        {children}
      </ButtonContent>
    </Root>
  );
};

export default Button;
