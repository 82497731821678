import Button from 'panama/components/buttons/Button';
import ErrorText from 'panama/components/fields/ErrorText';
import Modal from 'panama/components/modal/Modal';
import Text from 'panama/components/Text';
import { ZendeskAPI } from 'react-zendesk';

interface Props {
  fileName: string;
  errorMessage: string;
  isOpen: boolean;
  onClose: () => void;
  onTryAgain: () => void;
}

const ImportFailedModal = ({ fileName, errorMessage, isOpen, onClose, onTryAgain }: Props) => (
  <Modal
    title="Import failed"
    isOpen={isOpen}
    onClose={onClose}
    primaryAction={{
      id: 'import-failed-try-again-button',
      onClick: () => {
        onClose();
        onTryAgain();
      },
      variant: 'destructive',
      children: 'Try again',
    }}
    secondaryAction={{
      id: 'import-failed-cancel-button',
      onClick: onClose,
      variant: 'ghost',
      children: 'Cancel',
    }}
  >
    <Text $variant="bodyRegular">
      We couldn’t import {fileName}. Please check your file and make sure all your column headers
      match the template you’ve downloaded.{' '}
      <Button
        id="import-failed-contact-us-button"
        variant="link"
        onClick={() => {
          ZendeskAPI('messenger', 'open');
        }}
      >
        Contact us
      </Button>{' '}
      for more details.
    </Text>
    {errorMessage && (
      <ErrorText>
        Error Message:
        <br />
        {errorMessage}
      </ErrorText>
    )}
  </Modal>
);

export default ImportFailedModal;
