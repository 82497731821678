import { gql } from '@apollo/client';
import { GetPersistedJsonQuery } from 'gql/graphql';
import useLazyQueryWithErrorHandling from 'lib/hooks/graphql/queries/useLazyQueryWithErrorHandling';

const QUERY = gql`
  query GetPersistedJson {
    loggedInShopifyShop {
      id
      persistedJson
    }
  }
`;

/**
 * Gets `persistedJson` from logged in Shop. This corresponds to
 * the items stored in the shared cart.
 * This is a temporary replacement to the usage of local storage.
 */
const useGetPersistedJson = () => {
  const [getData, { data, isLoading, refetch }] =
    useLazyQueryWithErrorHandling<GetPersistedJsonQuery>(QUERY);

  return {
    getData,
    data,
    isLoading,
    refetch,
  };
};

export default useGetPersistedJson;
