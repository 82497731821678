import { useContext, useEffect } from 'react';
import { SessionContext } from './SessionProvider';

/**
 * Hook to fetch a cached Session object, which contains information about the
 * logged-in user, team, and their shop.
 */
export const useSession = () => {
  const sessionContext = useContext(SessionContext);
  if (sessionContext === undefined) {
    throw new Error('useSession must be used within a SessionProvider');
  }

  const { loadSession } = sessionContext;
  // Ensures we load at least once in the lifetime of the app
  useEffect(() => {
    (() => loadSession())();
  }, [loadSession]);

  return sessionContext;
};
