import { createContext, useContext } from 'react';

export const DEFAULT_GLOBAL_MODAL_STATE = {
  'bundles-not-ready': false,

  // SK related global modals
  'connect-webhook': false,
  'payment-information': false,
  'update-plan': false,
};

/**
 * List of allowed global modals within the app.
 */
export type GlobalModalName = keyof typeof DEFAULT_GLOBAL_MODAL_STATE;

/**
 * Stores global state for modals (e.g. whether a modal is open or not) for
 * modals that want to be able to be opened from anywhere in the app
 */
export type GlobalModalState = Record<GlobalModalName, boolean>;

/**
 * Updates the global modal state for modals that want to be able to be opened
 * from anywhere in the app
 */
type GlobalModalUpdate = (name: GlobalModalName, isOpen: boolean) => void;

export const GlobalModalStateContext = createContext<undefined | GlobalModalState>(undefined);
export const GlobalModalUpdateContext = createContext<undefined | GlobalModalUpdate>(undefined);

/**
 * Convenience hook to get the global modal state
 */
export const useGlobalModalState = (): GlobalModalState => {
  const context = useContext(GlobalModalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalModalState must be used within a GlobalModalStateContext.Provider');
  }
  return context;
};

/**
 * Convenience hook to get the global modal update function
 */
export const useGlobalModalUpdate = (): GlobalModalUpdate => {
  const context = useContext(GlobalModalUpdateContext);
  if (context === undefined) {
    throw new Error('useGlobalModalUpdate must be used within a GlobalModalUpdateContext.Provider');
  }
  return context;
};
