import type { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import useToast from '../interactions/useToast';

export type BackendErrorType = Error | ApolloError | GraphQLError | undefined;

// HACK ALERT: Backend is difficult and sends us malformed errors that we need
// to parse :(
const PYTHON_ERROR_INDICATOR = 'body="b\'{"errors":"';

/**
 * Type guard for ApolloErrors
 */
export const isApolloError = (error: BackendErrorType): error is ApolloError =>
  // This eslint rule is off since this is a type guard
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  !!(error as ApolloError)?.networkError || !!(error as ApolloError)?.graphQLErrors?.length;

/**
 * Type guard for ServerParseErrors
 */
export const isServerParseError = (error: BackendErrorType): boolean =>
  // This eslint rule is off since this is a type guard
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  isApolloError(error) && !!error?.networkError && error?.networkError?.name === 'ServerParseError';

export const clearServerParseError = (error: BackendErrorType, str: string): string => {
  let newString = str;
  if (isServerParseError(error)) {
    newString = '';
  }
  return newString;
};

export const formatBackendError = (error: BackendErrorType): string | null => {
  // Error could be hidden a few different places, or not present at all
  let errorMessage: string | undefined;
  if (isApolloError(error)) {
    errorMessage = error?.networkError?.message ?? error?.graphQLErrors?.[0]?.message;
    // If error is due to Apollo trying to parse an html
    // set errorMessage to an empty string to return a null value
    // in order to prevent toasts
    if (isServerParseError(error)) {
      errorMessage = '';
    }
  } else {
    errorMessage = error?.message;
  }

  if (!errorMessage) {
    return null;
  }

  // Either parse out the actual error from backend's python string, or just
  // show the raw error if it's a basic string
  const parsedError = errorMessage.includes(PYTHON_ERROR_INDICATOR)
    ? errorMessage.split(PYTHON_ERROR_INDICATOR)?.[1]?.split('"}\'",')?.[0]
    : errorMessage;
  return parsedError.toLowerCase().includes('error') ? parsedError : `Error: ${parsedError}`;
};

/**
 * Convenience function for handling errors from BE mutations
 */
export const getMutationErrorMessage = (e: unknown, defaultMessage: string): string => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  let formatted = formatBackendError(e as BackendErrorType) ?? defaultMessage;
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  formatted = clearServerParseError(e as BackendErrorType, formatted);
  return formatted;
};

/**
 * Shows a toast for a network error from Apollo after parsing out what the
 * message itself should be
 */
const useBackendErrorToast = (): ((error: BackendErrorType) => void) => {
  const showToast = useToast();
  const backendErrorToast = useCallback(
    (error: BackendErrorType) => {
      const formattedError = formatBackendError(error);
      if (formattedError) {
        showToast(formattedError);
      }
    },
    [showToast],
  );
  return backendErrorToast;
};

export default useBackendErrorToast;
