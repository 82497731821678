import {
  SharedCartContext,
  UpdateCartProductsProps,
} from 'components/shopkeepapp/cart/SharedCartContext';
import putPersistedJsonForShop from 'components/shopkeepapp/cart/putPersistedJsonForShop';
import useGetPersistedJson from 'components/shopkeepapp/cart/useGetPersistedJson';
import { SkDiscoverableProductFragment } from 'gql/graphql';
import { ObjectTyped } from 'object-typed';
import { useCallback, useEffect, useMemo, useState } from 'react';
import updatePersistedJsonForShop from './updatePersistedJsonForShop';

const TIMEOUT_LENGTH = 5000;

interface Props {
  children: React.ComponentProps<'div'>['children'];
}

/**
 * Model of the object that is stored on the `persistedJson` field.
 */
export type AllDraftProposals = Record<string, string[]>;

/**
 * Unique key to mark bulk add selection for proposals
 */
export const getBulkAddKey = (shopId: string) => `bulkAdd-${shopId}`;

/**
 * Converts list of products into appropriate object input for the bulk add cart function used for adding multiple products from multiple shops to the shared cart
 * object format: { [shopId]: [productId[]], .... } or { shopId: productIdList, ....}
 * @param products List of products to be added to cart
 * @returns An object of the format - { shopId: productIdList, ....}
 */
export const createBulkAddCartProductsInput = (products: SkDiscoverableProductFragment[]) =>
  products.reduce((cartItems: { [key: string]: string[] }, product) => {
    const shopId = product.shop.id;
    if (Object.keys(cartItems).includes(shopId)) {
      Object.assign(cartItems, { ...cartItems, [shopId]: [...cartItems[shopId], product.id] });
    } else {
      Object.assign(cartItems, { ...cartItems, [shopId]: [product.id] });
    }
    return cartItems;
  }, {});

/**
 * Provider that enables multiple places throughout the app to display
 * information regarding draft proposals AND act upon it (i.e. removing items).
 */
const SharedCartProvider = ({ children }: Props): React.ReactElement => {
  const { getData, data: persistedJsonData, refetch } = useGetPersistedJson();
  const [proposals, setProposals] = useState<AllDraftProposals>();
  const [isMiniCartOpen, setIsMiniCartOpen] = useState<boolean>(false);
  const [shopIds, setShopIds] = useState<string[]>([]);
  const [productIds, setProductIds] = useState<string[]>([]);
  const [totalCountOfDraftProposals, setTotalCountOfDraftProposals] = useState<number>(0);
  const [lastRemovedProductId, setLastRemovedProductId] = useState<string | null>(null);
  let timeout: NodeJS.Timeout | null = null;

  /**
   * Grabs the product IDs that are apart of a shop.
   * @param shopId ID used to grab product IDs
   * @returns key used to grab shop data + array of product IDs
   */
  const getProductIdsFromShopId = useCallback(
    (shopId: string) => {
      let retrievedProductIds = null;
      if (proposals) retrievedProductIds = proposals[shopId];
      return retrievedProductIds;
    },
    [proposals],
  );

  /**
   * Update product(s) to cart, with a separate flow for bulk add. Refetches the persisted json on a timeout, setting a 5 second countdown before refetching.
   * This is order to prevent the previous problem of refetching immediately causing the returning query to overwrite the current state if the user is clicking to add/remove products quickly.
   * Acts immediately if bulk add is being added or removed to prevent state issues, or if shouldRefetchImmediately is true. Returns null if the key doesn't exist.
   * @param shopId ID of the shop that the product belongs to
   * @param productIdsToRemove ID(s) of products to add
   * @param productIdsToAdd ID(s) of products to add
   * @param isBulkAdd Whether bulk add is being added or not
   * @param shouldRefetchImmediately If the persisted json should be refetched immediately
   */
  const updateCartProducts = useCallback(
    async ({
      shopId,
      productIdsToRemove,
      productIdsToAdd,
      isBulkAdd,
      shouldRefetchImmediately,
    }: UpdateCartProductsProps): Promise<void | null> => {
      const retrievedProductIds = getProductIdsFromShopId(shopId);
      const shopBulkAddKey = getBulkAddKey(shopId);
      const alreadyHasBulkAddKey = retrievedProductIds?.includes(shopBulkAddKey) ?? false;
      const shouldRemoveBulkAddKey =
        alreadyHasBulkAddKey &&
        !isBulkAdd &&
        (((productIdsToAdd && !productIdsToAdd.includes(shopBulkAddKey)) ||
          (productIdsToRemove && productIdsToRemove.includes(shopBulkAddKey))) ??
          true);

      const curProductIdsToKeep = retrievedProductIds
        ? retrievedProductIds.filter((productId) => !productIdsToRemove?.includes(productId))
        : null;

      let productIdsToKeep: string[] = [];

      if (curProductIdsToKeep && productIdsToAdd) {
        productIdsToKeep = [...curProductIdsToKeep, ...productIdsToAdd];
      } else if (curProductIdsToKeep) {
        productIdsToKeep = curProductIdsToKeep;
      } else if (productIdsToAdd) {
        productIdsToKeep = productIdsToAdd;
      }

      let newObject;
      if (productIdsToKeep && productIdsToKeep.length > 0) {
        newObject = { [shopId]: productIdsToKeep };
      }

      const productIdsToRemoveWithBulkAdd = productIdsToRemove
        ? [...productIdsToRemove, shopBulkAddKey]
        : [shopBulkAddKey];

      await updatePersistedJsonForShop(
        shouldRemoveBulkAddKey ? productIdsToRemoveWithBulkAdd : productIdsToRemove ?? [],
        isBulkAdd ? [shopBulkAddKey] : productIdsToAdd ?? [],
        shopId,
      ).then(() => {
        if (timeout !== null) {
          clearTimeout(timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timeout = setTimeout(
          () => {
            refetch();
          },
          isBulkAdd || shouldRemoveBulkAddKey || shouldRefetchImmediately ? 0 : TIMEOUT_LENGTH,
        );
      });

      if (productIdsToRemove?.length === 1) {
        setLastRemovedProductId(productIdsToRemove[0]);
      }

      setProductIds((currentProductIds) =>
        productIdsToAdd
          ? [
              ...currentProductIds.filter((productId) => !productIdsToRemove?.includes(productId)),
              ...productIdsToAdd,
            ]
          : currentProductIds.filter((productId) => !productIdsToRemove?.includes(productId)),
      );

      // update shop IDs if there are no more products for a shop
      if (!newObject) {
        setShopIds((currentShopIds) =>
          currentShopIds.filter((currentShopId) => currentShopId !== shopId),
        );
      }
    },
    [getProductIdsFromShopId, proposals, refetch],
  );

  /**
   * Bulk adds product(s) to cart and optimistically updates the product IDs. Returns null if the
   * key doesn't exist.
   * @param shopToProductIds Object in the following format [ shopId: [productIds,...], ...]
   */
  const bulkAddCartProducts = useCallback(
    async (shopToProductIds: Record<string, string[]>): Promise<void | null> => {
      const allNewProductsObject: Record<string, string[]> = {};
      Object.keys(shopToProductIds).map((shopId) => {
        const productIds = shopToProductIds[shopId];
        const retrievedProductIds = getProductIdsFromShopId(shopId);

        let parsedProductIds = [...(retrievedProductIds || []), ...productIds];
        if (!retrievedProductIds) parsedProductIds = [...productIds];

        allNewProductsObject[shopId] = parsedProductIds;
        return null;
      });
      let stringifiedJson = '';
      if (proposals) {
        const combinedObject = { ...proposals, ...allNewProductsObject };
        stringifiedJson = JSON.stringify(combinedObject);
      } else {
        stringifiedJson = JSON.stringify(allNewProductsObject);
      }

      await putPersistedJsonForShop(stringifiedJson);
      refetch();

      setProductIds((currentProductIds) => [...currentProductIds, ...productIds]);
    },
    [getProductIdsFromShopId, productIds, proposals, refetch],
  );

  /**
   * Determines if a product is in a cart using it's product ID and the ID of the shop
   * it belongs to.
   * @param shopId ID of the shop that the product belongs to
   * @param productId ID of the product to check if it's in the cart
   */
  const isProductInCart = useCallback(
    (shopId: string, productId: string): boolean => {
      if (
        proposals &&
        proposals[shopId]?.length === 1 &&
        proposals[shopId][0] === getBulkAddKey(shopId)
      ) {
        return true;
      }
      const retrievedProductIds = getProductIdsFromShopId(shopId);
      if (!retrievedProductIds) return false;
      if (retrievedProductIds?.includes(productId)) return true;
      return false;
    },
    [getProductIdsFromShopId, proposals],
  );

  /**
   * Determines if bulk add flag is in the cart using the ID of the shop
   * it belongs to.
   * @param shopId ID of the shop that the bulk add flag belongs to
   */
  const isBulkAddInCart = useCallback(
    (shopId: string): boolean => {
      if (
        proposals &&
        proposals[shopId]?.length === 1 &&
        proposals[shopId][0] === getBulkAddKey(shopId)
      ) {
        return true;
      }
      return false;
    },
    [proposals],
  );

  const refetchCart = useCallback(() => {
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout]);

  // Runs on first load to grab the `persistedJson` field
  useEffect(() => {
    getData();
    // Disabled so we don't have to put run into any unnecessary
    // re-renders by putting `getData` in there.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Updates proposals every time we refetch the `persistedJson`
  useEffect(() => {
    const persistedJson = persistedJsonData?.loggedInShopifyShop?.persistedJson;
    if (persistedJson) {
      setProposals(JSON.parse(persistedJson));
    }
  }, [persistedJsonData]);

  // Depending on if there are draft proposals, set the shop ID and product ID states
  useEffect(() => {
    if (proposals && ObjectTyped.keys(proposals).length > 0) {
      const newShopIds = ObjectTyped.keys(proposals);
      setShopIds([...newShopIds]);
      setProductIds([...ObjectTyped.entries(proposals).flatMap(([, value]) => value)]);
      return;
    }
    setShopIds([]);
    setProductIds([]);
  }, [proposals]);

  // If there are shop IDs, get the total count of draft proposals from it
  useEffect(() => {
    setTotalCountOfDraftProposals(shopIds.length);
  }, [shopIds]);

  const memoizedShopIds = useMemo(() => shopIds, [shopIds]);
  const memoizedProductIds = useMemo(() => productIds, [productIds]);
  const memoizedTotalCountOfDraftProposals = useMemo(
    () => totalCountOfDraftProposals,
    [totalCountOfDraftProposals],
  );

  const value = useMemo(
    () => ({
      isMiniCartOpen,
      setIsMiniCartOpen,
      shopIds: memoizedShopIds,
      productIds: memoizedProductIds,
      totalCountOfDraftProposals: memoizedTotalCountOfDraftProposals,
      updateCartProducts,
      bulkAddCartProducts,
      isProductInCart,
      isBulkAddInCart,
      refetchCart,
      draftProposalDateCreated: null, // TODO: figure out a replacement
      lastRemovedProductId,
      setLastRemovedProductId,
      getProductIdsFromShopId,
    }),
    [
      isMiniCartOpen,
      memoizedShopIds,
      memoizedProductIds,
      memoizedTotalCountOfDraftProposals,
      updateCartProducts,
      bulkAddCartProducts,
      refetchCart,
      isProductInCart,
      isBulkAddInCart,
      getProductIdsFromShopId,
      lastRemovedProductId,
    ],
  );

  return <SharedCartContext.Provider value={value}>{children}</SharedCartContext.Provider>;
};

export default SharedCartProvider;
