/**
 * This file defines Canal's styles for use WITHIN the Shopify apps.
 *
 * For styles used OUTSIDE the Shopify apps, see: panama/styles/theme.ts
 */

const shopifyAppTheme = {
  color: {
    // Grey Scale
    black: '#252323',
    darkGray: '#4F4F4F',
    cement: '#888888',
    subdued: '#C9CCCF',
    silver: '#DEDEDE',
    pressed: '#F1F2F3',
    ash: '#F2F2F2',
    hover: '#F6F6F7',
    white: '#FFFFFF',
    // Brand colors
    brandRegular: '#0ABAB5',
    brandDark: '#098E8A',
    cashGreen: '#85C694',
    // System colors
    red: '#DE3534',
    blue: '#2C6ECB',
    // Polaris colors
    interactive: '#2C6ECB',
    polarisSubdued: '#6D7175',
  },
  spacing: {
    xxsmall: '2px',
    xsmall: '4px',
    small: '8px',
    medium: '12px',
    default: '16px',
    large: '24px',
    xlarge: '32px',
    xxlarge: '48px',
    xxxlarge: '64px',
  },
  weight: {
    regular: '400',
    strong: '500',
    stronger: '600',
    bold: '700',
    bolder: '800',
    boldest: '900',
  },
  radius: {
    xxsmall: '2px',
    xsmall: '3px',
    small: '4px',
    medium: '8px',
    large: '12px',
    xlarge: '16px',
    round: '100%',
  },
  breakpoints: {
    xs: '576px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
    xl: '1360px',
  },
  fontSize: {
    xsmall: '0.8em',
    small: '1em',
    medium: '1.2em',
    large: '1.4em',
  },
  zIndex: {
    // Shopify's highest z-index for inputs appears to be 29 - stack it above
    above: 30,
    // Used to display a toggle button that floats above all else
    top: 999,
  },
};

export default shopifyAppTheme;
