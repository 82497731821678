import { createContext } from 'react';

export type ExportOptions =
  | {
      type: 'all';
    }
  | {
      type: 'selected';
      orderIds: string[];
    }
  | {
      type: 'search';
      searchQuery: string;
    }
  | {
      type: 'unfulfilled';
    };

export interface ExportOrders {
  /**
   * Export order line items function
   */
  exportLineItems: (
    /**
     * Number of orders being exported (used to display a loading indicator)
     */
    count: number,

    /**
     * Export options (all, selected, search)
     */
    options: ExportOptions,
  ) => Promise<void>;

  /**
   * Whether or not the export is currently in progress
   */
  isExporting: boolean;
}

const ExportOrdersContext = createContext<ExportOrders | undefined>(undefined);

export default ExportOrdersContext;
