import { AppProvider, Frame as PolarisFrame } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import translations from '@shopify/polaris/locales/en.json';
import React from 'react';
import 'remixicon/fonts/remixicon.css';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'styles/GlobalStyle';
import shopifyAppTheme from 'styles/shopifyAppTheme';

/**
 * Global style component, used to inject styling around a component.
 * Should be used exactly once in _app.tsx, and also in Storybook.
 */
const AppStyleProvider = ({
  children,
}: Pick<React.ComponentProps<'div'>, 'children'>): React.ReactElement => (
  <ThemeProvider theme={shopifyAppTheme}>
    <AppProvider i18n={translations}>
      <GlobalStyle />
      <PolarisFrame>{children}</PolarisFrame>
    </AppProvider>
  </ThemeProvider>
);

export default AppStyleProvider;
