import React from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme';
import Button, { Props as BaseButtonProps } from './Button';
import { IconVariant } from './styles';

export type Props = Omit<BaseButtonProps, 'variant' | 'isLoading' | 'size'> & {
  /**
   * When true, renders the button with lighter text and darker hover backgrounds. Useful when
   * the button is used on a darker background (like in the Toast component)
   */
  inverse?: boolean;
};

export const getPlainButtonTextColor = (
  isDisabled: boolean,
  inverse: boolean,
  iconVariant?: IconVariant,
): string => {
  if (isDisabled) return theme.color.gray[400];

  if (inverse) {
    if (iconVariant) return theme.color.white[800];
    return theme.color.primary[400];
  }

  if (iconVariant) return theme.color.black[800];
  return theme.color.primary[700];
};

const getPlainButtonBackgroundColor = (isDisabled: boolean, inverse: boolean): string => {
  if (isDisabled) return 'transparent';

  return inverse ? theme.color.gray[700] : theme.color.gray[200];
};

const StyledButton = styled(Button)<{
  isDisabled: boolean;
  $inverse: boolean;
  iconVariant?: IconVariant;
}>`
  color: ${({ isDisabled, $inverse, iconVariant }) =>
    getPlainButtonTextColor(isDisabled, $inverse, iconVariant)};

  &:hover {
    background-color: ${({ isDisabled, $inverse }) =>
      getPlainButtonBackgroundColor(isDisabled, $inverse)};
  }
`;

/**
 * A wrapper component around our Button component for simple buttons with transparent buttons. These are
 * useful for subtle actions that resemble links.
 */
const PlainButton = ({
  isDisabled = false,
  inverse = false,
  iconVariant,
  ...rest
}: Props): React.ReactElement => (
  <StyledButton
    {...rest}
    isDisabled={isDisabled}
    $inverse={inverse}
    iconVariant={iconVariant}
    variant="plain"
  />
);

export default PlainButton;
