import Button from 'panama/components/buttons/Button';
import CancelButton from 'panama/components/buttons/CancelButton';
import { isButtonProps, type ModalProps } from 'panama/components/modal/types';
import Text from 'panama/components/Text';
import Overlay from 'panama/layout/Overlay';
import Stack from 'panama/layout/Stack';
import theme from 'panama/styles/theme';
import styled, { css } from 'styled-components';

export type Props = ModalProps;

interface ModalContentProps {
  $maxContentWidth: number;
  $minContentWidth: number;
  $maxContentHeight?: number;
  $minContentHeight?: number;
  $shouldOverflow: boolean;
  $shouldGrow: boolean;
}

const ModalContent = styled(Stack).attrs({ isVertical: true })<ModalContentProps>`
  background-color: ${theme.color.white[900]};

  ${({ $shouldGrow }) =>
    $shouldGrow &&
    css`
      width: 100%;
      height: 100%;
    `}

  @media (min-width: ${theme.breakpoints.tablet}) {
    ${({ $shouldOverflow }) =>
      !$shouldOverflow &&
      css`
        max-height: 80vh;
      `};
    max-width: ${({ $maxContentWidth }) => `${$maxContentWidth}px`};
    min-width: ${({ $minContentWidth }) => `${$minContentWidth}px`};
  }

  @media (max-width: ${theme.breakpoints.tablet}) {
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
  }
  ${({ $minContentHeight }) =>
    $minContentHeight &&
    css`
      min-height: ${$minContentHeight}px;
    `};
  ${({ $maxContentHeight }) =>
    $maxContentHeight &&
    css`
      max-height: ${$maxContentHeight}px;
    `};
`;

interface ModalTitleProps {
  $noTitleBottomBorder?: boolean;
}

const ModalTitle = styled.div<ModalTitleProps>`
  padding: 16px 16px 16px 24px;
  border-bottom: ${(props) =>
    props.$noTitleBottomBorder ? 'none' : `1px solid ${theme.color.gray[300]}`};
`;

interface OverflowProps {
  $shouldOverflow: boolean;
}

const ScrollingStack = styled(Stack)<OverflowProps>`
  overflow: ${({ $shouldOverflow }) => ($shouldOverflow ? 'visible' : 'auto')};
  flex: 1;
`;

const GrayPanel = styled.div<{ smallSideBox?: boolean }>`
  padding: 24px;
  background-color: ${theme.color.white[800]};
  overflow: auto;
  flex: 0.75;
  ${({ smallSideBox }) =>
    smallSideBox &&
    css`
      flex: 0.5;
    `};
  border-bottom: 1px solid ${theme.color.gray[300]};
`;

const ModalBody = styled(ModalTitle)<OverflowProps & { $hasTitle: boolean; $padding?: string }>`
  overflow: ${({ $shouldOverflow }) => ($shouldOverflow ? 'visible' : 'auto')};
  flex: 1;
  padding: 24px;

  ${({ $padding }) =>
    $padding &&
    css`
      padding: ${$padding};
    `};

  ${({ $padding, $hasTitle }) =>
    $hasTitle &&
    !$padding &&
    css`
      padding-top: 0;
      padding-right: 0;
    `};
`;

const ModalFooter = styled(Stack).attrs({
  isReversed: true,
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  padding: 16px 24px;
`;

const ChildrenContainer = styled.div<{ $hasTitle: boolean }>`
  flex: 1;
  ${({ $hasTitle }) =>
    $hasTitle &&
    css`
      padding-right: 24px;
    `};
`;

const CloseButton = styled.div<{ $hasTitle: boolean }>`
  align-self: flex-end;
  ${({ $hasTitle }) =>
    $hasTitle &&
    css`
      padding: 10px;
    `};
`;

const StyledCancelButton = styled(CancelButton)`
  font-size: 24px;
`;

const LeftButton = styled(Button)`
  color: ${theme.color.black[600]};
  margin-left: -8px;

  &:hover {
    background-color: ${theme.color.gray[200]};
  }
`;

/**
 * A Modal component for rendering content within an overlay in the center of the page.
 * While the Modal is open, the rest of the page is un-clickable and covered with a gray
 * overlay to convey that. The Modal can be closed by clicking the "X" icon or pressing
 * the 'Escape' key.
 */
const Modal = ({
  className,
  children,
  isOpen,
  onClose,
  id,
  scrollableBodyClassName,
  primaryAction,
  primaryActionId,
  secondaryAction,
  secondaryActionId,
  leftAction,
  title,
  informationText = <div />,
  maxContentWidth = 612,
  minContentWidth = 612,
  maxContentHeight,
  minContentHeight,
  customBodyPadding,
  sidePanelBody,
  sidePanelPosition = 'left',
  smallSidePanel = false,
  shouldGrow = false,
  shouldOverflow = false,
  noCloseButton = false,
  noTitleBottomBorder = false,
}: Props): React.ReactElement | null => (
  <Overlay
    isOpen={isOpen}
    onClose={onClose}
    contentPosition="center"
    fadeDirection="bottom"
    fadeDuration={200}
    id={id}
  >
    <ModalContent
      className={className}
      $maxContentWidth={maxContentWidth}
      $minContentWidth={minContentWidth}
      $maxContentHeight={maxContentHeight}
      $minContentHeight={minContentHeight}
      $shouldOverflow={shouldOverflow}
      $shouldGrow={shouldGrow}
    >
      {title && (
        <ModalTitle $noTitleBottomBorder={noTitleBottomBorder}>
          <Stack justifyContent="space-between" alignItems="center" gap="16px">
            <Text $variant="h6">{title}</Text>
            {!noCloseButton && <StyledCancelButton id="panama-modal-x-button" onClick={onClose} />}
          </Stack>
        </ModalTitle>
      )}
      <ScrollingStack isVertical $shouldOverflow={shouldOverflow}>
        <ScrollingStack isReversed={sidePanelPosition === 'left'} $shouldOverflow={shouldOverflow}>
          <ModalBody
            $hasTitle={!title}
            $padding={customBodyPadding}
            $shouldOverflow={shouldOverflow}
          >
            <Stack
              className={scrollableBodyClassName}
              justifyContent="space-between"
              isVertical={!title}
              isReversed={!title}
              gap={title && '16px'}
            >
              <ChildrenContainer $hasTitle={!title}>{children}</ChildrenContainer>
              {!title && !noCloseButton && (
                <CloseButton $hasTitle={!title}>
                  <StyledCancelButton id="panama-modal-x-button" onClick={onClose} />
                </CloseButton>
              )}
            </Stack>
          </ModalBody>
          {sidePanelBody && <GrayPanel smallSideBox={smallSidePanel}>{sidePanelBody}</GrayPanel>}
        </ScrollingStack>
        {primaryAction && (
          <ModalFooter>
            <Stack
              alignItems="center"
              justifyContent={leftAction ? 'space-between' : 'flex-end'}
              style={leftAction ? { width: '100%' } : {}}
            >
              {leftAction && <LeftButton {...leftAction} variant="plain" />}
              <Stack alignItems="center" gap="8px" justifyContent="flex-end">
                {secondaryAction && (
                  <Button
                    {...secondaryAction}
                    id={secondaryActionId || 'modal-secondary-action-button'}
                  />
                )}
                {isButtonProps(primaryAction) ? (
                  <Button
                    {...primaryAction}
                    id={primaryActionId || 'modal-primary-action-button'}
                  />
                ) : (
                  primaryAction
                )}
              </Stack>
            </Stack>
            {informationText}
          </ModalFooter>
        )}
      </ScrollingStack>
    </ModalContent>
  </Overlay>
);

export default Modal;
