import Toast from 'panama/components/Toast';
import { useCallback, useState } from 'react';
import ToastTogglerContext, { ToastToggler } from './ToastTogglerContext';

const MAX_LENGTH = 200;

interface ProviderProps {
  /**
   * This will show children components within itself
   */
  children: React.ComponentProps<'div'>['children'];
}

/**
 * Renders an auto-dismissing toast provider - dismisses the toast
 * after a pre-configured timeout
 * @returns
 */
const ToastProvider = ({ children }: ProviderProps): React.ReactElement => {
  const [message, setMessage] = useState('');
  const [duration, setDuration] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [actionText, setActionText] = useState<string | undefined>(undefined);
  const [onAction, setOnAction] = useState<(() => void) | undefined>(undefined);

  const showToast = useCallback<ToastToggler>((message, options) => {
    const truncatedMessage =
      message.length > MAX_LENGTH ? `${message.substring(0, MAX_LENGTH)}...` : message;
    setMessage(truncatedMessage);
    setIsLoading(options?.isLoading ?? false);
    setDuration(options?.duration);
    setActionText(options?.actionText);
    setOnAction(options && options?.onAction !== undefined ? () => options?.onAction : undefined);
  }, []);

  return (
    <ToastTogglerContext.Provider value={showToast}>
      {children}
      <Toast
        message={message}
        setMessage={setMessage}
        isLoading={isLoading}
        duration={duration}
        actionText={onAction ? actionText : undefined}
        onAction={onAction ? () => onAction() : undefined}
      />
    </ToastTogglerContext.Provider>
  );
};

export default ToastProvider;
