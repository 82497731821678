import React from 'react';
import styled from 'styled-components';
import PlainButton, { Props as ButtonProps } from './PlainButton';

export type Props = Omit<ButtonProps, 'iconVariant'>;

const StyledButton = styled(PlainButton)`
  display: flex;
  line-height: 100%;
  font-weight: 400;
  font-size: 24px;
`;

/**
 * A simple button that renders an "X" icon for closing/canceling actions
 */
const CancelButton = (props: Props): React.ReactElement => (
  <StyledButton {...props} iconVariant="cancel" />
);

export default CancelButton;
