import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

/**
 * Renders children as a child to "document.body".
 *
 * @ref https://reactjs.org/docs/portals.html
 * @ref https://github.com/vercel/next.js/blob/canary/examples/with-portals/components/ClientOnlyPortal.js
 */
const Portal = ({
  children,
}: Pick<React.ComponentProps<'div'>, 'children'>): React.ReactElement | null => {
  const ref = useRef<HTMLElement | undefined>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.body;
    setMounted(true);
  }, []);

  return mounted && ref.current ? createPortal(children, ref.current) : null;
};

export default Portal;
