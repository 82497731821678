import { Provider } from '@shopify/app-bridge-react';
import RoutePropagator from 'components/shared/ShopifyRoutePropagator';
import APP_NAME_URL_PARAM_TO_PK from 'lib/constants/apikeys';
import { isShopifyEmbeddedApp } from 'lib/helpers/isEmbeddedApp';
import { getItemFromLocalStorage, setItemToLocalStorage } from 'lib/helpers/localStorage';

/**
 * The query parameter set in our Shopify App Urls that let us identify which
 * keys to use for API authentication and OAuth flow.
 */
export const APP_NAME_PARAM = 'canal_app_name' as const;
/**
 * We save and load the host from local storage - this is the key to the value
 */
const HOST_LOCAL_STORAGE_KEY = 'savedHost';
const APP_NAME_LOCALSTORAGE_KEY = 'savedCanalAppNameParam';

/**
 * Pulls out the value of a given parameter from a url, if it exists
 * @param name the parameter name
 * @returns the string value of the parameter if it exists
 */
const getParameterByName = (name: string) => {
  if (typeof window === 'undefined') {
    return null;
  }
  const regex = new RegExp(`[?&]${name.replace(/[[\]]/g, '\\$&')}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(window.location.href);
  if (!results || !results[2]) return null;
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

/**
 * Pulls an item out of local storage and tries to parse to a
 * string. Only works well if the item was a string when it was
 * saved to begin with
 */
const grabLocalStorageItem = (key: string): string | null => {
  const savedItem = getItemFromLocalStorage(key);
  if (savedItem && savedItem.length > 0) {
    try {
      return JSON.parse<string>(savedItem);
    } catch {
      return null;
    }
  }
  return null;
};

/**
 * Fetch host and api key information required for authenticating API calls when
 * running the app embedded into Shopify.
 * @returns Either an object with host and apiKey strings, or null, when those
 * can't be found.
 */
export const getShopifyCredentials = (): { host: string; apiKey: string } | null => {
  // Grabs the host param from Shopify embedded app or from local storage
  if (!isShopifyEmbeddedApp) {
    return null;
  }

  const host = getParameterByName('host') ?? grabLocalStorageItem(HOST_LOCAL_STORAGE_KEY);
  const canalAppNameParam = getParameterByName(APP_NAME_PARAM);
  if (canalAppNameParam) {
    setItemToLocalStorage(APP_NAME_LOCALSTORAGE_KEY, JSON.stringify(canalAppNameParam));
  }

  const canalAppName = canalAppNameParam ?? grabLocalStorageItem(APP_NAME_LOCALSTORAGE_KEY);
  const apiKey = APP_NAME_URL_PARAM_TO_PK.get(canalAppName || 'storefront');

  if (!host || !apiKey) {
    return null;
  }

  // Set it for next time if we have a host, and setup fetch to use the
  // authenticated fetch with a Shopify app
  setItemToLocalStorage(HOST_LOCAL_STORAGE_KEY, JSON.stringify(host));

  return {
    host,
    apiKey,
  };
};

interface Props {
  children: React.ReactNode;
}

/**
 * Wrap the app around a Shopify App Bridge Provider, which among other things
 * allows us to sync the URL of the embedded app inside of Shopify with that of
 * the parent frame.
 */
const ShopifyAppBridgeProvider = ({ children }: Props) => {
  const creds = getShopifyCredentials();
  if (!creds) {
    // When no Shopify credentials are found, assume we are not the embedded app
    // and don't render the RoutePropagator.
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <Provider
      config={{
        apiKey: creds.apiKey,
        host: creds.host,
        forceRedirect: true,
      }}
    >
      <RoutePropagator />
      {children}
    </Provider>
  );
};

export default ShopifyAppBridgeProvider;
