import { createContext } from 'react';

export type ExportOptions =
  | {
      type: 'all';
    }
  | {
      type: 'selected';
      productIds: string[];
    }
  | {
      type: 'search';
      searchQuery: string;
    };

export interface ExportProducts {
  /**
   * Export products function
   */
  exportProducts: (
    /**
     * Number of products being exported (used to display a loading indicator)
     */
    count: number,

    /**
     * Export options (all, selected, search)
     */
    options: ExportOptions,
  ) => Promise<void>;

  /**
   * Whether or not the export is currently in progress
   */
  isExporting: boolean;
}

const ExportProductsContext = createContext<ExportProducts | undefined>(undefined);

export default ExportProductsContext;
