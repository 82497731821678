/**
 * This file defines Canal's responsive typography system for use OUTSIDE of the Shopify apps.
 *
 * To use these styles, import the textStyle method from this file
 * and pass in one of the defined TextVariants
 *
 * For typography used WITHIN the Shopify apps, see: styles/shopifyAppTypography.ts
 */
import { css } from 'styled-components';
import theme from './theme';

// Defines font sizes, weights, and line heights to be used within this file
const typography = {
  // Font size defined as pixels
  size: {
    96: '96px',
    64: '64px',
    50: '50px',
    37: '37px',
    29: '29px',
    24: '24px',
    21: '21px',
    19: '19px',
    18: '18px',
    17: '17px',
    16: '16px',
    14: '14px',
    12: '12px',
  },

  // Font weights defined as relative weights
  weight: {
    700: '700',
    600: '600',
    500: '500',
    400: '400',
  },

  // Line heights defined as percentages of the text's font size
  lineHeight: {
    150: '150%',
    140: '140%',
    138: '138%',
    135: '135%',
    120: '120%',
    119: '119%',
    104: '104%',
    100: '100%',
  },
};

// All text variants supported by the Canal Design System
export type TextVariant =
  | 'displayMedium'
  | 'displaySmall'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'bodyRegular'
  | 'bodyRegularBold'
  | 'bodyRegularBrand'
  | 'bodyLarge'
  | 'bodyLargeBold'
  | 'cta'
  | 'caption'
  | 'captionBold'
  | 'footnote'
  | 'footnoteBold'
  | 'overline'
  | 'overlineBlack'
  | 'overlineWhite';

export const DEFAULT_TEXT_VARIANT: TextVariant = 'bodyRegular';

// Map of all the text variants
export const textVariants: { [key in TextVariant]: TextVariant } = {
  displayMedium: 'displayMedium',
  displaySmall: 'displaySmall',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  bodyRegular: 'bodyRegular',
  bodyRegularBold: 'bodyRegularBold',
  bodyRegularBrand: 'bodyRegularBrand',
  bodyLarge: 'bodyLarge',
  bodyLargeBold: 'bodyLargeBold',
  cta: 'cta',
  caption: 'caption',
  captionBold: 'captionBold',
  footnote: 'footnote',
  footnoteBold: 'footnoteBold',
  overline: 'overline',
  overlineBlack: 'overlineBlack',
  overlineWhite: 'overlineWhite',
};

// The CSS attributes defined in each font style
type FontStyle = {
  'font-size': string;
  'font-weight': string;
  'line-height': string;
  'letter-spacing'?: string;
  'text-transform'?: string;
  color?: string;
};

// Each TextVariant has mobile, tablet, and desktop FontStyles
type TextStyle = {
  [variant in TextVariant]: {
    mobile: FontStyle;
    tablet: FontStyle;
    desktop: FontStyle;
  };
};

// An object defining the TextStyle for each TextVariant
const TEXT_STYLES: TextStyle = {
  displayMedium: {
    mobile: {
      'font-size': typography.size['50'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['104'],
    },
    tablet: {
      'font-size': typography.size['64'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['104'],
    },
    desktop: {
      'font-size': typography.size['96'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['104'],
    },
  },

  displaySmall: {
    mobile: {
      'font-size': typography.size['37'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['104'],
    },
    tablet: {
      'font-size': typography.size['50'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['104'],
    },
    desktop: {
      'font-size': typography.size['64'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['104'],
    },
  },

  h1: {
    mobile: {
      'font-size': typography.size['29'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['138'],
    },
    tablet: {
      'font-size': typography.size['37'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['119'],
    },
    desktop: {
      'font-size': typography.size['50'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['120'],
    },
  },

  h2: {
    mobile: {
      'font-size': typography.size['24'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['135'],
    },
    tablet: {
      'font-size': typography.size['29'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['138'],
    },
    desktop: {
      'font-size': typography.size['37'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['119'],
    },
  },

  h3: {
    mobile: {
      'font-size': typography.size['21'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['135'],
    },
    tablet: {
      'font-size': typography.size['24'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['135'],
    },
    desktop: {
      'font-size': typography.size['29'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['138'],
    },
  },

  h4: {
    mobile: {
      'font-size': typography.size['19'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['135'],
    },
    tablet: {
      'font-size': typography.size['21'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['135'],
    },
    desktop: {
      'font-size': typography.size['24'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['135'],
    },
  },

  h5: {
    mobile: {
      'font-size': typography.size['18'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['135'],
    },
    tablet: {
      'font-size': typography.size['19'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['135'],
    },
    desktop: {
      'font-size': typography.size['21'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['135'],
    },
  },

  h6: {
    mobile: {
      'font-size': typography.size['17'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['140'],
    },
    tablet: {
      'font-size': typography.size['18'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['135'],
    },
    desktop: {
      'font-size': typography.size['19'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['135'],
    },
  },

  bodyRegular: {
    mobile: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['400'],
      'line-height': typography.lineHeight['150'],
    },
    tablet: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['400'],
      'line-height': typography.lineHeight['150'],
    },
    desktop: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['400'],
      'line-height': typography.lineHeight['150'],
    },
  },

  bodyRegularBold: {
    mobile: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['600'],
      'line-height': typography.lineHeight['150'],
    },
    tablet: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['600'],
      'line-height': typography.lineHeight['150'],
    },
    desktop: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['600'],
      'line-height': typography.lineHeight['150'],
    },
  },

  bodyRegularBrand: {
    mobile: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['400'],
      'line-height': typography.lineHeight['150'],
      color: theme.color.primary['700'],
    },
    tablet: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['400'],
      'line-height': typography.lineHeight['150'],
      color: theme.color.primary['700'],
    },
    desktop: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['400'],
      'line-height': typography.lineHeight['150'],
      color: theme.color.primary['700'],
    },
  },

  bodyLarge: {
    mobile: {
      'font-size': typography.size['17'],
      'font-weight': typography.weight['400'],
      'line-height': typography.lineHeight['150'],
    },
    tablet: {
      'font-size': typography.size['18'],
      'font-weight': typography.weight['400'],
      'line-height': typography.lineHeight['150'],
    },
    desktop: {
      'font-size': typography.size['19'],
      'font-weight': typography.weight['400'],
      'line-height': typography.lineHeight['150'],
    },
  },

  bodyLargeBold: {
    mobile: {
      'font-size': typography.size['17'],
      'font-weight': typography.weight['600'],
      'line-height': typography.lineHeight['150'],
    },
    tablet: {
      'font-size': typography.size['18'],
      'font-weight': typography.weight['600'],
      'line-height': typography.lineHeight['150'],
    },
    desktop: {
      'font-size': typography.size['19'],
      'font-weight': typography.weight['600'],
      'line-height': typography.lineHeight['150'],
    },
  },

  cta: {
    mobile: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['150'],
    },
    tablet: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['150'],
    },
    desktop: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['150'],
    },
  },

  caption: {
    mobile: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['400'],
      'line-height': typography.lineHeight['140'],
    },
    tablet: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['400'],
      'line-height': typography.lineHeight['140'],
    },
    desktop: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['400'],
      'line-height': typography.lineHeight['140'],
    },
  },

  captionBold: {
    mobile: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['140'],
    },
    tablet: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['140'],
    },
    desktop: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['140'],
    },
  },

  footnote: {
    mobile: {
      'font-size': typography.size['12'],
      'font-weight': typography.weight['400'],
      'line-height': typography.lineHeight['100'],
    },
    tablet: {
      'font-size': typography.size['12'],
      'font-weight': typography.weight['400'],
      'line-height': typography.lineHeight['100'],
    },
    desktop: {
      'font-size': typography.size['12'],
      'font-weight': typography.weight['400'],
      'line-height': typography.lineHeight['100'],
    },
  },

  footnoteBold: {
    mobile: {
      'font-size': typography.size['12'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['100'],
    },
    tablet: {
      'font-size': typography.size['12'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['100'],
    },
    desktop: {
      'font-size': typography.size['12'],
      'font-weight': typography.weight['500'],
      'line-height': typography.lineHeight['100'],
    },
  },

  overline: {
    mobile: {
      'font-size': typography.size['12'],
      'font-weight': typography.weight['600'],
      'line-height': typography.lineHeight['135'],
      'letter-spacing': '0.05em',
      'text-transform': 'uppercase',
      color: theme.color.primary['400'],
    },
    tablet: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['600'],
      'line-height': typography.lineHeight['135'],
      'letter-spacing': '0.05em',
      'text-transform': 'uppercase',
      color: theme.color.primary['400'],
    },
    desktop: {
      'font-size': typography.size['16'],
      'font-weight': typography.weight['600'],
      'line-height': typography.lineHeight['135'],
      'letter-spacing': '0.05em',
      'text-transform': 'uppercase',
      color: theme.color.primary['400'],
    },
  },

  overlineBlack: {
    mobile: {
      'font-size': typography.size['12'],
      'font-weight': typography.weight['600'],
      'line-height': typography.lineHeight['135'],
      'letter-spacing': '0.05em',
      'text-transform': 'uppercase',
    },
    tablet: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['600'],
      'line-height': typography.lineHeight['135'],
      'letter-spacing': '0.05em',
      'text-transform': 'uppercase',
    },
    desktop: {
      'font-size': typography.size['16'],
      'font-weight': typography.weight['600'],
      'line-height': typography.lineHeight['135'],
      'letter-spacing': '0.05em',
      'text-transform': 'uppercase',
    },
  },

  overlineWhite: {
    mobile: {
      'font-size': typography.size['12'],
      'font-weight': typography.weight['600'],
      'line-height': typography.lineHeight['135'],
      'letter-spacing': '0.05em',
      'text-transform': 'uppercase',
      color: theme.color.white['900'],
    },
    tablet: {
      'font-size': typography.size['14'],
      'font-weight': typography.weight['600'],
      'line-height': typography.lineHeight['135'],
      'letter-spacing': '0.05em',
      'text-transform': 'uppercase',
      color: theme.color.white['900'],
    },
    desktop: {
      'font-size': typography.size['16'],
      'font-weight': typography.weight['600'],
      'line-height': typography.lineHeight['135'],
      'letter-spacing': '0.05em',
      'text-transform': 'uppercase',
      color: theme.color.white['900'],
    },
  },
};

export const withMedia = (style: FontStyle | ReturnType<typeof css>) => css`
  ${style};

  @media (min-width: ${theme.breakpoints.tablet}) {
    ${style};
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    ${style};
  }
`;

/**
 * As the only export in this file, this is how text styles should be utilized in styled components. By
 * only allowing pre-defined variants (and not allowing pick-and-choosing any combo of sizes, weights, and line heights)
 * we will have more consistent fonts throughout our products.
 *
 * If you think another text variant is needed, check with design first to see if an existing variant
 * can be used instead. If design agrees that another variant is needed, you can add it to the list of TextVariants
 * and define the styles for each breakpoint
 */
export const textStyle = (variant: TextVariant): ReturnType<typeof css> => css`
  ${TEXT_STYLES[variant].mobile};

  @media (min-width: ${theme.breakpoints.tablet}) {
    ${TEXT_STYLES[variant].tablet};
  }

  @media (min-width: ${theme.breakpoints.desktop}) {
    ${TEXT_STYLES[variant].desktop};
  }
`;

export const clampText = (lines: number) => css`
  @supports (-webkit-line-clamp: ${lines}) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
  }
`;
