/** ************************************************************************************************************
 * BEFORE ADDING OR MODIFYING AN EVENT:
 * - Make sure to add/update the corresponding row in the events directory
 *   table: https://www.notion.so/shopcanal/Event-Tracking-Directory-96bd57a62e8d4981942514af981b976f
 ************************************************************************************************************ */

import { RegisterNewUserShopFragmentFragment } from 'gql/graphql';
import { IS_DEVELOP } from 'lib/constants/internals';
import type { AppType, OnboardingType } from 'lib/constants/landing';

// Our secret Segment environment variables as constants. These are used for initializing the Segment script
// The value of these env variables live in Vercel!
const PRODUCTION_SEGMENT_API_KEY = process.env.NEXT_PUBLIC_SEGMENT_PRODUCTION_WRITE_KEY;
const DEVELOP_SEGMENT_API_KEY = process.env.NEXT_PUBLIC_SEGMENT_DEVELOP_WRITE_KEY;
export const SEGMENT_API_KEY_FOR_ENVIRONMENT = IS_DEVELOP
  ? DEVELOP_SEGMENT_API_KEY
  : PRODUCTION_SEGMENT_API_KEY;

// Enum of all event names we use when calling `analytics.track`.
// When adding a new `track` call, make sure to add a new event name here.
export enum EventNames {
  // Shopkeep app events (in alphabetical order)
  MOMENT_BANNER_CLICKED = 'Moment Banner Clicked',
  MOMENT_BANNER_VIEWED = 'Moment Banner Viewed',
  SAMPLE_REQUEST_CREATED = 'Sample Request Created',
  SAMPLE_REQUEST_CTA_CLICKED = 'Sample Request CTA Clicked',

  SHOPKEEP_ADDED_COLLABORATOR_EMAIL = 'Shopkeep Added Collaborator Email',
  SHOPKEEP_ADDED_IMAGERY_AND_DESCRIPTION = 'Shopkeep Added Imagery And Description',
  SHOPKEEP_ADDED_PAYMENT_INFORMATION = 'Shopkeep Added Payment Information',
  SHOPKEEP_CLICKED_DISCOVER_FROM_HOMEPAGE_WIDGET = 'Shopkeep Clicked Discover From Homepage Widget',
  SHOPKEEP_CLICKED_HOMEPAGE_ACCOUNT_SETUP = 'Shopkeep Clicked Homepage Account Setup',
  SHOPKEEP_CLICKED_HOMEPAGE_INSIGHTS_TILE = 'Shopkeep Clicked Homepage Insights Tile',
  SHOPKEEP_CLICKED_HOMEPAGE_MY_SUPPLIERS = 'Shopkeep Clicked Homepage My Suppliers',
  SHOPKEEP_CLICKED_HOMEPAGE_PROPOSALS = 'Shopkeep Clicked Homepage Proposals',
  SHOPKEEP_CLICKED_INVITE_FROM_HOMEPAGE_WIDGET = 'Shopkeep Clicked Invite From Homepage Widget',
  SHOPKEEP_CLICKED_SEND_A_PROPOSAL_FROM_HOMEPAGE_WIDGET = 'Shopkeep Clicked Send A Proposal From Homepage Widget',
  SHOPKEEP_CLICKED_SUPPLIER_BRAND_IN_DISCOVER = 'Shopkeep Clicked Supplier Brand In Discover',
  SHOPKEEP_FINAL_ONBOARDING_STEP_SUBMITTED = 'Shopkeep Final Onboarding Step Submitted',
  SHOPKEEP_LOADED_CREATE_PROPOSAL_PAGE = 'Shopkeep Loaded Create Proposal Page',
  SHOPKEEP_MADE_SALE_IN_PAST_30_DAYS = 'Shopkeep Made Sale In Past 30 Days',
  SHOPKEEP_MESSAGED_SUPPLIER_ABOUT_LATE_ORDER = 'Shopkeep Messaged Supplier About Late Order',
  SHOPKEEP_OPENED_MESSAGE_MODAL_FROM_INVENTORY_ALERTS = 'Shopkeep Opened Message Modal From Inventory Alerts',
  SHOPKEEP_SAW_UPGRADE_PLAN_MODAL = 'Shopkeep Saw Upgrade Plan Modal',
  SHOPKEEP_SENT_REMINDER_TO_PENDING_SUPPLIER = 'Shopkeep Sent Reminder To Pending Supplier',
  SHOPKEEP_SET_PRODUCT_TO_ARCHIVE_FROM_INVENTORY_ALERTS = 'Shopkeep Set Product To Archive From Inventory Alerts',
  SHOPKEEP_SET_PRODUCT_TO_DRAFT_FROM_INVENTORY_ALERTS = 'Shopkeep Set Product To Draft From Inventory Alerts',
  SHOPKEEP_TRIED_TO_RENEGOTIATE_SHIPPING_BUT_HAD_A_PENDING_SHIPPING_RENEGOTIATION = 'Shopkeep Tried To Renegotiate Shipping But Had A Pending Shipping Renegotiation',
  SUPPLIER_TRIED_TO_RENEGOTIATE_SHIPPING_BUT_HAD_A_PENDING_SHIPPING_RENEGOTIATION = 'Supplier Tried To Renegotiate Shipping But Had A Pending Shipping Renegotiation',
  SUPPLIER_INVITE_EMAIL_SENT = 'Supplier Invite Email Sent',
  SUPPLIER_PROPOSAL_CREATED = 'Supplier Proposal Created',
  SUPPLIER_PROPOSAL_EDITED = 'Supplier Proposal Edited',

  // Supplier app events (in alphabetical order)
  SUPPLIER_ADDED_CATEGORIES_AND_VALUES = 'Supplier Added Categories And Values',
  SUPPLIER_ADDED_COLLABORATOR_EMAIL = 'Supplier Added Collaborator Email',
  SUPPLIER_ADDED_IMAGERY_AND_DESCRIPTION = 'Supplier Added Imagery And Description',
  SUPPLIER_ADDED_RETURN_INFORMATION = 'Supplier Added Return Information',
  SUPPLIER_CREATED_PRODUCT_ON_CANAL = 'Supplier Created New Product On Canal',
  SUPPLIER_CREATED_VARIANT_ON_CANAL = 'Supplier Created New Variant On Canal',
  SUPPLIER_UPDATED_PRODUCT_ON_CANAL = 'Supplier Updated Product On Canal',
  SUPPLIER_UPDATED_VARIANT_ON_CANAL = 'Supplier Updated Variant On Canal',
  SUPPLIER_CLICKED_GET_STOREFRONT_APP_BUTTON = 'Supplier Clicked Get Storefront App Button',
  SUPPLIER_CLICKED_HOMEPAGE_ACCOUNT_SETUP = 'Supplier Clicked Homepage Account Setup',
  SUPPLIER_CLICKED_HOMEPAGE_DROPSHIPPING_PARTNERS = 'Supplier Clicked Homepage Dropshipping Partners',
  SUPPLIER_CLICKED_HOMEPAGE_INSIGHTS_TILE = 'Supplier Clicked Homepage Insights Tile',
  SUPPLIER_CLICKED_HOMEPAGE_ORDERS = 'Supplier Clicked Homepage Orders',
  SUPPLIER_CLICKED_HOMEPAGE_PROPOSALS = 'Supplier Clicked Homepage Proposals',
  SUPPLIER_CLICKED_HOMEPAGE_MESSAGES = 'Supplier Clicked Homepage Messages',
  SUPPLIER_CLICKED_HOMEPAGE_TEST_ORDER_BANNER = 'Supplier Clicked Homepage Test Order',
  SUPPLIER_CLICKED_START_SELLING_ON_LANDING_PAGE = 'Supplier Clicked Start Selling on Landing Page',
  SUPPLIER_FINAL_ONBOARDING_STEP_SUBMITTED = 'Supplier Final Onboarding Step Submitted',
  SUPPLIER_HAS_RUN_TEST_ORDER = 'Supplier Has Run Test Order',
  SUPPLIER_LISTED_PRODUCT = 'Supplier Listed Product',
  SUPPLIER_OPENED_UP_PAYOUT_METHOD = 'Supplier Opened Up Payout Method',

  // Agnostic app events (in alphabetical order)
  COUNTER_PROPOSAL_CREATED = 'Counter Proposal Created',
  SHOP_ACCEPTED_PROPOSAL = 'Shop Accepted Proposal',
  SHOP_ACCEPTED_SAMPLE_REQUEST = 'Shop Accepted Sample Request',
  SHOP_COPIED_DIRECT_REFERRAL_LINK = 'Shop Copied Direct Link',
  SHOP_CLICKED_ADD_TO_PROPOSAL_ON_PRODUCT_TILE = 'Shop Clicked Add To Proposal On Product Tile',
  SHOP_CLICKED_CAROUSEL_ARROW = 'Shop Clicked Carousel Arrow',
  SHOP_CLICKED_CAROUSEL_ITEM = 'Shop Clicked Carousel Item',
  SHOP_CLICKED_CAROUSEL_VIEW_ALL = 'Shop Clicked Carousel View All',
  SHOP_CLICKED_NAV_BAR_ITEM = 'Shop Clicked Nav Bar Item',
  SHOP_CLICKED_VIEW_PRODUCT_DETAILS_ON_PRODUCT_TILE = 'Shop Clicked View Product Details On Product Tile',
  SHOP_DECLINED_SAMPLE_REQUEST = 'Shop Declined Sample Request',
  SHOP_HAS_SEEN_REFERRAL = 'Shop Has Seen Referral',
  SHOP_MADE_ONBOARDING_ACTION = 'Shop Made Onboarding Action',
  SHOP_SEARCHED_ON_DISCOVER = 'Shop Searched on Discover',
  SHOP_SENT_MESSAGE = 'Shop Sent Message',
  SHOP_SENT_REFERRAL = 'Shop Sent Referral',
  SHOP_VIEWED_CAROUSEL = 'Shop Viewed Carousel',
  SHOP_VIEWED_BRAND_WITH_PENDING_PARTNERSHIP = 'Shop Viewed Brand With Pending Partnership',
  SHOP_VIEWED_PARTNERSHIP_REQUEST_MESSAGE = 'Shop Viewed Partnership Request Message',

  // Wishlist events
  BRAND_ADDED_TO_WISHLIST = 'Brand Added To Wishlist',

  // Other events (in alphabetical order)
  BUTTON_CLICKED = 'Button Clicked',
  NEW_USER_REGISTERED = 'New User Registered',
  USER_INVITED = 'User Invited',
  USER_LOGGED_IN = 'User Logged In',
  USER_INVITED_FROM_MODAL = 'User Invited From Modal',
  REVIEW_TEAM_MODAL_DISMISSED = 'Review Team Modal Dismissed',
}

// All surfaces from which we send a message through our MessageModal component
export enum MessageSurfaces {
  ASSET_LIBRARY_EMPTY_STATE = 'Asset Library Empty State',
  ASSET_LIBRARY_NON_EMPTY_STATE = 'Asset Library Non Empty State',
  BRAND_PAGE_EXISTING_PARTNERSHIP = 'Brand Page Existing Partnership',
  BRAND_PAGE_NEW_RELATIONSHIP = 'Brand Page New Relationship',
  BRAND_PAGE_PROMPT = 'Brand Page Prompt',
  CUSTOM_PRODUCT_INFO = 'Custom Product Info',
  FULFILLMENT_INSIGHTS = 'Fulfillment Insights',
  INBOX = 'Inbox',
  INVENTORY_STATUS_MODAL = 'Inventory Status Modal',
  ORDER_DETAILS_PAGE = 'Order Details Page',
  PROPOSAL_INVENTORY_ALERT_TOOLTIP = 'Proposal Inventory Alert Tooltip',
  PROPOSAL_EMPTY_PRODUCTS_TABLE = 'Proposal Empty Products Table',
  REQUEST_SAMPLES_MODAL = 'Request Samples Modal',
  REQUEST_SAMPLES_ORDER_SUMMARY = 'Request Samples Order Summary',
  SHOPKEEP_HOMEPAGE_WIDGET = 'Shopkeep Homepage Widget',
  SHOPKEEP_INVENTORY = 'Shopkeep Inventory',
  SHOPKEEP_SUPPLIER_PROFILE_INVENTORY = 'Shopkeep Supplier Profile Inventory',
  SUPPLIER_ORDERS_PAGE = 'Supplier Orders Page',
  SUPPLIER_SHIPS_TO_MODAL = 'Supplier Ships To Modal',
  SUPPLIER_INITIATED_PARTNERSHIP = 'Supplier Initiated Partnership',
  GENERATE_BUY_BUTTON_SUPPLIER_STRIPE_SETTINGS_MODAL = 'Generate Buy Button Supplier Stripe Settings Modal',
  SK_PROFILE = 'SK Profile',
  PARTNERSHIP_PRODUCTS = 'Partnership Products',
  SUP_PROFILE_PROPOSAL_MARKET_UPDATE_MARKET_PROMPT = 'Sup Profile Proposal Update Market Prompt',
}

// Each EventName maps to a typed object of properties for that event. When adding a new
// EventName, make sure to add a new set of event properties here as well
export type EventProperties = {
  /**
   * Shopkeep app events (in alphabetical order)
   */
  [EventNames.COUNTER_PROPOSAL_CREATED]: {
    proposal_id: string;
  };
  [EventNames.SAMPLE_REQUEST_CREATED]: {
    sample_request_id: string;
  };
  [EventNames.SAMPLE_REQUEST_CTA_CLICKED]: {
    origin: 'Supplier profile' | 'Message banner';
  };
  [EventNames.SHOPKEEP_CLICKED_DISCOVER_FROM_HOMEPAGE_WIDGET]: Record<string, never>;
  [EventNames.SHOPKEEP_CLICKED_HOMEPAGE_ACCOUNT_SETUP]: {
    action: string;
  };
  [EventNames.SHOPKEEP_CLICKED_HOMEPAGE_INSIGHTS_TILE]: {
    tile: string;
  };
  [EventNames.SHOPKEEP_CLICKED_HOMEPAGE_MY_SUPPLIERS]: Record<string, never>;
  [EventNames.SHOPKEEP_CLICKED_HOMEPAGE_PROPOSALS]: Record<string, never>;
  [EventNames.SHOPKEEP_CLICKED_INVITE_FROM_HOMEPAGE_WIDGET]: Record<string, never>;
  [EventNames.SHOPKEEP_CLICKED_SEND_A_PROPOSAL_FROM_HOMEPAGE_WIDGET]: {
    sup_id: string;
  };
  [EventNames.SHOPKEEP_CLICKED_SUPPLIER_BRAND_IN_DISCOVER]: {
    sup_id: string;
    carousel_title: string | undefined;
  };
  [EventNames.SHOPKEEP_FINAL_ONBOARDING_STEP_SUBMITTED]: { logged_in_shop_id: string };
  [EventNames.SHOPKEEP_MADE_SALE_IN_PAST_30_DAYS]: Record<string, never>;
  [EventNames.SHOPKEEP_LOADED_CREATE_PROPOSAL_PAGE]: {
    supplier_id: string;
    shopkeep_id: string;
    variant_ids: string[];
  };
  [EventNames.SHOPKEEP_SENT_REMINDER_TO_PENDING_SUPPLIER]: {
    sup_id: string;
    invite_id: string;
  };
  [EventNames.SHOPKEEP_TRIED_TO_RENEGOTIATE_SHIPPING_BUT_HAD_A_PENDING_SHIPPING_RENEGOTIATION]: {
    sup_id: string;
    proposal_id: string;
  };
  [EventNames.SUPPLIER_TRIED_TO_RENEGOTIATE_SHIPPING_BUT_HAD_A_PENDING_SHIPPING_RENEGOTIATION]: {
    sk_id: string;
    proposal_id: string;
  };
  [EventNames.SUPPLIER_INVITE_EMAIL_SENT]: {
    sup_email: string;
  };
  [EventNames.SUPPLIER_PROPOSAL_CREATED]: {
    proposal_id: string;
  };
  [EventNames.SUPPLIER_PROPOSAL_EDITED]: {
    proposal_id: string;
  };
  [EventNames.SUPPLIER_CREATED_PRODUCT_ON_CANAL]: {
    sup_id: string;
  };
  [EventNames.SUPPLIER_CREATED_VARIANT_ON_CANAL]: {
    sup_id: string;
    product_id: string;
  };
  [EventNames.SUPPLIER_UPDATED_PRODUCT_ON_CANAL]: {
    sup_id: string;
    product_id: string;
  };
  [EventNames.SUPPLIER_UPDATED_VARIANT_ON_CANAL]: {
    sup_id: string;
    variant_id: string;
  };
  [EventNames.MOMENT_BANNER_CLICKED]: {
    moment: string;
  };
  [EventNames.MOMENT_BANNER_VIEWED]: {
    moment: string;
  };
  [EventNames.SHOPKEEP_ADDED_COLLABORATOR_EMAIL]: {
    logged_in_shop_id: string;
    is_coming_from_account_setup: boolean;
  };
  [EventNames.SHOPKEEP_ADDED_IMAGERY_AND_DESCRIPTION]: {
    logged_in_shop_id: string;
    is_coming_from_account_setup: boolean;
  };
  [EventNames.SHOPKEEP_ADDED_PAYMENT_INFORMATION]: {
    logged_in_shop_id: string;
    is_coming_from_account_setup: boolean;
  };
  [EventNames.SHOPKEEP_MESSAGED_SUPPLIER_ABOUT_LATE_ORDER]: {
    logged_in_shop_name: string | undefined;
    contacted_sup_name: string | undefined;
    page: string;
  };
  [EventNames.SHOPKEEP_SAW_UPGRADE_PLAN_MODAL]: {
    shop_id: string | undefined;
    shop_name: string | undefined;
  };
  [EventNames.SHOPKEEP_SET_PRODUCT_TO_ARCHIVE_FROM_INVENTORY_ALERTS]: {
    product_id: string;
    inventory_alert_type: string | undefined;
  };
  [EventNames.SHOPKEEP_SET_PRODUCT_TO_DRAFT_FROM_INVENTORY_ALERTS]: {
    product_id: string;
    inventory_alert_type: string | undefined;
  };
  [EventNames.SHOPKEEP_OPENED_MESSAGE_MODAL_FROM_INVENTORY_ALERTS]: {
    product_id: string;
    inventory_alert_type: string | undefined;
  };

  /**
   * Supplier app events (in alphabetical order)
   */
  [EventNames.SUPPLIER_FINAL_ONBOARDING_STEP_SUBMITTED]: { logged_in_shop_id: string };
  [EventNames.SUPPLIER_CLICKED_GET_STOREFRONT_APP_BUTTON]: {
    logged_in_shop_id: string;
    source: 'banner' | 'modal';
  };
  [EventNames.SUPPLIER_CLICKED_HOMEPAGE_ACCOUNT_SETUP]: {
    action: string;
  };
  [EventNames.SUPPLIER_CLICKED_HOMEPAGE_DROPSHIPPING_PARTNERS]: {
    section: string;
  };
  [EventNames.SUPPLIER_CLICKED_HOMEPAGE_INSIGHTS_TILE]: {
    tile: string;
  };
  [EventNames.SUPPLIER_CLICKED_HOMEPAGE_ORDERS]: Record<string, never>;
  [EventNames.SUPPLIER_CLICKED_HOMEPAGE_PROPOSALS]: Record<string, never>;
  [EventNames.SUPPLIER_CLICKED_HOMEPAGE_MESSAGES]: Record<string, never>;
  [EventNames.SUPPLIER_CLICKED_HOMEPAGE_TEST_ORDER_BANNER]: Record<string, never>;
  [EventNames.SUPPLIER_HAS_RUN_TEST_ORDER]: {
    logged_in_shop_id: string;
  };
  [EventNames.SUPPLIER_LISTED_PRODUCT]: {
    logged_in_shop_id: string;
    is_coming_from_account_setup: boolean;
  };
  [EventNames.SUPPLIER_ADDED_IMAGERY_AND_DESCRIPTION]: {
    logged_in_shop_id: string;
    is_coming_from_account_setup: boolean;
  };
  [EventNames.SUPPLIER_ADDED_CATEGORIES_AND_VALUES]: {
    logged_in_shop_id: string;
    is_coming_from_account_setup: boolean;
  };
  [EventNames.SUPPLIER_ADDED_RETURN_INFORMATION]: {
    logged_in_shop_id: string;
    is_coming_from_account_setup: boolean;
  };
  [EventNames.SUPPLIER_OPENED_UP_PAYOUT_METHOD]: {
    logged_in_shop_id: string;
    is_coming_from_account_setup: boolean;
  };
  [EventNames.SUPPLIER_ADDED_COLLABORATOR_EMAIL]: {
    logged_in_shop_id: string;
    is_coming_from_account_setup: boolean;
  };
  [EventNames.SUPPLIER_CLICKED_START_SELLING_ON_LANDING_PAGE]: {
    logged_in_shop_name: string | undefined;
  };

  /**
   * Agnostic app events (in alphabetical order)
   * */
  [EventNames.SHOP_ACCEPTED_PROPOSAL]: {
    logged_in_shop_id: string;
    responding_shop_id: string;
    proposal_id: string;
    app_type: 'shopkeep' | 'supplier' | 'unknown';
  };
  [EventNames.SHOP_ACCEPTED_SAMPLE_REQUEST]: {
    logged_in_shop_id: string;
    responding_shop_id: string;
    sample_request_id: string;
    app_type: 'shopkeep' | 'supplier' | 'unknown';
  };
  [EventNames.SHOP_CLICKED_ADD_TO_PROPOSAL_ON_PRODUCT_TILE]: {
    product_id: string;
    source:
      | 'discover_landing'
      | 'my_suppliers'
      | 'discover_results'
      | 'pdp'
      | 'available_products'
      | undefined;
    carousel_title: string | undefined;
  };
  [EventNames.SHOP_CLICKED_CAROUSEL_ARROW]: {
    // the two different types of apps
    app_type: AppType;

    // title of carousel interacted with
    carousel_title: string;

    // the logged in shop id
    logged_in_shop_id: string;
  };
  [EventNames.SHOP_CLICKED_CAROUSEL_ITEM]: {
    // the two different types of apps
    app_type: AppType;

    // title of carousel interacted with
    carousel_title: string;

    // item type
    item_type: 'products' | 'shops' | 'categories';

    // the logged in shop id
    logged_in_shop_id: string;
  };
  [EventNames.SHOP_CLICKED_CAROUSEL_VIEW_ALL]: {
    // the two different types of apps
    app_type: AppType;

    // title of carousel interacted with
    carousel_title: string;

    // the logged in shop id
    logged_in_shop_id: string;

    // destination of view all
    view_all_destination_url_suffix: string;
  };
  [EventNames.SHOP_CLICKED_NAV_BAR_ITEM]: {
    name: string;
  };
  [EventNames.SHOP_CLICKED_VIEW_PRODUCT_DETAILS_ON_PRODUCT_TILE]: {
    product_id: string;
    source:
      | 'discover_landing'
      | 'my_suppliers'
      | 'discover_results'
      | 'pdp'
      | 'available_products'
      | undefined;
    carousel_title: string | undefined;
  };
  [EventNames.SHOP_DECLINED_SAMPLE_REQUEST]: {
    logged_in_shop_id: string;
    responding_shop_id: string;
    sample_request_id: string;
    app_type: 'shopkeep' | 'supplier' | 'unknown';
  };
  [EventNames.SHOP_HAS_SEEN_REFERRAL]: {
    logged_in_shop_id: string;
    inviter_shop_id: string;
  };
  [EventNames.SHOP_SEARCHED_ON_DISCOVER]: {
    logged_in_shop_id: string;
    app_type: 'shopkeep' | 'supplier';
    search_term: string;

    // if logged-in shop clicks on a product from typeahead
    product_id_clicked_from_typeahead?: string;

    // if logged-in shop clicks on a shop from typeahead
    shop_id_clicked_from_typeahead?: string;

    // if logged-in shop presses enter on keyboard
    total_product_count?: number;
  };
  [EventNames.SHOP_SENT_MESSAGE]: {
    origination_surface: MessageSurfaces;
    communication_container_id?: string;
  };
  [EventNames.SHOP_SENT_REFERRAL]: {
    logged_in_shop_id: string;
    email_list: string[];
    app_type: 'shopkeep' | 'supplier' | 'unknown';
  };
  [EventNames.SHOP_MADE_ONBOARDING_ACTION]: {
    // route name the action happened on
    page: string;

    // the text on the button that was clicked
    action: string;
  };
  [EventNames.SHOP_VIEWED_CAROUSEL]: {
    // the two different types of apps
    app_type: AppType;

    // title of carousel interacted with
    carousel_title: string;

    // the logged in shop id
    logged_in_shop_id: string;
  };
  [EventNames.SHOP_VIEWED_BRAND_WITH_PENDING_PARTNERSHIP]: {
    // the logged in shop id
    logged_in_shop_id: string;

    // the id of the brand being viewed
    brand_id: string;
  };
  [EventNames.SHOP_VIEWED_PARTNERSHIP_REQUEST_MESSAGE]: Record<string, never>;
  // Other events (in alphabetical order)
  [EventNames.BUTTON_CLICKED]: {
    button_id: string;
  };
  [EventNames.NEW_USER_REGISTERED]: {
    app_type: AppType | undefined;
    platform: OnboardingType | undefined;
    shop: RegisterNewUserShopFragmentFragment;
  };
  [EventNames.USER_INVITED]: {
    // the two different types of onboarding flows
    app_type?: AppType;

    logged_in_shop_id: string;

    // Email of user invited
    invited_email: string;
  };
  [EventNames.USER_INVITED_FROM_MODAL]: {
    // the two different types of onboarding flows
    app_type?: AppType;

    logged_in_shop_id: string;

    // Email of user invited
    invited_email: string;
  };
  [EventNames.REVIEW_TEAM_MODAL_DISMISSED]: {
    app_type: AppType;
    logged_in_shop_id: string;
  };
  [EventNames.USER_LOGGED_IN]: {
    // the two different types of onboarding flows
    app_type?: AppType;

    // all three possible actions a shop can make in onboarding
    action_type: string;

    // the state where the action happened
    state?: string;
  };
  [EventNames.SHOP_COPIED_DIRECT_REFERRAL_LINK]: {
    logged_in_shop_name: string | undefined;
    app_type: AppType;
  };
  [EventNames.BRAND_ADDED_TO_WISHLIST]: {
    brand_id: string;
  };
};

// A helper function to log Segment events. Use this to track events!
export const trackSegmentEvent = (
  eventName: EventNames,
  eventProperties: EventProperties[EventNames],
): void => {
  if (typeof window !== 'undefined') window?.analytics?.track(eventName, eventProperties);
};
