import { IS_PRODUCTION } from 'lib/constants/internals';

const CANAL_SC_APP_TEST_2_PK = '07c991b2b40bc78758242011d2ffd77d';
const CANAL_SC_APP_PK = '271e922738a7bffaa15aff09ffa27d08';
const CANAL_STOREFRONT_APP_PK = 'acdddcf4ea802c26772039302f8e3299';
const CANAL_STOREFRONT_TEST_OLD_APP_PK = '76b0ad7e8e190b97f333288da4cad6d7';
const SHOPIFY_SALES_CHANNEL_TEST_CANAL7_APP_PK = '8b32ddab07897d9682969191061835a0';
const SHOPIFY_SHOPKEEP_TEST_APP_6_PK = '210da0f826a6d9ec8944d7ece3aef802';
const SHOPIFY_SHOPKEEP_TEST_APP_8_PK = '95e5b6fa3cf040eec8b1f3dee143e671';
const SHOPIFY_SALES_CHANNEL_TEST_CANAL9_APP_PK = '2ed402fe3a96b246c175bf9b50ef90ef';
const SHOPIFY_SHOPKEEP_DEVELOP_PK_4 = '0e10f316b724e4953418876e4ec54627';
const SHOPIFY_SK_DEVELOP_PK_5 = '7d6158890cbe3f6779c38a8edd37a273';
const SHOPIFY_SUPPLIER_DEVELOP_PK_DEV_SUP = '82c1f4daecff18ad202ac4455fe5dfcf';
const CANAL_DEVELOP_SHOPKEEP_PK = 'cf5d3aa955e1f14f0599fccd50b6e54a';

const APP_NAME_URL_PARAM_TO_PK = new Map([
  ['canal', CANAL_SC_APP_PK], // SUP app
  ['canal2', CANAL_SC_APP_TEST_2_PK],
  ['storefront', CANAL_STOREFRONT_APP_PK], // SK app
  ['storefront_old', CANAL_STOREFRONT_TEST_OLD_APP_PK],
  ['canal6', SHOPIFY_SHOPKEEP_TEST_APP_6_PK],
  ['canal7', SHOPIFY_SALES_CHANNEL_TEST_CANAL7_APP_PK],
  ['canal8', SHOPIFY_SHOPKEEP_TEST_APP_8_PK],
  ['canal9', SHOPIFY_SALES_CHANNEL_TEST_CANAL9_APP_PK],
  ['canal4', SHOPIFY_SHOPKEEP_DEVELOP_PK_4],
  ['canal5', SHOPIFY_SK_DEVELOP_PK_5],
  ['canal-dev-sup', SHOPIFY_SUPPLIER_DEVELOP_PK_DEV_SUP],
  ['canal-develop-sk', CANAL_DEVELOP_SHOPKEEP_PK],
  [`canal-${process.env.NEXT_PUBLIC_SK_APP_NAME}`, `${process.env.NEXT_PUBLIC_SK_PUBLIC_KEY}`],
  [`canal-${process.env.NEXT_PUBLIC_SUP_APP_NAME}`, `${process.env.NEXT_PUBLIC_SUP_PUBLIC_KEY}`],
]);

export default APP_NAME_URL_PARAM_TO_PK;
export const CANAL_APP_NAME_TO_IS_SUPPLIER_APP: { [key: string]: boolean } = {
  canal: true,
  canal2: true,
  canal7: true,
  canal9: true,
  'canal-dev-sup': true,
};

CANAL_APP_NAME_TO_IS_SUPPLIER_APP[`canal-${process.env.NEXT_PUBLIC_SUP_APP_NAME}`] = true;

const PROD_SHOPIFY_SUPPLIER_APP_ID = 5218177;
const DEV_SHOPIFY_SUPPLIER_APP_ID = 5725849;

export const SHOPIFY_SUPPLIER_APP_ID = IS_PRODUCTION
  ? PROD_SHOPIFY_SUPPLIER_APP_ID
  : DEV_SHOPIFY_SUPPLIER_APP_ID;
