/**
 * @returns If the app is embedded in an iframe app interface. If true,
 * there should be no header/we should assume we have access to the
 * embedded app context. If false, we should show our own header/handle
 * login info ourselves.
 */
export const isEmbeddedApp =
  typeof window !== 'undefined' && window && window.name.includes('iframe');

export const isShopifyEmbeddedApp =
  typeof window !== 'undefined' && window && window.name === 'app-iframe';

export const isBigCommerceEmbeddedApp =
  typeof window !== 'undefined' && window && window.name === 'cp-iframe';
