type Surface =
  | 'mobile-overlay'
  | 'tooltips-in-modal'
  | 'modal'
  | 'tooltip'
  | 'toast'
  | 'pinned-banner'
  | 'navigation-tab'
  | 'form';

/**
 * The order of the surfaces in the array is the order in which they should appear the the user
 * (i.e. the first element in the array should be on the top, and the last element should
 * be on the bottom).
 */
const Z_INDICES: Surface[] = [
  'toast',
  'mobile-overlay',
  'tooltips-in-modal',
  'modal',
  'tooltip',
  'navigation-tab',
  'form',
  'pinned-banner',
];

/**
 * Some offset value so the function doesn't return 1, 2, 3, etc.
 */
const OFFSET = 100;

export const zIndex = (surface: Surface) => Z_INDICES.length - Z_INDICES.indexOf(surface) + OFFSET;
