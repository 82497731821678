import { TeamsMembershipRoleChoices } from 'gql/graphql';
import { SPYGLASS_PREFIX } from 'lib/constants/routes';
import { isEmbeddedApp } from 'lib/helpers/isEmbeddedApp';
import { setItemToLocalStorage } from 'lib/helpers/localStorage';
import { useRouter } from 'next/router';
import { GHOST_MODE_LOCAL_STORAGE_KEY } from './constants';
import { useSession } from './useSession';

/**
 * Hook that returns whether or not the logged in user is in ghost (read-only) mode or not.
 * Gets the user's membership from the current session, then checks whether the role
 * matches the ghost role.
 *
 * @returns [isGhostMode, hasFinishedLoading]
 */
export const useIsGhostMode = (): readonly [boolean, boolean] => {
  const {
    session: { membership },
    hasFinishedLoading,
  } = useSession();

  const isGhostMode = !isEmbeddedApp && membership?.role === TeamsMembershipRoleChoices.GHOST;

  setItemToLocalStorage(GHOST_MODE_LOCAL_STORAGE_KEY, String(isGhostMode));

  return [isGhostMode, hasFinishedLoading] as const;
};

export const useIsInSpyglass = (): boolean => {
  const { asPath } = useRouter();

  return asPath.includes(SPYGLASS_PREFIX);
};
