import ToastTogglerContext from 'components/shared/toast/ToastTogglerContext';
import { useContext } from 'react';

/**
 * Simple convenience hook for showing a toast in the global toast slot
 */
const useToast = () => {
  const toastToggler = useContext(ToastTogglerContext);
  if (toastToggler === undefined) {
    throw new Error('useToast must be used within a ToastTogglerProvider');
  }

  return toastToggler;
};

export default useToast;
