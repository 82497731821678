import { isArray } from '@apollo/client/utilities';
import * as Sentry from '@sentry/nextjs';
import { createApp } from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { getShopifyCredentials } from 'components/shared/ShopifyAppBridgeProvider';
import Linkify from 'linkify-react';
import { isEmbeddedApp } from './isEmbeddedApp';
import { isNonNullItem } from './nonNull';
import { parseStringForURL } from './strings';

export const CONSTANTS = {
  HTTPS: 'https://',
};

/**
 * Removes all possible protocols from url string (i.e. http, www, etc.).
 * @param urlString String of URL to remove protocols from
 */
export const removeAllProtocols = (urlString: string): string =>
  urlString.replace(/(^\w+:|^)\/\//, '');

/**
 * Removes all possible HTTP protocols from url string (i.e. http, https.).
 * @param urlString String of URL to remove HTTP protocols from
 * @ref https://stackoverflow.com/a/8206279
 */
export const removeAllHTTPProtocols = (urlString: string): string =>
  urlString.replace(/^https?:\/\//, '');

/**
 * This is a helper function to return if it's a valid url or not.
 *  @param urlString String of URL to determine if valid
 */
export const isValidUrl = (urlString: string): boolean => {
  const re =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  return re.test(String(urlString).toLowerCase());
};

/**
 * Determines if a URL string has a valid TLD (i.e. ".com"), but not http, https, etc.
 * @param urlString String of URL to determine if it has a valid TLD
 */
export const hasValidTLD = (urlString: string): boolean => {
  const re =
    /^(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  return re.test(String(urlString).toLowerCase());
};

/**
 * Opens an external URL in a new tab.
 * @deprecated In favor of using openExternal() with a string parameter.
 */
export const openExternalUrl = (url: URL): void => {
  window.open(url.toString(), '_blank');
};

/**
 * Opens an external URL in a new tab.
 */
export const openExternal = (url: string): void => {
  window.open(url, '_blank');
};

/**
 * If the string is a valid URL, it converts the string into a URL object.
 *
 * @param urlString The string to encode into a URL.
 * @returns Using the url string, it returns a valid URL or null.
 */
export const urlFromString = (urlString: string): URL | null => {
  if (urlString === undefined || urlString === null || urlString.length === 0) {
    return null;
  }
  return new URL(urlString);
};

/**
 * Grabs the admin url for Shopify Settings
 *
 * Format: https://[shop].myshopify.com/admin/settings/[section]
 * Example: https://kimia-test-sk.myshopify.com/admin/settings/shipping
 * @param shopUrl Domain name of the Shopify shop's admin.
 * @param section name of settings section to append to path.
 * @returns Full URL for the settings page open to the correction section.
 */
export const shopifyAdminSettingsUrl = (shopUrl: string, section: string): string => {
  const path = `/admin/settings/${section}`;
  const fullUrl = CONSTANTS.HTTPS + shopUrl + path;
  return fullUrl;
};

type GeneratedCSVResponseType = {
  csvUrl: string | null;
  ok: boolean | null;
  emailAddresses?: (string | null)[] | null;
};

type DownloadGeneratedCSVMutationResponseType = {
  [T: string]: GeneratedCSVResponseType | string | null;
};

type DownloadCSVMutationHelperProps = {
  response: {
    data: DownloadGeneratedCSVMutationResponseType | null | undefined;
    errors?: readonly unknown[] | undefined;
  };
  mutationName: string;
  showToast: (
    message: string,
    options?: Partial<{
      isLoading: boolean;
      duration: number;
      actionText?: string;
      onAction?: () => void;
    }>,
  ) => void;
};

/**
 * Helps streamline the CSV download operation by making it generic. Will either download the CSV if the url is given, or notify the user that the csv has been sent to their email
 * @param response The response to the async 'generate' mutation
 * @param mutationName Name of BE mutation, needed to access response data
 * @param showToast Needed to display message if user was sent an email
 * @returns void, downloads csv to users browser/notifies them of an email
 *
 * Example: See `ListView` component
 */
export const downloadCSVMutationHelper = ({
  response,
  mutationName,
  showToast,
}: DownloadCSVMutationHelperProps) => {
  if (response.data) {
    const data = response.data[mutationName];
    if (typeof data === 'object') {
      if (data?.ok && data.csvUrl) {
        window.open(data.csvUrl);
      }
      if (data?.ok && !data.csvUrl) {
        showToast(
          `Your CSV was emailed to ${
            data.emailAddresses?.filter(isNonNullItem).join(', ') || "your shop's primary email"
          }`,
        );
      }
    }
  }
};

/**
 * Downloads a csv file from a given link to our BE
 *
 * Format: https://[ENV]/[API_PROXY]/[PAGE_LOCATION]/[BE_DOWNLOAD_FUNCTION]?[PARAMS]
 * Example: const url = `${API_PROXY}/payments/download_pivoted_line_items_for_sk?start=2023-06-06&end=2023-07-17` (found in SK orders)
 * @param url Backend rest endpoint location
 * @param contentType Document type (currently only csv or pdf)
 * @returns void, downloads csv to users browser
 */
export const downloadFile = async (url: string, contentType: string | null = null) => {
  try {
    let headers: HeadersInit = {};
    if (isEmbeddedApp) {
      const creds = getShopifyCredentials();
      if (creds) {
        const app = createApp(creds);
        const sessionToken = await getSessionToken(app);
        headers = {
          Authorization: `Bearer ${sessionToken}`,
        };
      }
    }

    const opts: RequestInit = {
      method: 'GET',
      headers,
    };

    await fetch(url, opts)
      .then((response) => {
        if (response.status !== 200) {
          return Promise.reject(new Error(`Server responded with ${response.status}`));
        }
        return response.blob().then((data) => {
          const redirectUrl = response.url;
          return {
            data,
            redirectUrl,
          };
        });
      })
      .then(({ data, redirectUrl }) => {
        if (!redirectUrl && !data) {
          if (!data) {
            Sentry.captureMessage('No data');
          }
          if (!redirectUrl) {
            Sentry.captureMessage('No redirect url');
          }
          return;
        }
        // Create blob link to download
        const typeToCreate = contentType || 'text/csv';
        const url = window.URL.createObjectURL(new Blob([data], { type: typeToCreate }));
        const link = document.createElement('a');

        link.href = redirectUrl || url;
        link.setAttribute('download', 'true');

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      });
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

/**
 * Takes a string list [a, b] and converts it into Record object { a: a, b: b}
 * Useful for useRouterXXXQuery as a way to construct the 'allow list' (i.e. for useRouterTabQuery it is used to construct allTabs)
 *
 * @param list of strings [x1, x2, ...]
 * @returns A record with key and value of strings above { x1: x1, x2: x2, ...}
 */
export const convertStringListToRecordObject = (list: string[]): Record<string, string> => {
  const newObject: Record<string, string> = {};
  list.forEach((key) => {
    newObject[parseStringForURL(key)] = parseStringForURL(key);
  });
  return newObject;
};

export const isParamStringType = (param: string | string[] | undefined) =>
  !isArray(param) && !!param;

export const isAtLeastOneParamInUse = (params: (string | string[] | undefined)[]) =>
  params.some((param) => isParamStringType(param));

/**
 * Takes a string and checks for any links inside and converts them to <a> tag
 * Example: MessageRow
 *
 * @param text: string to be converted
 * @returns JSX element of the text with links
 */
export const urlifyText = (text: string) => (
  <Linkify options={{ target: '_blank' }}>{text}</Linkify>
);
