import { API_URL } from 'lib/api';
import { downloadFile } from 'lib/helpers/urls';
import useToast from 'lib/hooks/interactions/useToast';
import { useCallback, useMemo, useState } from 'react';
import ExportProductsContext, { ExportOptions } from './ExportProductsContext';

const getExportProductsURL = (options: ExportOptions) => {
  const baseURL = `${API_URL}/platform/csv/product/export/`;
  if (options.type === 'all') {
    return baseURL;
  }
  if (options.type === 'selected') {
    const { productIds } = options;
    return `${baseURL}?${new URLSearchParams(productIds.map((id) => ['selected_id', id]))}`;
  }
  throw new Error('Not yet supported!');
};

interface ProviderProps {
  /**
   * This will show children components within itself
   */
  children: React.ComponentProps<'div'>['children'];
}

/**
 * Context for exporting products -- is context cause we want to
 * prevent the user from making a second export while the first one
 * is still in progress.
 *
 * Can be trigger from various parts of the app, returns the state
 * of the export and a function to trigger it. Has toast baked in.
 */
const ExportProductsProvider = ({ children }: ProviderProps) => {
  const [isExporting, setIsExporting] = useState(false);
  const showToast = useToast();
  const exportProducts = useCallback(
    async (count: number, options: ExportOptions) => {
      if (isExporting) return;

      setIsExporting(true);
      showToast(`Exporting${count > 1 ? ` ${count} products` : ''}`, {
        isLoading: true,
        duration: Infinity,
      });
      try {
        await downloadFile(getExportProductsURL(options));
        showToast('');
      } catch (error) {
        showToast(
          error instanceof Error ? error.message : 'Something went wrong. Please try again.',
        );
      } finally {
        setIsExporting(false);
      }
    },
    [isExporting, showToast],
  );

  const exportProductsState = useMemo(
    () => ({ isExporting, exportProducts }),
    [isExporting, exportProducts],
  );

  return (
    <ExportProductsContext.Provider value={exportProductsState}>
      {children}
    </ExportProductsContext.Provider>
  );
};

export default ExportProductsProvider;
