import React, { ReactNode } from 'react';
import InformationIcon from './InformationIcon';
import TooltipInner from './TooltipInner';

export type TooltipPosition = 'above' | 'below' | 'left' | 'right';
export type ArrowPosition = 'left' | 'center' | 'right';

export type Props = {
  children: ReactNode;

  /**
   * The main body text to display in the tooltip. Set to null to prevent the tooltip from opening.
   */
  content: React.ReactNode | string | null;

  /**
   * When true, renders the tooltip with a black background with white text. When false,
   * renders the tooltip with a white background and black text
   */
  isDark?: boolean;

  /**
   * Optional prop for controlling the visibility of the tooltip content; when undefined,
   * the tooltip will be displayed when the children are hovered over
   */
  isOpen?: boolean;

  /**
   * When true, the tooltip renders white text
   */
  hasWhiteText?: boolean;

  /**
   * An optional headline to be displayed above the main content of the tooltip.
   */
  headline?: string;

  /**
   * An optional Remixicon class name to be displayed as an icon above the main content of the tooltip, to
   * the left of the headline. Will not be rendered unless a headline is also provided
   */
  icon?: string;

  /**
   * Optional text to display in a button at the bottom of the tooltip. When present, the tooltip
   * will only be dismissed when this button is pressed
   */
  dismissText?: string;

  /**
   * Vertical position of the tooltip in relation to the children. The tooltip can be displayed
   * either `above`, `below`, `right` or `left` of the children. `left` only has one set position.
   * Defaults to `below`
   */
  tooltipPosition?: TooltipPosition;

  /**
   * Horizontal position of the tooltip arrow in relation to the tooltip body. The arrow can be
   * displayed on the `left`, `center`, or `right` side of the vertical tooltip edge closest to
   * the children. Defaults to `center`
   */
  arrowPosition?: ArrowPosition;

  /**
   * Number of milliseconds to delay on trigger hover before hiding tooltip. Default is 200.
   */
  delayLeave?: number;

  /**
   * Number of milliseconds to delay on trigger hover before displaying tooltip. Default is 0.
   */
  delayEnter?: number;

  /**
   * When true, the tooltip will be displayed with an arrow. Defaults to true
   */
  showArrow?: boolean;

  /**
   * triggerOffset is the distance in pixels between layer and trigger. Defaults to 15.
   */
  triggerOffset?: number;

  /**
   * Specifies the z-index to set the tooltip content to, overriding the default z-index
   * of the tooltip. This is useful when the tooltip is being displayed within a modal.
   */
  zIndexOverride?: number;
};

/**
 * Displays a tooltip popup when the children are hovered over. This component
 * is useful when we need to display additional info or help onboard users to
 * new features or announcements
 *
 * If the `dismissText` prop is provided, the tooltip will only disappear once
 * the button containing that text is pressed. Otherwise, the tooltip will
 * disappear when the children are no longer hovered over.
 */
const Tooltip = ({ children, ...props }: Props): React.ReactElement => (
  <TooltipInner {...props}>{children}</TooltipInner>
);

export { InformationIcon };

export default Tooltip;
