/**
 * Typeguard to check if the array is defined and has a string object as its first item
 * @param array An object that may or may not be an array
 */
const isStringArray = (array: string | string[] | undefined): array is string[] =>
  !!array && typeof array?.[0] === 'string';

/**
 * Converts a string/string[]/undefined into a string (empty string is base case)
 * @param param A string/string[]/undefined directly from router.query
 * @returns A string from the param
 */
export const stringifiedParam = (param: string | string[] | undefined): string => {
  if (typeof param === 'string') {
    return param;
  }
  if (isStringArray(param)) {
    return param[0];
  }
  return '';
};
