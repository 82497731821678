// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const urlsToIgnore = ['localhost', 'ngrok', 'e2e_data_population'];

Sentry.init({
  dsn: SENTRY_DSN || 'https://f69363b1b2aa4f4380245ca558fa14c7@o926226.ingest.sentry.io/5877519',
  tracesSampleRate: 1.0,

  // We don't want to log localhost or ngrok errors to Sentry; this filters them out
  beforeSend: (event) => {
    const requestUrl = event?.request?.url;
    if (requestUrl && urlsToIgnore.some((url) => requestUrl.includes(url))) {
      return null;
    }
    return event;
  },

  // We want to ignore some errors that are outside of our control
  ignoreErrors: [
    // Discussion about this error: https://github.com/getsentry/sentry-javascript/issues/3440
    'Non-Error promise rejection captured with value:',
  ],
});
