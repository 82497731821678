import { DEFAULT_TEXT_VARIANT, TextVariant, textStyle } from 'panama/styles/typography';
import styled, { css } from 'styled-components';

export interface Props {
  /**
   * Variant of the text
   */
  $variant?: TextVariant;

  /**
   * Color of the text
   */
  color?: string;

  /**
   * Text decoration of the text
   */
  $textDecoration?: string;

  /**
   * Determines if the text is upper-cased
   */
  $isUppercase?: boolean;

  /**
   * Determines if the text should wrap
   */
  $isNoWrap?: boolean;

  /**
   * If specified, lays out the contents a particular way within its parent
   */
  $alignment?: PanamaTable.Alignment;

  /**
   * If specified, creates space between the text and other icons (used in BadgeCell)
   */
  $paddingLeft?: string;
}

/**
 * Renders manipulated text object.
 */
const Text = styled.span<Props>`
  ${({ $alignment: alignment }) =>
    alignment &&
    css`
      text-align: ${alignment};
    `};
  ${({ $variant: variant }) => textStyle(variant ?? DEFAULT_TEXT_VARIANT)};
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
  ${({ $isUppercase }) =>
    $isUppercase &&
    css`
      text-transform: uppercase;
    `};
  ${({ $isNoWrap }) =>
    $isNoWrap
      ? css`
          white-space: nowrap;
        `
      : css`
          overflow-wrap: break-word;
        `};
  ${({ $textDecoration }) =>
    $textDecoration &&
    css`
      text-decoration: ${$textDecoration};
    `};
  ${({ $paddingLeft }) =>
    $paddingLeft &&
    css`
      padding-left: ${$paddingLeft};
    `};
`;

export default Text;
