import { useEffect } from 'react';
import { isBigCommerceEmbeddedApp } from 'lib/helpers/isEmbeddedApp';
import { getItemFromLocalStorage, setItemToLocalStorage } from 'lib/helpers/localStorage';
import { BACKEND_URL } from 'lib/constants/internals';
import * as Sentry from '@sentry/nextjs';

const SIGNED_LOCAL_STORAGE_KEY = 'saved_payload_jwt';
const SIGNED_JWT_PARAM = 'signed_payload_jwt';
export const CUR_JWT_LOCAL_STORAGE_KEY = 'bcjwt';

/**
 * Pulls an item out of local storage and tries to parse to a
 * string. Only works well if the item was a string when it was
 * saved to begin with
 */
const grabLocalStorageItem = (key: string): string | null => {
  const savedItem = getItemFromLocalStorage(key);
  if (savedItem && savedItem.length > 0) {
    try {
      return JSON.parse<string>(savedItem);
    } catch {
      return null;
    }
  }
  return null;
};

/**
 * Fetch bigcommerce jwt token used for pinging bigcommerce/load/ from local storage
 * @returns string jwt token
 */
export const getBigCommerceCredentials = (): string | null => {
  if (!isBigCommerceEmbeddedApp) {
    return null;
  }

  if (window) {
    const searchParams = new URLSearchParams(window.location.search);
    const jwt = searchParams.get(SIGNED_JWT_PARAM);
    if (jwt) {
      setItemToLocalStorage(SIGNED_LOCAL_STORAGE_KEY, String(jwt));
      return jwt;
    }
  }

  const jwt = grabLocalStorageItem(SIGNED_LOCAL_STORAGE_KEY);

  if (jwt) {
    setItemToLocalStorage(SIGNED_LOCAL_STORAGE_KEY, String(jwt));
  }

  return jwt;
};

export const getBigCommerceToken = async (jwt: string) => {
  try {
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    };
    const data = { signed_payload_jwt: jwt };
    const opts: RequestInit = {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    };

    const url = `${BACKEND_URL}/bigcommerce/onboarding/load/`;
    const response = await fetch(url, opts)
      .then((resp) => resp.json())
      .then((json) => (json ? String(json) : null))
      .catch((err) => Sentry.captureException(err));
    setItemToLocalStorage(CUR_JWT_LOCAL_STORAGE_KEY, String(response));
    return String(response);
  } catch (e) {
    // eslint-disable-next-line no-console
    Sentry.captureException(e);
  }
};

interface Props {
  children: React.ReactNode;
}

/**
 * Queries BigCommerce Embedded App for account info
 */
const BigCommerceAppBridgeProvider = ({ children }: Props) => {
  const token = getBigCommerceCredentials();

  useEffect(() => {
    if (token && isBigCommerceEmbeddedApp) {
      getBigCommerceToken(token);
    }
  }, [token]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default BigCommerceAppBridgeProvider;
