import { API_URL } from 'lib/api';
import { downloadFile } from 'lib/helpers/urls';
import useToast from 'lib/hooks/interactions/useToast';
import { useCallback, useMemo, useState } from 'react';
import ExportLineItemsContext, { ExportOptions } from './ExportLineItemsContext';

const getExportLineItemsURL = (options: ExportOptions) => {
  const baseURL = `${API_URL}/platform/csv/download_unfulfilled_supplier_line_items`;
  if (options.type === 'unfulfilled') {
    return baseURL;
  }
  if (options.type === 'all') {
    return `${baseURL}?${new URLSearchParams({ all_line_items: 'true' })}`;
  }
  throw new Error('Not yet supported!');
};

interface ProviderProps {
  /**
   * This will show children components within itself
   */
  children: React.ComponentProps<'div'>['children'];
}

/**
 * Context for exporting order line items -- is context cause we want to
 * prevent the user from making a second export while the first one
 * is still in progress. Also to allow them to export and then navigate
 * away from the page.
 *
 * Can be trigger from various parts of the app, returns the state
 * of the export and a function to trigger it. Has toast baked in.
 */
const ExportOrdersProvider = ({ children }: ProviderProps) => {
  const [isExporting, setIsExporting] = useState(false);
  const showToast = useToast();
  const exportLineItems = useCallback(
    async (count: number, options: ExportOptions) => {
      if (isExporting) return;

      setIsExporting(true);
      showToast(`Exporting line items${count > 1 ? ` for ${count} orders` : ''}`, {
        isLoading: true,
        duration: Infinity,
      });
      try {
        await downloadFile(getExportLineItemsURL(options));
        showToast('');
      } catch (error) {
        showToast(
          error instanceof Error ? error.message : 'Something went wrong. Please try again.',
        );
      } finally {
        setIsExporting(false);
      }
    },
    [isExporting, showToast],
  );

  const exportOrdersState = useMemo(
    () => ({ isExporting, exportLineItems }),
    [isExporting, exportLineItems],
  );

  return (
    <ExportLineItemsContext.Provider value={exportOrdersState}>
      {children}
    </ExportLineItemsContext.Provider>
  );
};

export default ExportOrdersProvider;
