import { gql } from '@apollo/client';
import { LoggedInMeShopAndTeamQuery, LoggedInMeShopAndTeamQueryVariables } from 'gql/graphql';
import client from 'lib/api/ApolloClient';

// It's imperative that this query stays lightweight so we should avoid
// requesting fields with expensive resolvers unless they are useful to several
// callers of useSession()
const LOGGEDIN_SHOPIFY_SHOP_FIELDS = gql`
  fragment LoggedInShopifyShopFields on ShopifyShop {
    id
    shopifySid
    remoteRid
    remotePlatform
    shopkeepDesiredCommission
    shopkeepDesiredFullfillmentEnum
    shopkeepDesiredShippingPaymentType
    shopkeepDesiredShippingPaymentAmountUsd
    shopkeepDesiredShippingCustomerChargeType
    shopkeepDesiredShippingCustomerChargeAmountUsd
    didSupInstallStorefrontAppFromBanner

    numUnreadCommunicationContainersAsSk
    numUnreadCommunicationContainersAsSup
    numProposalsUnreadAsSup
    numProposalsUnreadAsSk

    name
    logo
    headerImage
    phone
    canalPhoneNumber
    canalContactEmail
    shopStatus
    description
    isUsingCsvOnly
    referralStatus
    specificReferral
    referralsInvitingCurrentShop {
      id
      onboardedAt
      referralType
      referralRelationshipType
      inviterShop {
        id
        remoteRid
        referralCode
      }
    }
    domain
    myshopifyDomain
    planName
    isOonShopkeep
    isOonSupplier
    defaultShopkeepDesiredCommissionAsPercentage
    createdAt
    isCuratedCommerce
    hasCanalDeliveryProfile
    multiLocationEnabled
    emailPassthrough
    onboardingComplete
    appsInstalled
    instagramHandle
    deliveryLegacyMode
    platform {
      platform
      appId
      appToken
    }
    enabledFeatureFlagNames
    shopifyRecurringApplicationChargeId
    supplierMetabaseDashboardUrl
    supplierShippingPolicySummary
    supplierDesiredShippingRateCents
    supplierAvgFullfillmentTime
    supplierDesiredCommission
    supplierDesiredCommissionAsPercentage
    removeSkProductBackgroundSetting
    hasDiscoverableProducts
    productImageFill
    productImageBackgroundColor
    city
    country
    countryCode
    countryName
    province
    zip
    address1
    address2
    currency
    ediIsaId
    ediIsaIdQualifier
    canalPlan
    canCreateNewRelationship
    partnershipStatus
    skAppOnboardingCompletedAt
    supAppOnboardingCompletedAt
    relationshipsAsSk(tableFilterInput: {}) {
      edges {
        node {
          id
          sup {
            id
          }
        }
      }
    }
    relationshipsAsSup(tableFilterInput: {}) {
      edges {
        node {
          hasApprovedProposal
          numTotalOrdersTogether
          sk {
            id
          }
        }
      }
    }
    canalReferrals {
      id
      inviterShop {
        id
      }
      referredShop {
        id
      }
    }
  }
`;

// It's imperative that this query stays lightweight so we should avoid
// requesting fields with expensive resolvers unless they are useful to several
// callers of useSession()
const QUERY = gql`
  ${LOGGEDIN_SHOPIFY_SHOP_FIELDS}
  fragment LoggedinTeam on TeamType {
    id
    name
    hasAgreedToToc
  }
  query LoggedInMeShopAndTeam {
    me {
      id
      username
      firstName
      lastName
      lastLogin
      dateJoined
      email
    }
    loggedInShopifyShop {
      ...LoggedInShopifyShopFields
    }
    loggedInTeam {
      ...LoggedinTeam
    }
    loggedInMembership {
      id
      role
      status
    }
  }
`;

/**
 * Hits the API to return the logged in user, shop and team.
 * @returns {me,loggedInShopifyShop,loggedInTeam, loggedInMembership}
 */
export async function fetchSessionFromAPI(): Promise<LoggedInMeShopAndTeamQuery> {
  const { data } = await client.query<
    LoggedInMeShopAndTeamQuery,
    LoggedInMeShopAndTeamQueryVariables
  >({
    query: QUERY,
    // Force Apollo to let us take care of caching ourselves. Apollo might
    // otherwise skip session-reloading with `loadSession(true)` as it sees fit.
    fetchPolicy: 'no-cache',
  });
  return data;
}
