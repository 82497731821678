import { css } from 'styled-components';
import theme from '../../styles/theme';
import { VARIANT_STYLES as LINK_VARIANT_STYLES } from '../link/styles';

/**
 * Each of the different button styles we support. Typically, they are differentiated by their
 * color palette (i.e. primary is brand-colored, basic is black, etc), but can also differ
 * in their borders, padding, and hover states
 */
export type ButtonVariant =
  | 'primary'
  | 'basic'
  | 'dim'
  | 'white'
  | 'destructive'
  | 'plain'
  | 'ghost'
  | 'link';

/**
 * Different size variants for all the ButtonVariants (except 'plain', which just has one size).
 */
export type SizeVariant = 'large' | 'medium' | 'small';

/**
 * Position of the icon in relation to the button children.
 */
export type IconPosition = 'left' | 'right';

/**
 * The types of icons we can append or prepend to our button children
 */
export type IconVariant =
  | 'add'
  | 'add-reverse'
  | 'cancel'
  | 'more'
  | 'archive'
  | 'arrow-right'
  | 'arrow-left'
  | 'arrow-down'
  | 'arrow-up'
  | 'arrow-up-down'
  | 'full-arrow-up'
  | 'full-arrow-down'
  | 'full-arrow-left'
  | 'full-arrow-right'
  | 'back'
  | 'external-link'
  | 'checkmark'
  | 'download'
  | 'mail'
  | 'trash-bin'
  | 'delete'
  | 'alert'
  | 'desktop'
  | 'instagram'
  | 'link'
  | 'link-singular'
  | 'mobile'
  | 'gift'
  | 'send-plane'
  | 'pencil'
  | 'filter'
  | 'copy'
  | 'heart'
  | 'time'
  | 'settings'
  | 'refresh-line';

/**
 * Map of IconVariant to the remix icon className. We use more readable variant
 * names for ease of use
 */
export const ICON_VARIANT_TO_CLASS_NAME: Record<IconVariant, string> = {
  alert: 'ri-alert-line',
  archive: 'ri-archive-fill',
  'arrow-right': 'ri-arrow-right-s-line',
  'arrow-left': 'ri-arrow-left-s-line',
  'arrow-down': 'ri-arrow-down-s-line',
  'arrow-up': 'ri-arrow-up-s-line',
  'arrow-up-down': 'ri-arrow-up-down-line',
  'full-arrow-right': 'ri-arrow-right-line',
  'full-arrow-left': 'ri-arrow-left-line',
  'full-arrow-up': 'ri-arrow-up-line',
  'full-arrow-down': 'ri-arrow-down-line',
  'refresh-line': 'ri-refresh-line',
  add: 'ri-add-line',
  'add-reverse': 'ri-add-line',
  cancel: 'ri-close-line',
  more: 'ri-more-2-line',
  back: 'ri-arrow-left-s-line',
  'external-link': 'ri-external-link-line',
  link: 'ri-links-line',
  'link-singular': 'ri-link',
  checkmark: 'ri-check-fill',
  download: 'ri-download-line',
  mail: 'ri-mail-line',
  'trash-bin': 'ri-delete-bin-6-line',
  delete: 'ri-delete-bin-line',
  desktop: 'ri-computer-line',
  mobile: 'ri-smartphone-line',
  instagram: 'ri-instagram-line',
  gift: 'ri-gift-line',
  'send-plane': 'ri-send-plane-line',
  pencil: 'ri-pencil-line',
  filter: 'ri-filter-3-line',
  copy: 'ri-file-copy-2-line',
  heart: 'ri-heart-3-line',
  time: 'ri-time-line',
  settings: 'ri-settings-3-line',
};

/**
 * These icons must be displayed on their own in `plain` buttons
 */
export const PLAIN_ICONS = ['cancel', 'more'];

/**
 * Mapping between the icons and their position (either right or left) relative to the button children
 */
export const ICON_POSITIONS: Record<IconVariant, IconPosition> = {
  archive: 'left',
  'arrow-right': 'right',
  'arrow-left': 'right',
  'arrow-down': 'right',
  'arrow-up': 'right',
  'arrow-up-down': 'right',
  'full-arrow-left': 'right',
  'full-arrow-right': 'right',
  'full-arrow-up': 'right',
  'full-arrow-down': 'right',
  'refresh-line': 'right',
  'add-reverse': 'right',
  back: 'left',
  more: 'left',
  cancel: 'left',
  add: 'left',
  'external-link': 'left',
  checkmark: 'left',
  download: 'left',
  mail: 'left',
  'trash-bin': 'right',
  delete: 'left',
  alert: 'right',
  desktop: 'left',
  mobile: 'left',
  instagram: 'left',
  link: 'left',
  'link-singular': 'left',
  gift: 'left',
  'send-plane': 'left',
  pencil: 'left',
  filter: 'left',
  copy: 'left',
  heart: 'left',
  time: 'left',
  settings: 'left',
};

/**
 * The various styles associated with each ButtonVariant
 */
type ButtonVariantStyle = {
  textColor: string;
  backgroundColor: string;
  hoverAndLoadingBackgroundColor: string;
  disabledTextColor: string;
  disabledBackgroundColor: string;

  // Optional props for border colors
  borderColor?: string;
  hoverAndLoadingBorderColor?: string;
  disabledBorderColor?: string;
};

/**
 * The mapping of ButtonVariants to their corresponding ButtonVariantStyles.
 */
export const VARIANT_STYLES: { [key in ButtonVariant]: ButtonVariantStyle } = {
  primary: {
    textColor: theme.color.white[900],
    backgroundColor: theme.color.primary[700],
    hoverAndLoadingBackgroundColor: theme.color.primary[900],
    disabledTextColor: theme.color.white[900],
    disabledBackgroundColor: theme.color.primary[150],
  },
  basic: {
    textColor: theme.color.white[900],
    backgroundColor: theme.color.black[800],
    hoverAndLoadingBackgroundColor: theme.color.black[900],
    disabledTextColor: theme.color.gray[500],
    disabledBackgroundColor: theme.color.gray[200],
  },
  dim: {
    textColor: theme.color.black[900],
    backgroundColor: theme.color.gray[200],
    hoverAndLoadingBackgroundColor: theme.color.gray[300],
    disabledTextColor: theme.color.gray[500],
    disabledBackgroundColor: theme.color.gray[200],
  },
  white: {
    textColor: theme.color.black[800],
    backgroundColor: theme.color.white[900],
    hoverAndLoadingBackgroundColor: theme.color.white[900],
    disabledTextColor: theme.color.gray[400],
    disabledBackgroundColor: theme.color.white[900],
    borderColor: theme.color.gray[400],
    hoverAndLoadingBorderColor: theme.color.black[800],
    disabledBorderColor: theme.color.gray[300],
  },
  destructive: {
    textColor: theme.color.white[900],
    backgroundColor: theme.color.critical[500],
    hoverAndLoadingBackgroundColor: theme.color.critical[900],
    disabledTextColor: theme.color.white[900],
    disabledBackgroundColor: theme.color.critical[300],
  },
  plain: {
    textColor: theme.color.black[800],
    backgroundColor: 'transparent',
    hoverAndLoadingBackgroundColor: theme.color.gray[200],
    disabledTextColor: theme.color.gray[400],
    disabledBackgroundColor: 'transparent',
  },
  ghost: {
    textColor: theme.color.black[800],
    backgroundColor: 'transparent',
    hoverAndLoadingBackgroundColor: 'transparent',
    disabledTextColor: theme.color.gray[400],
    disabledBackgroundColor: 'transparent',
    borderColor: theme.color.gray[400],
    hoverAndLoadingBorderColor: theme.color.black[800],
    disabledBorderColor: theme.color.gray[300],
  },
  link: {
    textColor: LINK_VARIANT_STYLES.default.color,
    backgroundColor: 'transparent',
    hoverAndLoadingBackgroundColor: 'transparent',
    disabledTextColor: LINK_VARIANT_STYLES.default.disabledColor,
    disabledBackgroundColor: 'transparent',
    borderColor: 'transparent',
    hoverAndLoadingBorderColor: 'transparent',
    disabledBorderColor: 'transparent',
  },
};

/**
 * A function to get a button's padding to take care of a ButtonVariant and IconVariant
 * that have exceptional styling
 */
export const buttonPaddingStyle = (
  size: SizeVariant,
  variant: ButtonVariant,
  iconVariant: IconVariant | undefined,
  hasNoChildren: boolean,
): string => {
  if (variant === 'link') {
    return '0';
  }
  if (iconVariant && PLAIN_ICONS.includes(iconVariant)) {
    return '5px';
  }
  // When showing icons and no text, we want consistent padding
  if (iconVariant && hasNoChildren) {
    return '7px';
  }

  if (variant === 'plain') {
    return '2px 5px';
  }
  if (size === 'medium') {
    return '6px 16px';
  }
  if (size === 'small') {
    return '4px 12px';
  }

  return '12px 24px';
};

export const buttonDisabledStyle = (variant: ButtonVariant) => css`
  color: ${VARIANT_STYLES[variant].disabledTextColor};
  background-color: ${VARIANT_STYLES[variant].disabledBackgroundColor};
  border: 1px solid ${VARIANT_STYLES[variant].disabledBorderColor || 'transparent'};

  &:hover {
    cursor: 'initial';
    background-color: ${VARIANT_STYLES[variant].disabledBackgroundColor};
    border: 1px solid ${VARIANT_STYLES[variant].disabledBorderColor};
  }
`;
