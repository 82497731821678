/**
 * TODO: why do we need this variable if our Next JS config file already proxies to the backend?
 *
 * These environment variables should only ever be used & defined here - consolidate other
 * uses of them into a flag to make sure we can configure things more easily!
 * Local is defined as "running off local backend or in local build", develop is defined as "running
 * off develop backend", and production is any other shopcanal.com environment. Note
 * that `IS_LOCAL` could be true at the same time as develop or production, since
 * you could be running off one of those backends locally.
 */
export const BACKEND_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8000';
export const IS_LOCAL =
  process.env.NODE_ENV === 'development' ||
  BACKEND_URL.includes('localhost') ||
  BACKEND_URL.includes('127.0.0');
export const IS_DEVELOP = BACKEND_URL.includes('develop.shopcanal.com');
export const IS_PRODUCTION = !IS_DEVELOP && BACKEND_URL.includes('shopcanal.com');

/**
 * Any flag using this value is ON in develop or localhost, and OFF
 * in production! Use it to shadow launch features to us before launching
 * fully to customers.
 */
export const ON_FOR_CANAL_ONLY = IS_DEVELOP || IS_LOCAL;

/*
 * Necessary to render 3rd party images that haven't been uploaded to our BE yet
 * (The BE prepends these automatically, but until the image is stored there this is necessary to render it)
 * (( don't ask me why its in payments I don't get it either ))
 */
export const IMAGE_PROXY_URL = `${BACKEND_URL}/payments/image_proxy?img=`;
